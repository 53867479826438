import React from 'react';
import { MessagesGroupView } from '../common/messagesGroupView';

// const width =
//   window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export const ExpertMessaging = () => {
  return (
    <div>
     
      <div className="requests-title">Messages</div>
      <MessagesGroupView />
    </div>
  );
};
