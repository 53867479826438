import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, Radio, Spin, Tag, Upload, message } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { BtnBold, BtnItalic, Editor, EditorProvider, Toolbar } from 'react-simple-wysiwyg';
import { useNavigate } from 'react-router-dom';
import BlogTags from './Tags';
const CreateBlog = () => {
  const [form] = Form.useForm();
  const [content, setContent] = useState('');
  const [fileList, setFileList] = useState([]);
  const [authorImage, setAuthorImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const isCreateBlogLoading = useSelector(state => state?.common?.isCreateBlogLoading);
  const navigate = useNavigate();
  const handleImageUpload = ({ fileList }) => {
    setFileList(fileList);
    const file = fileList[0];
    if (file) {
      form.setFieldValue('featured_image', file.originFileObj);
    } else {
      form.setFieldValue('featured_image', null);
    }
  };
  const handleAuthorImagehandle = ({ fileList }) => {
    setAuthorImage(fileList);
    const file = fileList[0];
    if (file) {
      form.setFieldValue('author_image', file.originFileObj);
    } else {
      form.setFieldValue('author_image', null);
    }
  };
  const dispatch = useDispatch();
  const handleSubmit = values => {
    if (content === '') {
      message.error('Please provide body');
      return;
    }
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('body', content);
    formData.append('published', values.published);
    formData.append('slug', values.title + Date.now());
    formData.append('author_id', 1);
    formData.append('excerpt', 'excerpt');
    formData.append('featured_image_caption', values.featured_image_caption);
    formData.append('author_image', values.author_image);
    formData.append('author_name', values.author_name);
    formData.append('featured_image', values.featured_image);
    formData.append('tags', JSON.stringify(tags));
    formData.append('youtube_video_url', values.youtube_video_url);
    dispatch({ type: 'CREATE_BLOG', formData: formData });
    setLoading(true);
  };
  useEffect(() => {
    if (!isCreateBlogLoading && loading) {
      setLoading(false);
      form.resetFields();
      setContent('');
      setFileList([]);
      setAuthorImage([]);
      setTags([]);
      message.success('Blog created successfully');
      dispatch({ type: 'CREATE_BLOG_ERROR' });
      navigate('/admin/blogs', { state: '' });
    }
  }, [isCreateBlogLoading]);
  return (
    <>
      <Form form={form} onFinish={handleSubmit} layout="horizontal" style={{ maxWidth: 900 }}>
        <Form.Item
          label="Title"
          name="title" // Add name prop here
          rules={[
            {
              required: true,
              message: 'Please enter title',
            },
          ]}
        >
          <Input placeholder="Title" />
        </Form.Item>
        <EditorProvider>
          <Editor
            containerProps={{ style: { height: 300, resize: 'vertical', position: 'relative' } }}
            value={content}
            onChange={e => setContent(e.target.value)}
          >
            <Toolbar style={{ position: 'sticky', top: 0, left: 0,zIndex: 50  }}>
              <BtnBold />
              <BtnItalic />
            </Toolbar>
          </Editor>
        </EditorProvider>
        <Form.Item label="Published" name="published" valuePropName="checked">
          <Checkbox></Checkbox>
        </Form.Item>
        <Form.Item
          label="Upload Image"
          name={'featured_image'}
          rules={[{ required: true, message: 'Please upload image' }]}
        >
          <Upload
            name="featured_image"
            onChange={handleImageUpload}
            fileList={fileList}
            maxCount={1}
            listType="picture-card"
          >
            {fileList.length < 1 && (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          label="Youtube video url"
          name="youtube_video_url" // Add name prop here
          rules={[
            {
              // required: true,
              message: 'Youtube video url',
            },
          ]}
        >
          <Input placeholder="Youtube video url" />
        </Form.Item>
        <Form.Item
          label="Featured Image Caption"
          name="featured_image_caption" // Add name prop here
          rules={[
            {
              required: true,
              message: 'Featured Image Caption',
            },
          ]}
        >
          <Input placeholder="Featured Image Caption" />
        </Form.Item>
        <Form.Item
          label="Author Name"
          name="author_name" // Add name prop here
          rules={[
            {
              required: true,
              message: 'Please enter author name',
            },
          ]}

        >
          <Input placeholder="Author Name" />
        </Form.Item>
        <Form.Item
          label="Upload Author Image"
          name={'author_image'}
          rules={[{ required: true, message: 'Please upload author image' }]}
        >
          <Upload
            name="author_image"
            onChange={handleAuthorImagehandle}
            fileList={authorImage}
            maxCount={1}
            listType="picture-card"
          >
            {authorImage.length < 1 && (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>
        <BlogTags tags={tags} setTags={setTags} />
        <Form.Item>
          {
            loading ? (
              <Spin />
            ) : (
              <Button type="primary" htmlType="submit" className="w100 mt25" size="large">
                Create
              </Button>
            )}
        </Form.Item>
      </Form>
    </>
  );
};

export default CreateBlog;
