/* eslint-disable react-hooks/exhaustive-deps */
import { message, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

const DeleteModal = props => {
  const dispatch = useDispatch();
  const { visible, setVisible, subTitle, rowData, deleteModule, doRefresh, okText } = props;
  const {
    isDeleteLoading,
    isDeleteReviewLoading,
    isDeleteUserLoading,
    isAdminDeleteCreditsLoading,
  } = useSelector(state => state.admin);
  const getButtonLoadingParameter = () => {
    switch (deleteModule) {
      case 'request':
        return isDeleteLoading;
      case 'review':
        return isDeleteReviewLoading;
      case 'expert':
        return isDeleteUserLoading;
      case 'expert_reject':
        return isDeleteUserLoading;
      case 'credits':
        return isAdminDeleteCreditsLoading;
      default:
        return;
    }
  };

  const onDelete = () => {
    if (deleteModule === 'request') {
      dispatch({ type: 'ADMIN_DELETE_REQUEST', deleteModule: deleteModule, deleteId: rowData.id });
      setVisible(false);
      message.success('Record deleted successfully');
      dispatch({ type: 'CLEAR_ADMIN_DATA' });
      doRefresh();
    } else if (deleteModule === 'review') {
      dispatch({ type: 'ADMIN_DELETE_REVIEW', id: rowData.id });
      message.success('Review deleted successfully');
      setVisible(false);
      dispatch({ type: 'CLEAR_ADMIN_DATA' });
      doRefresh();
    } else if (deleteModule === 'expert') {
      dispatch({ type: 'ADMIN_DELETE_USER', id: rowData?.id });
      message.success('Expert deleted successfully');
      setVisible(false);
      dispatch({ type: 'CLEAR_ADMIN_DATA' });
      doRefresh();
    } else if (deleteModule === 'credits') {
      dispatch({ type: 'ADMIN_DELETE_CREDITS', id: rowData?.creditId });
      message.success('Credits deleted successfully');
      setVisible(false);
      dispatch({ type: 'CLEAR_ADMIN_DATA' });
    } else if (deleteModule === 'expert_reject') {
      dispatch({ type: 'ADMIN_DELETE_USER', id: rowData?.id });
      message.success('expert rejected successfully');
      setVisible(false);
    }
  };
  return (
    <Modal
      title={'Are you sure?'}
      open={visible}
      onOk={onDelete}
      centered
      onCancel={() => setVisible(false)}
      okText={okText ? okText : 'Delete'}
      okButtonProps={{
        disabled: getButtonLoadingParameter(),
        loading: getButtonLoadingParameter(),
      }}
      cancelText={'Cancel'}
    >
      <p>{subTitle || ''}</p>
    </Modal>
  );
};

export default DeleteModal;
