import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Button, Card, Alert } from 'antd';
import Helmet from '../../components/common/helmet';
import expert_home_thumbnail_2 from '../../assets/images/expert_home_video_thumbnail_2.jpeg';
import TextIcon from '../../assets/images/text-icon.svg';
import PracticeExpansion from '../../assets/images/practice-expansion.png';
import StartAPractice from '../../assets/images/starting-a-practice.png';
import howItWork4 from '../../assets/images/ReadyToStart.jpeg';
import { ArrowRightOutlined, CaretRightOutlined, PlayCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import FAQs from '../public/FAQs';
import videoCameraIcon from '../../assets/images/video-camera-icon.png';
import { animatedMehtod, cuurentBrowser, isLogin, role } from '../../helpers/functions';
import { Modal } from 'antd';
import ReactPlayer from 'react-player';
import { useInView, animated, useTransition, useSpringRef } from 'react-spring';
import bgMenImg from '../../assets/images/removed_bg_man.png';
import bgWOmenImg from '../../assets/images/removed_bg_women.png';
import benefitListIcon1 from '../../assets/images/Utilize AI Technology.png';
import benefitListIcon2 from '../../assets/images/Expand your Reach.png';
import benefitListIcon3 from '../../assets/images/Increase Visibility.png';
import benefitListIcon4 from '../../assets/images/Cost-effective Growth.png';
import benefitListIcon5 from '../../assets/images/Establish Trust.png';
import benefitListIcon6 from '../../assets/images/Work Flexibly.png';
import HowItWork from '../common/HowItWork';
import useResponsive from '../../hooks/useBreakpoint';

const ExpertHomePage = props => {
  const [visible, setVisible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [modal3Visible, setModal3Visible] = useState(false);
  const [ispaused, setIsPaused] = useState(false);
  const [alertBoxVisible, setAlertBoxVisible] = useState(false);
  const [readyToStartModel, setReadyToStartModel] = useState(false);
  const breakPoint = useResponsive();
  const navigate = useNavigate();
  const redirectForSigup = () => {
    if (isLogin()) {
      navigate(`/expert/profile`);
    } else {
      localStorage.setItem('redirectLink', `${window.location.origin}/expert`);
      navigate('/expert/signup');
    }
  };
  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  const handleGetStartedClick = () => {
    if (isLogin()) {
      if (role() === 'expert') {
        localStorage.setItem('redirectLink', `${window.location.origin}/expert/profile`);
        navigate('/expert/profile');
      } else {
        setAlertBoxVisible(!alertBoxVisible);
      }
    } else {
      localStorage.setItem('redirectLink', `${window.location.origin}/expert/profile`);

      navigate('/expert/signup');
    }
  };
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current) {
        // Close the modal here
        setAlertBoxVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [sectionOneRef, sectionOneSprings] = useInView(animatedMehtod, {
    rootMargin: cuurentBrowser() === 'Firefox' ? '-40% 0%' : '-75px 0px',
    once: true,

    // amount: 20,
  });
  const [sectionTwoRef, sectionTwoSprings] = useInView(animatedMehtod, {
    rootMargin: cuurentBrowser() === 'Firefox' ? '-40% 0%' : '-75px 0px',
    once: true,
    // amount: 20,
  });
  const [sectionThreeRef, sectionThreeSprings] = useInView(animatedMehtod, {
    rootMargin: cuurentBrowser() === 'Firefox' ? '-40% 0%' : '-75px 0px',
    once: true,
    // amount: 20,
  });
  const [sectionFourRef, sectionFourSprings] = useInView(animatedMehtod, {
    rootMargin: cuurentBrowser() === 'Firefox' ? '-40% 0%' : '-75px 0px',
    once: true,
    // amount: 20,
  });
  const [sectionFiveRef, sectionFiveSprings] = useInView(animatedMehtod, {
    rootMargin: cuurentBrowser() === 'Firefox' ? '-40% 0%' : '-75px 0px',
    once: true,
    // amount: 20,
  });
  const [sectionSixRef, sectionSixSprings] = useInView(animatedMehtod, {
    rootMargin: cuurentBrowser() === 'Firefox' ? '-40% 0%' : '-75px 0px',
    once: true,
    // amount: 20,
  });

  const backgroundImagesHP = [
    { id: 1, image: `url("${bgMenImg}")`, content: 'Slide 1' },
    { id: 2, image: `url("${bgWOmenImg}")`, content: 'Slide 2' },
  ];

  const [hpImgIndex, setHpImgIndex] = useState(0);
  const transRef = useSpringRef();

  const transitions = useTransition(hpImgIndex, {
    ref: transRef,
    keys: null,
    from: { opacity: 0, transform: 'translate3d(100%, 0, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0%, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(-30%, 0, 0)' },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      //  ( breakPoint == 'xl'  || breakPoint == 'xxl' ) &&
      setHpImgIndex(prevIndex => (prevIndex + 1) % backgroundImagesHP.length);
    }, 5000); // Slide every 5 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  useEffect(() => {
    transRef.start();
  }, [hpImgIndex]);

  //-------------------------------- how it works section 3 start -----------------------------------------------

  const workingStepsContent = [
    {
      id: 1,
      title: 'Register for Free',
      desc: 'Complete the application form to comprehensively outline your professional experience, specific services offered, and corresponding fees. After screening, we’ll activate your account once it aligns with our quality standards.',
      vidURL: 'https://www.youtube.com/embed/jnvm-V9R4wM?si=ygRO7dmF-9wAfal3&playlist=jnvm-V9R4wM',
    },
    {
      id: 2,
      title: 'Precision Matching',
      desc: 'Our AI-driven algorithm pairs you with clients seeking your expertise. For each match, a tailored valuation report highlights your qualifications, ensuring you are presented as the best solution when the fit is just right.',
      vidURL: 'https://www.youtube.com/embed/GExWW8GYPsg?si=OMRSZyyRsc6_UEQn&playlist=GExWW8GYPsg',
    },

    {
      id: 3,
      title: 'Dashboard Efficiency',
      desc: 'Use our dashboard equipped with status tracking, messaging, and payment processing. Schedule meetings effortlessly with our calendar and access instant meeting options. Manage notifications and reminders. Focus on clients; we handle the rest.',
      vidURL: 'https://www.youtube.com/embed/Ma5bqglhAxo?si=cJcl7p0LQ800jzV7&playlist=Ma5bqglhAxo',
    },

    {
      id: 4,
      title: 'Get Paid',
      desc: 'Complete your work, obtain client approval, and mark it as "Completed" to receive your fee. Your earnings, guaranteed by us, are transferred monthly minus our nominal marketplace percentage, within the first ten days of the following month.',
      vidURL: 'https://www.youtube.com/embed/guFRQzHdKwQ?si=AqZwNnTNtU31x5x9&playlist=guFRQzHdKwQ',
    },
  ];

  //-------------------------------- how it works section 3 end -----------------------------------------------
  return (
    <div className="home-page">
      <Helmet title="Home" />
      <animated.div style={sectionOneSprings} ref={sectionOneRef}>
        <div
          className="cover-img position-relative expert-home"
          style={{ backgroundImage: 'none', overflow: 'hidden' }}
        >
          {transitions((style, i) => (
            <animated.div
              key={backgroundImagesHP[i].id}
              className="backgoung-img"
              style={{
                ...style,
                position: 'absolute',
                top: '0px',
                right: '0%',

                height: '100%',
                width:
                  (breakPoint == 'xl' && '85%') ||
                  (breakPoint == 'xxl' && '85%') ||
                  (breakPoint == 'lg' && '80%') ||
                  (breakPoint == 'md' && '70%') ||
                  (breakPoint == 'sm' && '70%') ||
                  (breakPoint == 'xs' && '60%'),
                zIndex: 5,
                backgroundImage: backgroundImagesHP[i].image,
                backgroundSize: 'cover', // Ensures the image covers the container
                backgroundPosition: '90%', // Centers the image
                backgroundRepeat: 'no-repeat', // Prevents tiling

                // display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center',
              }}
            >
              {/* {backgroundImagesHP[i].content} */}
            </animated.div>
          ))}
          <div className="text-on-img">
            <div className="section">
              <Row gutter={75}>
                <Col xs={14} sm={16} md={18} lg={16} xl={18}>
                  <p id="head-title" className="img-title ac-heading">
                    A Place to Elevate your Professional Career
                  </p>
                  <p className="img-subtitle ac-content">
                    Strengthen your professional reputation and online presence, survive the fierce
                    competition, attract new clients, and increase your market share.
                  </p>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    onClick={() => {
                      console.log('clicked');
                      handleGetStartedClick();
                    }}
                    style={{ zIndex: 100 }}
                  >
                    Get Started
                  </Button>
                  {alertBoxVisible && (
                    <Alert
                      message="You are logged in as client. please logout and sign up as expert using different email."
                      type="info"
                      closable
                      showIcon
                      style={{
                        width: '50%',
                        marginTop: '10px',
                      }}
                      ref={modalRef}
                      onClose={() => setAlertBoxVisible(false)}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </animated.div>
      <section className="section2 ac-bg-light">
        <animated.div style={sectionTwoSprings} ref={sectionTwoRef}>
          <Row
            style={{ display: 'flex', justifyContent: 'center' }}
            className="mt50 benefits-row-container"
            gutter={[75]}
          >
            <Col
              className="d-flex-a-c"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              onClick={() => {
                setIsPaused(false);
                setTimeout(() => {
                  setVisible(true);
                }, [100]);
              }}
            >
              <img
                style={{ width: '100%', borderRadius: '8px', cursor: 'pointer' }}
                src={expert_home_thumbnail_2}
                alt="Accountizer Homepage"
                loading="lazy"
              />
              <div className="ac-overlay" style={{ borderRadius: '8px' }}></div>
              <div className="ac-playicon">
                <CaretRightOutlined />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            >
              <p className="h-title" style={{ fontSize: '2.5rem', fontWeight: '400' }}>
                Offering Accounting Professionals Solutions for
              </p>
              <div className="box">
                <img
                  loading="lazy"
                  src={PracticeExpansion}
                  alt="rocket-icon"
                  style={{ width: '48px' }}
                />
                <div>
                  <p className="title">Practice Expansion</p>
                  <p className="sub-title">
                    For CPAs, tax preparers, business advisors, or bookkeepers, who run a
                    professional practice and would like to sustain and/or expand market share,
                    build a more prominent online presence, overcome the fierce market competition,
                    and attract new clients.
                  </p>
                </div>
              </div>
              <div className="box mt25">
                <img
                  loading="lazy"
                  src={StartAPractice}
                  alt="switch-icon"
                  style={{ width: '48px' }}
                />
                <div>
                  <p className="title">Starting a Practice</p>
                  <p className="sub-title">
                    For accounting professionals looking forward to starting their own practice.
                    Accountizer will help by building client networks, and by introducing you to
                    clients interested in your industry experience.
                  </p>
                </div>
              </div>
            </Col>
            <Modal
              open={visible}
              width={(width * 68) / 100}
              style={{ height: (height * 75.5) / 100 }}
              footer={null}
              onCancel={() => {
                setIsPaused(true);
                setTimeout(() => {
                  setVisible(false);
                }, [100]);
              }}
              bodyStyle={{ padding: 0 }}
              centered={true}
            >
              <ReactPlayer
                className="react-vimeo-player-modal"
                url="https://youtu.be/Y1KY0imxgCc"
                controls={true}
                stopOnUnmount={true}
                playing={!ispaused}
                width={(width * 65) / 100}
                height={(height * 75.5) / 100}
              />
            </Modal>
          </Row>
        </animated.div>
      </section>
      <section className="section3 ac-bg-light">
        <animated.div style={sectionThreeSprings} ref={sectionThreeRef}>
          <div className="benefit">
            <div className="tc">
              <p className="h-title mb10 ac-heading">Benefits for Experts</p>
              <Modal
                open={modal2Visible}
                width={(width * 68) / 100}
                style={{ height: (height * 75.5) / 100 }}
                footer={null}
                onCancel={() => {
                  setIsPaused(true);
                  setTimeout(() => {
                    setModal2Visible(false);
                  }, [100]);
                }}
                bodyStyle={{ padding: 0 }}
                centered={true}
              >
                <ReactPlayer
                  className="react-vimeo-player-modal"
                  url="https://vimeo.com/808442055"
                  controls={true}
                  stopOnUnmount={true}
                  playing={!ispaused}
                  width={(width * 65) / 100}
                  height={(height * 75.5) / 100}
                />
              </Modal>
              <p className="sub-title ac-content" style={{ width: 'auto' }}>
                Join us today for free to take advantage of Accountizer’s major benefits to
                professionals.
              </p>
              <Row
                className="mt50 box"
                style={{
                  // flexWrap: 'wrap',
                  // justifyContent: 'between',
                  // gap: '4%',
                  // rowGap: '50px',
                  // rowGap: 'clamp(25px, 4vw, 65px)',

                  width: '100%',
                }}
              >
                {benefitList.map((item, index) => (
                  <Col
                    style={{ padding: '25px' }}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}
                    xl={8}
                    className="rotate-card-container"
                  >
                    <div class="rotate-card">
                      <div class="card__face card__face--front ">
                        <div className="benefit-expert-card-front-content">
                          <img
                            loading="lazy"
                            className="benefit-expert-icon mb-5"
                            // width={150}
                            // height={150}
                            src={item?.icon || TextIcon}
                            alt="Accountizer Experts"
                          ></img>
                          <p className="mt20 title">{item.title}</p>
                        </div>
                      </div>
                      <div class="card__face card__face--back">
                        <div className="benefit-expert-card-back-content">
                          <p className="sub-text desc" style={{ fontSize: '13px' }}>
                            {item.desc}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </animated.div>
      </section>
      <section className="section4 ac-bg-light">
        {/* <animated.div style={sectionFourSprings} ref={sectionFourRef}> */}
        <div className="tc">
          <p className="h-title mb10 ac-heading">How it Works</p>
        </div>
        <Modal
          open={modal3Visible}
          footer={null}
          width={(width * 68) / 100}
          onCancel={() => {
            setIsPaused(true);
            setTimeout(() => {
              setModal3Visible(false);
            }, [100]);
          }}
          bodyStyle={{ padding: 0 }}
          centered={true}
        >
          <ReactPlayer
            className="react-vimeo-player-modal"
            url="https://vimeo.com/807290856"
            controls={true}
            stopOnUnmount={true}
            playing={!ispaused}
            width={(width * 65) / 100}
            height={(height * 75.5) / 100}
          />
        </Modal>

        {workingStepsContent.map((item, index) => (
          <HowItWork key={item.id} item={item} index={index} />
        ))}

        {/* </animated.div> */}
      </section>

      <section className="section5 ac-bg-light">
        <animated.div style={sectionFiveSprings} ref={sectionFiveRef}>
          <div className="benefit">
            <p className="m-title tc ac-heading">Frequently Asked Questions</p>
            <div className="mt50 box">
              <FAQs type="expert" count={5} />
            </div>
            <center>
              <Button
                type="primary"
                htmlType="submit"
                className="mt35"
                size="large"
                onClick={() => navigate('/expert/faq')}
              >
                View all
              </Button>
            </center>
          </div>
        </animated.div>
      </section>

      <section className="section6 ac-bg-light">
        <animated.div style={sectionSixSprings} ref={sectionSixRef}>
          <Card>
            <Row gutter={75}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} className="d-flex-center">
                <div>
                  <p className="title mb20">Ready to get started?</p>
                  <p className="sub-title mb25">
                    Sign up for free, fill out the online application form, complete your screening
                    process, and get connected to boost your accounting career today.
                  </p>
                  <Button size="large" onClick={() => redirectForSigup()}>
                    Get Started &nbsp;
                    <ArrowRightOutlined />
                    &nbsp;
                  </Button>
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                className="tr"
                style={{
                  marginTop:
                    breakPoint == 'xs' || breakPoint == 'sm' || breakPoint == 'md' ? '20px' : '0px',
                }}
              >
                <PlayCircleFilled
                  className="play-icon"
                  onClick={() => {
                    setIsPaused(false);
                    setTimeout(() => {
                      setReadyToStartModel(true);
                    }, [100]);
                  }}
                />
                <img
                  onClick={() => {
                    setIsPaused(false);
                    setTimeout(() => {
                      setReadyToStartModel(true);
                    }, [100]);
                  }}
                  loading="lazy"
                  style={{ width: '100%',cursor: 'pointer' }}
                  src={howItWork4}
                  alt="Accountizer Homepage"
                ></img>
                <Modal
                  open={readyToStartModel}
                  footer={null}
                  onCancel={() => {
                    setIsPaused(true);
                    setTimeout(() => {
                      setReadyToStartModel(false);
                    }, [100]);
                  }}
                  centered={true}
                  width={(width * 68) / 100}
                  style={{ height: (height * 75.5) / 100 }}
                >
                  <ReactPlayer
                    className="react-vimeo-player-modal"
                    url="https://youtu.be/OueyBglJUQU"
                    controls={true}
                    stopOnUnmount={true}
                    playing={!ispaused}
                    width={(width * 65) / 100}
                    height={(height * 75.5) / 100}
                  />
                </Modal>
              </Col>
            </Row>
          </Card>
        </animated.div>
      </section>
    </div>
  );
};

export default ExpertHomePage;

const benefitList = [
  {
    id: 1,
    title: 'Utilize AI Technology',
    desc: 'Let AI technology work for you, not against you. By joining us, you will be part of the next AI revolution in accounting, enhancing your practice with cutting-edge tools.',
    icon: benefitListIcon1,
  },
  {
    id: 2,
    title: 'Expand your Reach',
    desc: 'Attract new clients and extend your market share by tapping into a broader network of potential customers through our platform.',
    icon: benefitListIcon2,
  },
  {
    id: 3,
    title: 'Increase Visibility',
    desc: 'Save on marketing costs from social media ads, webinars, content creation, or SEO research. Increase your online presence for free and focus on what you do best.',
    icon: benefitListIcon3,
  },
  {
    id: 4,
    title: 'Cost-effective Growth',
    desc: 'Stop spending upfront on marketing your practice. We handle the marketing and only take a small percentage if we successfully secure you new business opportunities.',
    icon: benefitListIcon4,
  },
  {
    id: 5,
    title: 'Establish Trust',
    desc: 'Joining the Accountizer community means you are vetted for your qualifications, skills, and knowledge. This helps build an instantly high level of trust with new clients, especially for new practices.',
    icon: benefitListIcon5,
  },
  {
    id: 6,
    title: 'Work Flexibly',
    desc: 'Accountizer is a fully remote platform. Enjoy the freedom to work from anywhere and save on overhead costs, giving you flexibility and reducing expenses.',
    icon: benefitListIcon6,
  },
];
