/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Form, Button, Spin, Radio, Space, Input, Row, Col, Divider } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserData } from '../../helpers/functions';
import { IoMdArrowRoundBack } from 'react-icons/io';
const { TextArea } = Input;

const Invoice = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const { qid } = useParams();
  const [form] = Form.useForm();
  const [selectedId, setSelectedId] = useState('');
  const { isInvoiceLoading, invoiceList } = useSelector(state => state.common);
  const { isStepUpdating, stepUpdateMsg } = useSelector(state => state.steps);
  const [selectedType, setSelectedType] = useState('');
  const { isQuestionLoading, questionDetail } = useSelector(state => state.common);
  useEffect(() => {
    dispatch({ type: 'GET_INVOICE_REQUEST', formData: { status: 'active' } });
  }, []);

  const onChange = e => {};

  useEffect(() => {
    if (!isQuestionLoading && Object.keys(questionDetail).length > 0) {
      setSelectedType(questionDetail.customer_type !== null ? questionDetail.customer_type.id : '');
      form.setFieldsValue({
        customer_type_id:
          questionDetail.customer_type !== null ? questionDetail.customer_type.id : '',
      });
    }
  }, [isQuestionLoading, questionDetail]);

  const onFinish = values => {
    if (qid) {
      dispatch({
        type: 'QUESTION_STEP_UPDATE_REQUEST',
        formData: {
          ...values,
          user_id: getUserData().id,
          id: qid,
        },
        requestType: 'post',
        step: 'update-invoice',
      });
    }
  };

  useEffect(() => {
    if (!isStepUpdating && stepUpdateMsg === 'success') {
      handleNext(qid, 'main-category', 'invoice');
      dispatch({ type: 'CLEAR_STEPS' });
    }
  }, [isStepUpdating, stepUpdateMsg]);

  return (
    <div>
      <div className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={false}>
          {isInvoiceLoading && <Spin />}
          {!isInvoiceLoading && (
            <>
              <p className="m-title tl mb-7 d-flex-center">
                <span
                  className="back-icon"
                  onClick={() =>
                    handleBack(qid, selectedType === 5 ? 'invoice-ownership' : 'invoice-business')
                  }
                >
                  <IoMdArrowRoundBack />
                </span>
                <span>How many invoices do you handle per month?</span>
              </p>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                form={form}
                onFinish={onFinish}
              >
                <Form.Item
                  name="invoices_id"
                  initialValue={1}
                  rules={[{ required: true, message: 'Please select at least one option' }]}
                >
                  <Radio.Group
                    className={'mt3'}
                    style={{ width: '100%' }}
                    onChange={onChange}
                    defaultValue={1}
                  >
                    <Space direction="vertical" size={'middle'} style={{ width: '100%' }}>
                      {invoiceList.map(item => {
                        return (
                          <>
                            <Radio
                              style={{ fontSize: '18px' }}
                              key={item.id}
                              value={item.id}
                              dataAttr={item.slug}
                            >
                              {' '}
                              {item.name}
                            </Radio>
                          </>
                        );
                      })}
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Divider className="mt40 mb40" />
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    className="mt5"
                    loading={isStepUpdating}
                    disabled={isStepUpdating}
                  >
                    Next
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </Card>
      </div>
    </div>
  );
};
export default Invoice;
