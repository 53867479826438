import { Card, Col, message, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertDateToTimezone, convertTimeToTimezone, dynamicMessagingCard, getFileNameFromUrl, getUserData } from '../../helpers/functions';
import { DownloadOutlined } from '@ant-design/icons';
import { postRequest } from '../../helpers/apiCall';
import ImagePreviewModal from '../common/modal/ImagePreviewModal';
import { GET_FILE_URL, GTE_BASE64_FILE } from '../../helpers/constant';

export const ArchiveSection = props => {
  const { subjectId } = props;
  const { attachmentByThreadId, isAttachmentByThreadIdLoading } = useSelector(
    state => state.requests,
  );
  const dispatch = useDispatch();
  const [clickedMessagData, setClickedMessagData] = useState({});
  const [isModal, setIsModal] = useState(false);
  const [imgsrc, setImgsrc] = useState('');

  useEffect(() => {
    if (!isNaN(subjectId)) {
      dispatch({ type: 'GET_ATTACHMENTS_BY_THREAD_ID', id: subjectId });
    }
  }, []);
  async function handleAttachmentClick(id) {
    try {
      const resp = await postRequest(GET_FILE_URL, {
        messageId: id,
      });
      if (resp?.status === 200) {
        return resp.data.url;
      }
    } catch (error) {
      message.error('something went wrong please try again.');
      // console.log(error);
    }
  }
  const handleDownloadClick = async (type, m) => {
    setClickedMessagData(m);
    switch (type) {
      case 'image':
        const url = await handleAttachmentClick(m?.id);
        setImgsrc(url);
        setIsModal(true);
        break;
      case 'docs':
        const a = document.createElement('a');
        a.href = await handleAttachmentClick(m?.id);
        a.download = m?.body.substring(m?.body.lastIndexOf('/') + 1);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        break;
      case 'pdf':
        const a1 = document.createElement('a');
        a1.href = await handleAttachmentClick(m?.id);
        a1.download = m?.body.substring(m?.body.lastIndexOf('/') + 1);
        document.body.appendChild(a1);
        a1.click();
        document.body.removeChild(a1);

        break;
      case 'excel':
        const a2 = document.createElement('a');
        a2.href = await handleAttachmentClick(m?.id);
        a2.download = m?.body.substring(m?.body.lastIndexOf('/') + 1);
        document.body.appendChild(a2);
        a2.click();
        document.body.removeChild(a2);

        break;
      default:
        break;
    }
  };
  const handleDownloadImage = async (id) => {
    try {
      if (!id) return;
      const resp = await postRequest(GTE_BASE64_FILE, {
        messageId: id,
      });
      if (resp?.status === 200) {
        const link = document.createElement('a');
        link.href = resp.data.url;
        link.download = resp.data.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      message.error('something went wrong please try again.');
    }
  };

  return isAttachmentByThreadIdLoading ? (
    <div className="d-flex-a-c">
      <Spin />
    </div>
  )
    : attachmentByThreadId?.length ? (
      <div >
        {isModal && <ImagePreviewModal
          isModal={isModal}
          setIsModal={setIsModal}
          imgsrc={imgsrc}
          handleDownload={handleDownloadImage}
          data={clickedMessagData}
          setdata={setClickedMessagData}
        />}
        {attachmentByThreadId.map(data => {
          return (
            <>
              <Card className="mb15">
                <Row>
                  <Col
                    style={{ wordWrap: 'break-word', paddingRight: '15px' }}
                    xs={10}
                    sm={10}
                    md={10}
                    lg={10}
                    xl={10}
                  >
                    <p className="small-text">File Name</p>
                    <p className="big-text">{getFileNameFromUrl(data?.body, 12)}</p>
                  </Col>

                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <p className="small-text">File Ext.</p>
                    <p className="big-text">{data?.attachment_type}</p>
                  </Col>

                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <p className="small-text">Type</p>
                    <p className="big-text">{data?.user_id != getUserData().id ? "Received" : "Sent"}</p>
                  </Col>

                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <p className="small-text">Date</p>
                    <p className="big-text" >{convertDateToTimezone(data?.created_at)}</p>
                  </Col>

                  <Col xs={2} sm={2} md={2} lg={2} xl={2} style={{ alignContent: 'center', textAlign: 'center' }}>
                    {/* <Link to={item?.receipt_url || ''} target="_blank"  > */}
                    <DownloadOutlined style={{ fontSize: '2rem', color: '#1890ff', cursor: 'pointer' }} onClick={() => handleDownloadClick(data?.attachment_type, data)} />
                    {/* </Link> */}

                  </Col>

                </Row>
              </Card>

            </>
          )
        })}
      </div>
    ) : (
      <Card style={{ textAlign: 'center' }} className="mb15">
        No shared document found.
      </Card>
    );
};
