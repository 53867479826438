import { Card, Row, Col, Button } from 'antd';
import howItWork4 from '../../assets/images/howItWork4.png';
import { ArrowRightOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FAQs from './FAQs';
import { isLogin } from '../../helpers/functions';
import useResponsive from '../../hooks/useBreakpoint';

const FaqPage = props => {
  const { type } = props;
  const navigate = useNavigate();
  const breakpoint = useResponsive();
  return (
    <>
      <div className="padding-around border"  >
        <div className="faq-page" >
          <p className="m-title tc ac-heading">Frequently Asked Questions</p>
          <div className="mt50 box" >
            <FAQs type={type} count={500} />
          </div>
        </div>
      </div>
      <div className="padding-around">
        <div className="border-bottom"></div>
      </div>
      <div className="padding-around contact-faq" >
        <div className="contact-section">
          <p className="title">How to contact you if I have further questions?</p>
          <p className="sub-title">
            Please visit our{' '}
            <span className="link cursor" onClick={() => navigate('/contact')}>
              Contact
            </span>{' '}
            page, leave your question, and we'll answer you as soon as possible, or email us at{' '}
            <span className="link">admin@accountizer.ca</span>
          </p>
        </div>
      </div>
      <section className="padding-around section6">
        {!isLogin() && (
          <Card>
            <Row gutter={75}>
              <Col xs={24} sm={24} md={24} lg={8} xl={12}  className="d-flex-center">
                <div>
                  <p className="title mb20">Ready to get started?</p>
                  <p className="sub-title mb25">
                    {type === 'client'
                      ? 'Sign up for free.'
                      : 'Sign up for free, fill out the online application form, complete your screening process, and get connected to boost your accounting career today.'}
                  </p>
                  <Button
                    className=""
                    size="large"
                    onClick={() => navigate(type === 'client' ? '/signup' : '/expert/signup')}
                  >
                    Get Started &nbsp;
                    <ArrowRightOutlined />
                    &nbsp;
                  </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={16} xl={12} style={{ marginTop: (breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md') ? '20px' : '0px' , display: 'flex', justifyContent: 'center' }} className="tr">
                <img loading='lazy' style={{ width: '100%' }} src={howItWork4} alt="Accountizer Homepage"></img>
              </Col>
            </Row>
          </Card>
        )}
      </section>
    </>
  );
};

export default FaqPage;
