import { put, takeLatest, call } from 'redux-saga/effects';
//Import API Service.
import { getRequestAPI, postRequestAPI, postRequestForMessagingAPI } from '../../helpers/service';
import { json } from 'react-router-dom';
import { message } from 'antd';
import { MESSAGE_TYPE } from '../../helpers/constant';

function* getAllMyMessageThread() {
  try {
    const response = yield call(getRequestAPI, { url: `my-threads`, params: {} });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_MESSAGE_THREAD_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'GET_MESSAGE_THREAD_ERROR' });
    }
  } catch (error) {
    yield put({ type: 'GET_MESSAGE_THREAD_ERROR' });
  }
}

function* postMessage({ formData = {} }) {
  try {
    const response = yield call(postRequestAPI, {
      url: `threads/add/message/${formData.id}`,
      data: {
        message: formData.message,
        message_type: formData?.type || 'TEXT',
        attachment_type: formData?.attachment_type || 'message',
        has_attachment: formData?.has_attachment !== undefined ? formData?.has_attachment : 1,
      },
    });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'POST_MESSAGE_STATUS_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'POST_MESSAGE_STATUS_ERROR' });
    }
  } catch (error) {
    yield put({ type: 'POST_MESSAGE_STATUS_ERROR' });
  }
}
function* postZoomMeeting({ formData1 = {} }) {
  try {
    const response = yield call(postRequestAPI, {
      url: `generate-meeting`,
      data: formData1,
    });
    if (response.data.code === 200 || response.data.code === 201) {
      let resData = response.data.result;
      resData = { ...resData, "party_a_creator_name": formData1?.party_a_creator_name, "party_b_name": formData1?.party_b_name };

      const message = {
        has_attachment: 0,
        message: JSON.stringify({ data: resData, msg: formData1.message }),
        message_type: MESSAGE_TYPE.JOIN_MEETING,
      };
      const res = yield call(postRequestAPI, {
        url: `threads/add/message/${formData1.id}`,
        data: message,
      });

      if (res.status === 200 || res.status === 201) {
        yield put({ type: 'POST_MESSAGE_STATUS_SUCCESS', data: res.data || [] });
      } else {
        yield put({ type: 'POST_MESSAGE_STATUS_ERROR' });
      }
    } else {
      yield put({ type: 'POST_MESSAGE_STATUS_ERROR' });
    }
  } catch (error) {
    yield put({ type: 'POST_MESSAGE_STATUS_ERROR' });
  }
}
function* generateScheduleMeeting({ formData = {} }) {
  try {
    const response = yield call(postRequestAPI, {
      url: `generate-meeting`,
      data: formData,
    });
    if (response.data.code === 200 || response.data.code === 201) {
      const message = {
        has_attachment: 0,
        message: JSON.stringify({ data: response.data.result }),
        message_type: MESSAGE_TYPE.SCHEDULE_MEETING,
      };
      const res = yield call(postRequestAPI, {
        url: `threads/add/message/${formData.id}`,
        data: message,
      });
      if (res.status === 200 || res.status === 201) {
        yield put({ type: 'POST_MESSAGE_STATUS_SUCCESS', data: response.data || [] });
      } else {
        yield put({ type: 'POST_MESSAGE_STATUS_ERROR' });
      }
    }
  } catch (error) {
    yield put({ type: 'POST_MESSAGE_STATUS_ERROR' });
  }
}
function* createNewMessageThread({ formData = {} }) {
  try {
    const response = yield call(postRequestAPI, { url: `threads/add`, data: formData });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'POST_NEW_MESSAGE_THREAD_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'POST_NEW_MESSAGE_THREAD_ERROR' });
    }
  } catch (error) {
    yield put({ type: 'POST_NEW_MESSAGE_THREAD_ERROR' });
  }
}

function* getThreadBySubjectId({ id, firstMessageDisplayId }) {
  try {
    const response = yield call(postRequestForMessagingAPI,
      { url: `threads/${id}`, data: { firstMessageDisplayId: firstMessageDisplayId } });

    if (response.status === 200) {

      if (firstMessageDisplayId) {

        yield put({ type: 'ADD_NEW_MESSAGE_IN_THREAD', data: response.data?.messages || {} });

      } else {

        yield put({ type: 'GET_THREAD_BY_SUBJECT_ID_SUCCESS', data: response.data || {} });
      }
    } else {

      if (firstMessageDisplayId) {

        yield put({ type: 'ADD_NEW_MESSAGE_IN_THREAD_ERROR', error: true });

      } else {

        yield put({ type: 'GET_THREAD_BY_SUBJECT_ID_ERROR', error: response });
      }
    }
  } catch (error) {
    console.log(error);

    yield put({ type: 'GET_THREAD_BY_SUBJECT_ID_ERROR', error: error.message || '' });
  }
}

function* getUnreadMessages() {
  try {
    const response = yield call(getRequestAPI, { url: `thread/unread`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'GET_ALL_UNREAD_MESSAGES_SUCCESS', data: response.data || {} });
    } else {
      yield put({ type: 'GET_ALL_UNREAD_MESSAGES_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_ALL_UNREAD_MESSAGES_ERROR', error: error.message || '' });
  }
}

function* markAllMessagesByThreadId({ formData = {} }) {
  try {
    const response = yield call(postRequestAPI, { url: `thread/mark-as-read`, data: formData });
    if (response.status === 200) {
      yield put({ type: 'MARK_ALL_MESSAGES_AS_READ_SUCCESS', data: response.data || {} });
    } else {
      yield put({ type: 'MARK_ALL_MESSAGES_AS_READ_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'MARK_ALL_MESSAGES_AS_READ_ERROR', error: error.message || '' });
  }
}

function* uploadImageToDB({ formData,modeOfFile,path }) {
  try {
    const response = yield call(postRequestAPI, { url: 'uploading-file', data: formData });
    if (response.status === 200) {
      if (response?.data?.success) {
        yield put({ type: 'UPLOAD_IMAGE_TO_DB_SUCCESS', data: response.data?.url || '' });
      } else {
        yield put({ type: 'UPLOAD_IMAGE_TO_DB_ERROR', error: 'No record found' });
      }
    } else {
      yield put({ type: 'UPLOAD_IMAGE_TO_DB_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'UPLOAD_IMAGE_TO_DB_ERROR', error: error.message || '' });
  }
}

function* getAttachmentsByThreadId({ id }) {
  try {
    const response = yield call(getRequestAPI, { url: `thread/attachment/${id}`, params: {} });
    if (response.status === 200) {
      yield put({ type: 'GET_ATTACHMENTS_BY_THREAD_ID_SUCCESS', data: response.data || {} });
    } else {
      yield put({ type: 'GET_ATTACHMENTS_BY_THREAD_ID_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_ATTACHMENTS_BY_THREAD_ID_ERROR', error: error.message || '' });
  }
}

function* setSelectedChatGroup({ id }) {
  yield put({ type: 'SET_SELECTED_TAB_GROUP_SUCCESS', data: id });
}
function* getMeetingSignature({ meetingNumber, role, passWord }) {
  try {
    const response = yield call(postRequestAPI, {
      url: 'generate-signature',
      data: { meetingNumber, role, passWord },
    });
    if (response.status === 200) {
      yield put({ type: 'ZOOM_MEETING_SIGNATURE_SUCCESS', data: response?.data || '' });
    } else {
      yield put({ type: 'ZOOM_MEETING_SIGNATURE_EMPTY', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'ZOOM_MEETING_SIGNATURE_EMPTY', error: error.message || '' });
  }
}

function* getDateTimeForSpecificTimeZone({ formData }) {
  try {
    const response = yield call(postRequestAPI, {
      url: 'get-date-time-for-timezone',
      data: {
        data: formData.data,
        toTimezone: formData.toTimezone,
      },
    });
    if (response.status === 200) {
      yield put({ type: 'TIMEZONE_RECORD', data: response?.data?.data || '' });
    } else {
      yield put({ type: 'TIMEZONE_RECORD_EMPTY', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'TIMEZONE_RECORD_EMPTY', error: error.message || '' });
  }
}

function* watchCommonSagaRequests() {
  yield takeLatest('GET_THREAD_BY_SUBJECT_ID', getThreadBySubjectId);
  yield takeLatest('GET_ALL_UNREAD_MESSAGES', getUnreadMessages);
  yield takeLatest('GET_ATTACHMENTS_BY_THREAD_ID', getAttachmentsByThreadId);
  yield takeLatest('GET_MESSAGE_THREAD', getAllMyMessageThread);
  yield takeLatest('SET_SELECTED_TAB_GROUP', setSelectedChatGroup);
  yield takeLatest('POST_NEW_MESSAGE_THREAD', createNewMessageThread);
  yield takeLatest('POST_MESSAGE', postMessage);
  yield takeLatest('POST_ZOOM_MEETING', postZoomMeeting);
  yield takeLatest('GENERATE_SCHDULE_MEETING', generateScheduleMeeting);
  yield takeLatest('MARK_ALL_MESSAGES_AS_READ', markAllMessagesByThreadId);
  yield takeLatest('UPLOAD_IMAGE_TO_DB', uploadImageToDB);
  yield takeLatest('GET_MEETING_SIGNATURE', getMeetingSignature);
  yield takeLatest('GET_DATE_TIME_FOR_TIMEZONE', getDateTimeForSpecificTimeZone);
}

export function* MessagingSaga() {
  yield watchCommonSagaRequests();
}
