import { Spin, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpertRequestCard from '../common/expertRequestCard';
import { useNavigate } from 'react-router-dom';
import { getUserData } from '../../helpers/functions';

export const ExpertHistoryList = () => {
  const dispatch = useDispatch();
  const { isGetExpertRequestHistoryLoading, expertRequestHistory } = useSelector(
    state => state.requests,
  );
  const { isUpdateQuestionStatusLoading } = useSelector(state => state.common);

  const [selectedTabName, setSelectedTabName] = useState('jobCompleted');


  const navigate = useNavigate();
  const { expertCreditById } = useSelector(state => state.expert);
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  useEffect(() => {
    dispatch({ type: 'GET_EXPERT_REQUEST_HISTORY_VIEW', requestType: ((selectedTabName == "jobCompleted") ? "2" : "1") });
  }, [expertRequestHistory?.data?.length, isUpdateQuestionStatusLoading, selectedTabName]);

  useEffect(() => {
    dispatch({
      type: 'GET_EARNING_BY_ID',
      formData: {
        client_id: getUserData().id,
      },
    });
    dispatch({ type: 'GET_CREDIT_BY_ID', id: getUserData().id });
  }, []);

  const onActiveRequestsClick = data => {
    navigate(`/dashboard/expert/history/request/${data.id}`, {
      state: data,
    });
  };

  const activeRequests = () => {
    return (
      <>
        {isGetExpertRequestHistoryLoading ? (
          <div
            style={{ height: (height * 25) / 100 }}
            className="expert-empty-new-request-container"
          >
            <Spin />
          </div>
        ) : expertRequestHistory.data?.length ? (
          expertRequestHistory?.data?.map(el => {
            return (
              <ExpertRequestCard
                {...el}
                type={'RequestHistory'}
                isClickable={true}
                onClick={() => onActiveRequestsClick(el)}
              />
            );
          })
        ) : (
          <div
            style={{ height: (height * 25) / 100 }}
            className="expert-empty-new-request-container"
          >
            No History.
          </div>
        )}
      </>
    );
  };


  const setTabNameAndKey = data => {
    switch (data) {
      case 1:
        setSelectedTabName('jobCompleted');
        break;
      case 2:
        setSelectedTabName('answerdQuestions');
        break;
      default:
        return;
    }
  };

  const tabBarItems = [
    {
      label: `Jobs Completed`,
      key: 1,
      children: activeRequests(),
    },
    {
      label: `Answered`,
      key: 2,
      children: activeRequests(),
    },
  ];

  return (
    <div className="expert-container">
      <div className="expert-requests-title">My History</div>
      <div className="expert-question-stats-container">
        <div style={{ width: '49%' }} className="expert-question-stats-card">
          <div className="expert-question-stats-card-content-wrapper">
            <div className="expert-question-stats-card-content-title">Job Completed</div>
            <div className="expert-question-stats-card-content-value">
              {expertRequestHistory?.data?.length ? expertRequestHistory?.data?.length : 0}
            </div>
          </div>
        </div>
        <div style={{ width: '49%' }} className="expert-question-stats-card">
          <div className="expert-question-stats-card-content-wrapper">
            <div className="expert-question-stats-card-content-title">Earned To Date</div>
            <div className="d-flex expert-question-stats-card-content-title-container">
              <div className="expert-question-stats-card-content-value">
                CA${expertCreditById?.credit?.amount ? expertCreditById?.credit?.amount : '00.00'}
              </div>
              <div className="d-flex-a-c expert-question-stats-card-content-sub-value">
                {/* <div className='expert-question-stats-card-content-sub-value-text'>
                  +CA{expertCreditById?.credit?.amount ? expertCreditById?.credit?.amount : 0}
                </div>
                <div className='expert-question-stats-card-content-sub-value-icon'>
                  <Tooltip title="Extra credit added by the admin" placement='bottom'>
                    <img src={infoIcon} alt="info" height={10} width={10} />
                  </Tooltip>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="expert-tab-navigator-container">
        <Tabs items={tabBarItems} onChange={setTabNameAndKey} />
      </div>
    </div>
  );
};
