/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Form, Button, Spin, Radio, Space, Select } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserData } from '../../helpers/functions';
import QuestionSuccessModal from '../common/modal/questionSuccess';
import CustomSelect from '../common/customSelect';
import useResponsive from '../../hooks/useBreakpoint';

const Step6 = ({ handleNext }) => {
  const dispatch = useDispatch();
  const { qid } = useParams();
  const [form] = Form.useForm();
  const { isLocationLoading, locationList, isQuestionLoading, questionDetail } = useSelector(
    state => state.common,
  );
  const { isStepUpdating, stepUpdateMsg, isQuestionUpdating, questionUpdateMsg } = useSelector(
    state => state.steps,
  );
  const [visibleModal, setVisibleModal] = useState(false);
  useEffect(() => {
    if (qid) {
      dispatch({ type: 'GET_QUESTION_DETAIL', id: qid });
    }
    dispatch({ type: 'GET_LOCATION_REQUEST', formData: {} });
  }, []);

  useEffect(() => {
    if (!isQuestionLoading && Object.keys(questionDetail).length > 0) {
      const locationId = questionDetail.location !== null ? questionDetail.location?.id : '';
      form.setFieldsValue({ location_id: locationId });
    }
  }, [isQuestionLoading, questionDetail]);

  const onFinish = values => {
    if (qid) {
      dispatch({
        type: 'QUESTION_STEP_UPDATE_REQUEST',
        formData: {
          ...values,
          user_id: getUserData().id,
          id: parseInt(qid),
        },
        requestType: 'post',
        step: 6,
      });
    }
  };

  useEffect(() => {
    if (!isStepUpdating && stepUpdateMsg === 'success') {
      if (qid) {
        dispatch({
          type: 'QUESTION_STATUS_UPDATE_REQUEST',
          formData: {
            request_submitted: 1,
            user_id: getUserData().id,
            id: parseInt(qid),
          },
        });
      }
      dispatch({ type: 'CLEAR_STEPS' });
    }
  }, [isStepUpdating, stepUpdateMsg]);

  useEffect(() => {
    if (!isStepUpdating && stepUpdateMsg === 'success') {
      if (questionDetail?.expert_id) {
        setVisibleModal(true);
        dispatch({ type: 'CLEAR_STEPS' });
      } else {
        handleNext(qid, 'matching-automation');
        dispatch({ type: 'CLEAR_STEPS' });
      }
    }
  }, [isStepUpdating, stepUpdateMsg]);

  const optionsList = tmpLocation => {
    let locationOptions = [];
    if (tmpLocation.length > 0) {
      tmpLocation.map(item => {
        locationOptions.push({
          value: item.id,
          label: item.name,
        });
      });
    }
    return locationOptions;
  };
  const breakpoint = useResponsive();
  return (
    <div>
      <div className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={false}>
          {isLocationLoading && <Spin />}
          {!isLocationLoading && (
            <>
              <p className="m-title tl mb-7">Location</p>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                form={form}
                onFinish={onFinish}
                style={{ width: (breakpoint == "md" || breakpoint == "sm" || breakpoint == "xs") && "75vw" }}
              >
                <Form.Item
                  name="location_id"
                  rules={[{ required: true, message: 'Please select location' }]}
                >
                  <CustomSelect
                    placeholder="Select an option"
                    style={{
                      // width: '100%',
                    }}
                    size="large"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={optionsList(locationList)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    className="mt-5"
                    disabled={isStepUpdating}
                    loading={isStepUpdating}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </Card>
      </div>
      {visibleModal && (
        <QuestionSuccessModal
          visible={visibleModal}
          setVisible={setVisibleModal}
          navigateTo="/dashboard/customer"
        />
      )}
    </div>
  );
};
export default Step6;
