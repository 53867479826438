import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RequestHistoryCard from '../common/requestHistoryCard';
import { Spin } from 'antd';

export const RequestHistory = () => {
  const dispatch = useDispatch();
  const { requestsHistory, isRequestsHistoryLoading, isPostReviewsLoading } = useSelector(
    state => state.requests,
  );

  useEffect(() => {
    dispatch({ type: 'GET_REQUESTS_HISTORY' });
  }, [dispatch, isPostReviewsLoading]);
  const navigate = useNavigate();
  const handleOnRequestClick = data => {
    navigate(`/dashboard/customer/history/${data.id}`, {
      state: data,
    });
  };
  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  return (
    <div>
      <p className="requests-title">Requests History</p>
      {isRequestsHistoryLoading ? (
        <div
          style={{ width: (width * 80) / 100, height: (height * 50) / 100 }}
          className="d-flex-a-c"
        >
          <Spin />
        </div>
      ) : !requestsHistory?.data?.length ? (
        <div
          style={{ width: (width * 80) / 100, height: (height * 50) / 100 }}
          className="d-flex-a-c"
        >
          History Empty.
        </div>
      ) : (
        requestsHistory?.data?.map((data, index) => {
          return (
            <div style={{ backgroundColor: '#FFFFFF' }} key={index}>
              <RequestHistoryCard
                {...data}
                isClickable={true}
                list = {true}
                onRequestClick={() => handleOnRequestClick(data)}
              />
            </div>
          );
        })
      )}
    </div>
  );
};
