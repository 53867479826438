import { Select } from 'antd';
import React, { useRef, useState } from 'react';

export default function CustomSelect({ ...props }) {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const selectRef = useRef(null);
  return (
    <Select
      ref={selectRef}
      placeholder="Select an option"
      open={isSelectOpen}
      onFocus={e => {
        setIsSelectOpen(true);
      }}
      onBlur={e => {
        setIsSelectOpen(false);
      }}
      onSelect={e => {
        setIsSelectOpen(false);
        selectRef.current.blur();
      }}
      {...props}
      style={{ width: '100%',backgroundColor:"" }}
    />
  );
}
