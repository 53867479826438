import React from 'react';

export const PaymentIconForNavigation = props => {
  const { isActive } = props;
  return (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.0751 0.699219C1.65075 0.699219 1.24378 0.86779 0.943727 1.16785C0.643669 1.46791 0.475098 1.87487 0.475098 2.29922V5.49922C0.475098 5.92357 0.643669 6.33053 0.943727 6.63059C1.24378 6.93065 1.65075 7.09922 2.0751 7.09922V2.29922H10.0751C10.0751 1.87487 9.90653 1.46791 9.60647 1.16785C9.30641 0.86779 8.89944 0.699219 8.4751 0.699219H2.0751ZM3.6751 5.49922C3.6751 5.07487 3.84367 4.66791 4.14373 4.36785C4.44378 4.06779 4.85075 3.89922 5.2751 3.89922H11.6751C12.0994 3.89922 12.5064 4.06779 12.8065 4.36785C13.1065 4.66791 13.2751 5.07487 13.2751 5.49922V8.69922C13.2751 9.12357 13.1065 9.53053 12.8065 9.83059C12.5064 10.1306 12.0994 10.2992 11.6751 10.2992H5.2751C4.85075 10.2992 4.44378 10.1306 4.14373 9.83059C3.84367 9.53053 3.6751 9.12357 3.6751 8.69922V5.49922ZM8.4751 8.69922C8.89944 8.69922 9.30641 8.53065 9.60647 8.23059C9.90653 7.93053 10.0751 7.52357 10.0751 7.09922C10.0751 6.67487 9.90653 6.26791 9.60647 5.96785C9.30641 5.66779 8.89944 5.49922 8.4751 5.49922C8.05075 5.49922 7.64378 5.66779 7.34373 5.96785C7.04367 6.26791 6.8751 6.67487 6.8751 7.09922C6.8751 7.52357 7.04367 7.93053 7.34373 8.23059C7.64378 8.53065 8.05075 8.69922 8.4751 8.69922Z"
        fill={isActive ? '#ffffff' : '#6B7280'}
      />
    </svg>
  );
};
