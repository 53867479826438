import { put, takeLatest, call, all } from 'redux-saga/effects';
import { message } from 'antd';
//Import API Service.
import { getRequestAPI, postRequestAPI, putRequestAPI } from '../../helpers/service';

function* postQuestion({ formData, requestType, id }) {
  try {
    let response;
    if (requestType === 'post') {
      response = yield call(postRequestAPI, { url: `create-question`, data: formData });
    } else if (requestType === 'put') {
      response = yield call(putRequestAPI, { url: `update-question`, data: formData });
    }
    if (response.status === 200 || response.status === 201) {
      if (requestType === 'put') {
        yield put({ type: 'QUESTION_STEP_1_SUCCESS', data: response?.data?.question || {} });
      } else {
        yield put({ type: 'QUESTION_STEP_1_SUCCESS', data: response.data || '' });
      }
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'QUESTION_STEP_1_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({ type: 'QUESTION_STEP_1_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* postQuestion2({ formData, requestType, id }) {
  try {
    let response;
    if (requestType === 'post') {
      response = yield call(postRequestAPI, { url: `update-customer-type`, data: formData });
    }
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'QUESTION_STEP_2_SUCCESS', data: response.data || '' });
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'QUESTION_STEP_2_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({ type: 'QUESTION_STEP_2_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* questionStatusUpdate({ formData }) {
  try {
    let response;
    response = yield call(postRequestAPI, { url: `update-status`, data: formData });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'QUESTION_STATUS_UPDATE_SUCCESS', data: response.data || '' });
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'QUESTION_STATUS_UPDATE_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({
      type: 'QUESTION_STATUS_UPDATE_ERROR',
      error: error?.message || 'Something went wrong',
    });
  }
}

function* questionStepUpdate({ formData, requestType, step }) {
  try {
    let response;
    if (requestType === 'post') {
      let URL = '';
      if (step === 'update-status') {
        URL = 'update-status';
      } else if (step === 3) {
        URL = 'update-category';
      } else if (step === 4) {
        URL = 'update-service';
      } else if (step === 5) {
        URL = 'update-seniority';
      } else if (step === 6) {
        URL = 'update-location';
      } else if (step === 7) {
        URL = 'auto-assign';
      } else if (step === 'ownership') {
        URL = 'update-ownership';
      } else if (step === 'business-sector') {
        URL = 'update-sector';
      } else if (step === 'sub-category') {
        URL = 'update-sub-category';
      } else if (step === 'update-invoice') {
        URL = 'update-invoice';
      }
      response = yield call(postRequestAPI, { url: URL, data: formData });
    }
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'QUESTION_STEP_UPDATE_SUCCESS', data: response.data || '' });
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'QUESTION_STEP_UPDATE_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({
      type: 'QUESTION_STEP_UPDATE_ERROR',
      error: error?.message || 'Something went wrong',
    });
  }
}

function* expertStepUpdate({ formData, requestType, step }) {
  try {
    let response;
    if (requestType === 'put') {
      let URL = '';
      if (step === 'service-type') {
        URL = 'update-expert-service';
      } else if (step === 'customer-type') {
        URL = 'update-prefer-customer';
      } else if (step === 'main-category') {
        URL = 'update-expert-category';
      } else if (step === 'location') {
        URL = 'update-expert-location';
      } else if (step === 'language') {
        URL = 'update-expert-language';
      }
      response = yield call(putRequestAPI, { url: URL, data: formData });
    }
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'EXPERT_STEP_UPDATE_SUCCESS', data: response.data || '' });
    } else {
      message.error({
        content: response.message || 'Something went wrong',
        className: 'error-class',
        duration: 3,
      });
      yield put({ type: 'EXPERT_STEP_UPDATE_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({
      type: 'EXPERT_STEP_UPDATE_ERROR',
      error: error?.message || 'Something went wrong',
    });
  }
}

function* expertCertificateUpdate({ formData, moduleType = 'certificate' }) {
  try {
    let URL = '';
    if (moduleType === 'certificate') {
      URL = 'licenses-and-certificates/add';
    } else if (moduleType === 'experince') {
      URL = 'experience-in-industry/add';
    } else if (moduleType === 'mainSubCat') {
      URL = 'services-and-rate/add';
    } else if (moduleType === 'degree') {
      URL = 'university-degrees/add';
    }
    const response = yield call(putRequestAPI, { url: URL, data: formData });

    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'EXPERT_CERTIFICATE_UPDATE_SUCCESS', data: response.data || '' });
    } else {
      yield put({ type: 'EXPERT_CERTIFICATE_UPDATE_ERROR', data: response?.message || '' });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({
      type: 'QUESTION_STATUS_UPDATE_ERROR',
      error: error?.message || 'Something went wrong',
    });
  }
}

function* postQuestionSubCategory({ formData }) {
  try {
    const response = yield call(postRequestAPI, {
      url: `sub-categories`,
      data: {
        parent_id: formData?.parent_id,
        per_page: formData?.per_page || 10,
        page: formData?.page,
      },
    });
    if (response.status === 200) {
      yield put({ type: 'GET_SUB_CATEGORY_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'GET_SUB_CATEGORY_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'GET_SUB_CATEGORY_ERROR', error: error.message || '' });
  }
}

function* Empty_SUB_CATEGORY() {
  yield put({ type: 'Empty_SUB_CATEGORY' });
}

function* postFullQuestion({ formData }) {
  try {
    const response = yield call(postRequestAPI, {
      url: `create-question-with-all-data`,
      data: formData.formData,
    });
    if (response.status === 200) {
      yield put({ type: 'FULL_QUESTION_CREATE_SUCCESS', data: response.data || [] });
    } else {
      yield put({ type: 'FULL_QUESTION_CREATE_ERROR', error: 'No record found' });
    }
  } catch (error) {
    yield put({ type: 'FULL_QUESTION_CREATE_ERROR', error: error.message || '' });
  }
}

function* generateQuestionWithAI({ formData }) {
  try {
    const response = yield call(postRequestAPI, { url: `generate-question-with-ai`, data: formData });
    if (response.status === 200 && response.data?.success === true) {
      yield put({ type: 'GENRATE_QUESTION_WITH_AI_SUCCESS', data: response.data?.result || [] });
    } else {
      yield put({ type: 'GENRATE_QUESTION_WITH_AI_ERROR', error: 'Not Genrated!' });
    }
  } catch (error) {
    yield put({ type: 'GENRATE_QUESTION_WITH_AI_ERROR', error: error.message || '' });
  }
}

function* watchStepsSagaRequests() {
  yield takeLatest('QUESTION_STEP_1_REQUEST', postQuestion);
  yield takeLatest('QUESTION_STEP_2_REQUEST', postQuestion2);
  yield takeLatest('QUESTION_STEP_UPDATE_REQUEST', questionStepUpdate);
  yield takeLatest('QUESTION_STATUS_UPDATE_REQUEST', questionStatusUpdate);
  yield takeLatest('EXPERT_STEP_UPDATE_REQUEST', expertStepUpdate);
  yield takeLatest('EXPERT_CERTIFICATE_UPDATE_REQUEST', expertCertificateUpdate);
  yield takeLatest('QUESTION_SUB_CATEGORY_REQUEST', postQuestionSubCategory);
  yield takeLatest('EMPTY_SUB_CATEGORY', Empty_SUB_CATEGORY);
  yield takeLatest('FULL_QUESTION_CREATE_REQUEST', postFullQuestion);
  yield takeLatest('GENRATE_QUESTION_WITH_AI', generateQuestionWithAI);

}

function* StepsSaga() {
  yield watchStepsSagaRequests();
}

export default StepsSaga;
