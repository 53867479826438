/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Radio, Row, Spin } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { AssignedToExpertCard } from '../common/assignedToExpertCard';
import RequestHistoryCard from '../common/requestHistoryCard';
import { ProcessHistoryStepsCard } from '../common/processHistoryStepsCard';
import ReactLinkify from 'react-linkify';
import PaymentDetails from './paymentDetails';
import { AdjustIconForNavigation } from '../../assets/images/components/AdjustIconForNavigation';
import { ProfileIconForNavigation } from '../../assets/images/components/ProfileIconForNavigation';
import { PaymentIconForNavigation } from '../../assets/images/components/PaymentIconForNavigation';
import { ArchiveIconForNavigation } from '../../assets/images/components/ArchiveForNavigation';
import { ArchiveSection } from './archiveSection';
import { getSubCategoryName } from '../../helpers/functions';
import useResponsive from '../../hooks/useBreakpoint';

const RequestHistoryDetail = () => {
  const [selectedTab, setSelectedTab] = useState('request');

  const dispatch = useDispatch();
  const { state } = useLocation();
  const { questionDetail, isQuestionLoading } = useSelector(state => state.common);
  const {
    isQuestionRequestStatusLoading,
    requestQuestionStatus,
    isPostReviewsLoading,
    isCreateNewThreadLoading,
  } = useSelector(state => state.requests);
  const { qid } = useParams();
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  useEffect(() => {
    if (qid) {
      dispatch({ type: 'GET_QUESTION_DETAIL', id: qid });
      dispatch({ type: 'GET_QUESTION_REQUEST_STATUS', formData: { question_id: qid } });
    }
  }, [isPostReviewsLoading, qid]);

  useEffect(() => {
    const divToScrollTo = document.getElementById('answerDiv');
    if (divToScrollTo) {
      divToScrollTo.scrollIntoView({ behavior: 'smooth' });
    }
    if (questionDetail?.question_type?.id == 2) {
      setSelectedTab('archive');
    } else {
      setSelectedTab('request');
    }
  }, [isQuestionRequestStatusLoading, questionDetail]);

  const isStatusAssigned = id => {
    if (id >= 3) {
      return true;
    }
    return false;
  };

  const renderUsingSwitch = () => {
    switch (selectedTab) {
      case 'request':
        return (
          <div className="request-details">
            <h3>Request Details</h3>
            <div className="request-details-wrapper">
              <h6>Question</h6>
              {questionDetail?.status?.question_status_id !== 3 && (
                <div className="general">
                  <ReactLinkify>{questionDetail?.question_disc}</ReactLinkify>
                </div>
              )}
              {questionDetail?.status?.question_status_id === 11 &&
                questionDetail?.question_type?.id === 1 && (
                  <div style={{ marginBlock: '20px' }}>
                    <h6>Answer</h6>
                    {questionDetail?.status?.question_status_id !== 3 && (
                      <div className="general" id="answerDiv">
                        <ReactLinkify>{questionDetail?.question_detail?.my_answer}</ReactLinkify>
                      </div>
                    )}
                  </div>
                )}
              <div className="request-group">
                {isStatusAssigned(questionDetail?.status?.question_status_id) && (
                  <>
                    <div>
                      <h6>I need to contact an expert for a</h6>
                      <p>{questionDetail?.question_type?.name}</p>
                    </div>
                  </>
                )}
                <div>
                  <h6>I am (a/an) or representing (a/an)</h6>
                  <p>{questionDetail?.customer_type?.name}</p>
                </div>
                <div>
                  <h6>Service category</h6>
                  <p>{questionDetail?.category?.name}</p>
                </div>
                {!isStatusAssigned(questionDetail?.status?.question_status_id) && (
                  <div>
                    <h6>Type of service</h6>
                    <p>{questionDetail?.service?.customer_label}</p>
                  </div>
                )}
                {isStatusAssigned(questionDetail?.status?.question_status_id) && (
                  <>
                    <div>
                      <h6>
                        {questionDetail?.category?.name
                          ? questionDetail?.category?.name + ' Subcategory'
                          : '-'}
                      </h6>
                      <p>{getSubCategoryName(questionDetail)}</p>
                    </div>
                    <div>
                      <h6>Business Sector</h6>
                      <p>{questionDetail?.sector?.name}</p>
                    </div>
                    <div>
                      <h6>How many invoices do you handle per month?</h6>
                      <p>{questionDetail?.invoices?.name}</p>
                    </div>
                  </>
                )}
                {!isStatusAssigned(questionDetail?.status?.question_status_id) && (
                  <div>
                    <h6>Estimated level of seniority required</h6>
                    <p>{questionDetail?.seniority?.name}</p>
                  </div>
                )}

                {isStatusAssigned(questionDetail?.status?.question_status_id) &&
                  questionDetail?.ownership && (
                    <div>
                      <h6>Ownership type</h6>
                      <p>{questionDetail?.ownership?.name}</p>
                    </div>
                  )}
                <div>
                  <h6>Location</h6>
                  <p>{questionDetail?.location?.name}</p>
                </div>
              </div>
            </div>
          </div>
        );
      case 'messaging':
        return (
          <div className="d-flex-a-c" style={{ height: (height * 60) / 100, width: '100%' }}>
            Messaging
          </div>
        );
      case 'payment':
        return (
          <div className="payment-detail">
            <PaymentDetails />
          </div>
        );
      case 'archive':
        return (
          <div className="archive-body">
            <ArchiveSection subjectId={qid} />
          </div>
        );
      default:
        return;
    }
  };

  const breakpoint = useResponsive();

  return !isQuestionRequestStatusLoading && !isQuestionLoading ? (
    <div className="request-details-body">
      <RequestHistoryCard {...state} {...questionDetail} />

      <Row className="request">
        <Col xs={24} sm={24} md={24} lg={17} className="request-wrapper">
          {isStatusAssigned(questionDetail?.status?.question_status_id) && (
            <>
              <AssignedToExpertCard
                {...questionDetail}
                data={{ status: questionDetail?.status }}
                type="review"
              />
              <div>
                <Radio.Group
                  className="request-tabs-navigator-container"
                  buttonStyle="solid"
                  defaultValue={selectedTab}
                  onChange={e => setSelectedTab(e.target.value)}
                >
                  <Radio.Button className="request-tabs-navigator-tabs" value="request">
                    <div className="request-tabs-navigator-tabs-div">
                      {(breakpoint === 'xl' || breakpoint === 'xxl') && (
                        <ProfileIconForNavigation isActive={selectedTab === 'request'} />
                      )}
                      
                      Request Details
                    </div>
                  </Radio.Button>
                  <Radio.Button disabled className="request-tabs-navigator-tabs" value="messaging">
                    <div className="request-tabs-navigator-tabs-div">
                    {(breakpoint === 'xl' || breakpoint === 'xxl') && (
                          <AdjustIconForNavigation isActive={selectedTab === 'messaging'} />
                      )}
                  
                      Messaging
                    </div>
                  </Radio.Button>
                  <Radio.Button className="request-tabs-navigator-tabs" value="payment">
                    <div className="request-tabs-navigator-tabs-div">
                    {(breakpoint === 'xl' || breakpoint === 'xxl') && (
                         <PaymentIconForNavigation isActive={selectedTab === 'payment'} />
                      )}
                     
                      Payment Details
                    </div>
                  </Radio.Button>
                  <Radio.Button className="request-tabs-navigator-tabs" value="archive">
                    <div className="request-tabs-navigator-tabs-div">
                    {(breakpoint === 'xl' || breakpoint === 'xxl') && (
                      <ArchiveIconForNavigation isActive={selectedTab === 'archive'} />
                      )}
                 
                      Archive
                    </div>
                  </Radio.Button>
                </Radio.Group>
              </div>
            </>
          )}
          <div className="request-container">{renderUsingSwitch()}</div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6}>
          <ProcessHistoryStepsCard
            rawData={questionDetail}
            showAssignedExpert={true}
            questionStatus={requestQuestionStatus}
          />
        </Col>
      </Row>
    </div>
  ) : (
    <div style={{ height: (height * 60) / 100 }} className="d-flex-a-c">
      <Spin />
    </div>
  );
};

export default RequestHistoryDetail;
