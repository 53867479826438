import React, { useState } from 'react';
import { Layout, Button, Popover, Avatar } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import MainLogo from '../../assets/images/Acc_logo/logo_blue.svg';
import WhiteLogo from '../../assets/images/white-logo.svg';
import { ROLE_NAME } from '../../helpers/constant';
import { getFullName, isLogin, logout, role, showAvatarName } from '../../helpers/functions';
import ExpertHeader from './expertHeader';
import useResponsive from '../../hooks/useBreakpoint';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import MobileHeader from './MobileHeader';
const { Header } = Layout;

const items = [
  {
    label: 'Find an Expert',
    key: 'find-expert',
  },
  {
    label: 'About Accountizer',
    key: 'about-us',
  },
  {
    label: 'Blogs',
    key: 'blogs',
  },
  {
    label: 'FAQs',
    key: 'faq',
  },
  {
    label: 'Contact',
    key: 'contact',
  },
  {
    label: 'Book a Demo',
    key: 'https://calendly.com/justin-m-accountizer',
  },
];

const PublicHeader = props => {
  const breakpoint = useResponsive();
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState('mail');
  const expertAvatar = localStorage.getItem('expertAvatar');
  const [showMenu, setShowMenu] = useState(false);
  const onClick = e => {
    if (
      e.key === 'about-us' ||
      e.key === 'contact' ||
      e.key === 'find-expert' ||
      e.key === 'faq' ||
      e.key === 'blogs'
    ) {
      navigate(`/${e.key}`);
      setCurrent(e.key);
    } else if (e.key === 'https://calendly.com/justin-m-accountizer') {
      window.open(e.key, '_blank');
      setCurrent('');
    } else {
      setCurrent('');
    }
  };

  const onLogoutClick = () => {
    logout();
    navigate('/login');
  };
  const content = (
    <div>
      <p>{getFullName()}</p>
      <p
        className="pointer"
        onClick={() => {
          if (role() === ROLE_NAME.EXPERT) {
            navigate('/dashboard/expert/request');
          } else {
            navigate('/dashboard/customer');
          }
        }}
      >
        Dashboard
      </p>
      {role() === ROLE_NAME.EXPERT && !isLogin() && (
        <p
          onClick={() => {
            navigate('/expert');
          }}
        >
          Become an Expert
        </p>
      )}
      <p>
        <span className="header-menu pointer" onClick={() => onLogoutClick()}>
          Logout
        </span>
      </p>
    </div>
  );

  return (
    <>
      {location.pathname.includes('/expert') || location.search.includes('type=expert') ? (
        <>
        <ExpertHeader />
        </>
      ) : (
        
        <Header
          style={{
            padding: `${breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' ? '0rem 1rem' : ''}`,
          }}
          className={`header ${location.pathname === '/' || location.pathname === '/expert' ? 'transfer' : ''}`}
        >
          <div className="d-flex-a-c" style={{ width: '100%', justifyContent: 'space-between' }}>
            {breakpoint === 'xs' ||
            breakpoint === 'sm' ||
            breakpoint === 'md' ||
            breakpoint === 'lg' ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                  }}
                >
                  <div className="main-logo">
                    {location.pathname === '/' || location.pathname === '/expert' ? (
                      <img
                        src={MainLogo}
                        onClick={() => navigate('/')}
                        className="pointer"
                        alt="Accountizer Logo "
                        style={{ width: '10rem' }}
                        loading="lazy"
                      ></img>
                    ) : (
                      <img
                        src={MainLogo}
                        onClick={() => navigate('/')}
                        className="pointer"
                        alt="Accountizer Logo "
                        style={{ width: '10rem' }}
                        loading="lazy"
                      ></img>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: '70%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                  }}
                >
                  {isLogin() && (
                    <div className="f-flex-a-e">
                      <div className="header-menu d-flex-a-center">
                        {expertAvatar ? (
                          <Popover
                            placement="bottom"
                            content={content}
                            trigger="hover"
                            zIndex={99999}
                          >
                            <img
                              style={{ height: '40px', width: '40px', borderRadius: '50%' }}
                              src={expertAvatar}
                              alt="avatar"
                              loading="lazy"
                            />
                          </Popover>
                        ) : (
                          <Popover
                            placement="bottom"
                            content={content}
                            trigger="hover"
                            zIndex={99999}
                          >
                            <Avatar size="large" className="pointer">
                              {showAvatarName()}
                            </Avatar>
                          </Popover>
                        )}
                      </div>
                    </div>
                  )}
                  {!isLogin() && !(breakpoint === 'xs' || breakpoint === 'sm') && (
                    <div className="d-flex-a-c">
                      <p
                        style={{ cursor: 'pointer' }}
                        className="mb0 mr15"
                        onClick={() => navigate('/login?type=customer')}
                      >
                        {
                          location.search.includes('type=customer') ? 'Expert Login' : 'Client Login'
                        }
                      </p>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="float-r"
                        size="middle"
                        onClick={() => navigate('/expert')}
                      >
                        Become an Expert
                      </Button>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    width: '10%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                  }}
                >
                  <MobileHeader
                    showMenu={showMenu}
                    setShowMenu={setShowMenu}
                    menuItems={items}
                    onClick={onClick}
                    publicheader={true}     
                  />
                </div>
              </div>
            ) : (
              <>
                <div>
                  <div className="main-logo">
                    {location.pathname === '/' || location.pathname === '/expert' ? (
                      <img
                        src={MainLogo}
                        onClick={() => navigate('/')}
                        className="pointer"
                        alt="Accountizer Logo "
                        style={{ width: '15vw' }}
                        loading="lazy"
                      ></img>
                    ) : (
                      <img
                        src={MainLogo}
                        onClick={() => navigate('/')}
                        className="pointer"
                        alt="Accountizer Logo "
                        style={{ width: '15vw' }}
                        loading="lazy"
                      ></img>
                    )}
                  </div>
                </div>
                <div className="d-flex-a-c">
                  <div className="header-menu public-header d-flex-a-c">
                    <div className="d-flex-a-c">
                      {items.map((item, idx) => {
                        return (
                          <p
                            key={idx}
                            className="mb0 menu-headings-font"
                            onClick={e => onClick(item)}
                          >
                            <span className="cursor-p">{item.label}</span>
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div>
                  {isLogin() && (
                    <div className="f-flex-a-e">
                      <div className="header-menu d-flex-a-center">
                        {expertAvatar ? (
                          <Popover
                            placement="bottom"
                            content={content}
                            trigger="hover"
                            zIndex={99999}
                          >
                            <img
                              style={{ height: '40px', width: '40px', borderRadius: '50%' }}
                              src={expertAvatar}
                              alt="avatar"
                              loading="lazy"
                            />
                          </Popover>
                        ) : (
                          <Popover
                            placement="bottom"
                            content={content}
                            trigger="hover"
                            zIndex={99999}
                          >
                            <Avatar size="large" className="pointer">
                              {showAvatarName()}
                            </Avatar>
                          </Popover>
                        )}
                      </div>
                    </div>
                  )}
                  {!isLogin() && !(breakpoint === 'xs' || breakpoint === 'sm') && (
                    <div className="d-flex-a-c">
                      <p
                        style={{ cursor: 'pointer' }}
                        className="mb0 mr15"
                        onClick={() => navigate(`${location.search.includes('type=customer') ? '/login?type=expert' : '/login?type=customer'}`)}
                      >
                        {
                          location.search.includes('type=customer') ? 'Expert Login' : 'Client Login'
                        }
                      </p>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="float-r"
                        size="middle"
                        onClick={() => navigate('/expert')}
                      >
                        Become an Expert
                      </Button>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </Header>
      )}
    </>
  );
};

export default PublicHeader;
