import { put, takeLatest, call } from 'redux-saga/effects';
import { message } from 'antd';
//Import API Service.
import { getRequestAPI, postRequestAPI, putRequestAPI } from '../../helpers/service';
import { encrypt } from '../../helpers/functions';
import axios from 'axios';
import { API_URL } from '../../helpers/constant';

function* loginRequest({ formData }) {
  try {
    let response = {};
    if (formData?.token) {

      response = { data: formData, status: 200 };

    } else {
      response = yield call(postRequestAPI, {
        url: `login?email=${formData.email}&password=${formData.password}`,
        data: formData,
      });
    }
    if (response.status === 200 || response.status === 201) {
      let loginPlainObj = response.data;
      let loginObj = JSON.stringify(response.data);
      if (loginPlainObj.email_verified_at === null) {
        localStorage.setItem('loggedInUser', encrypt(loginObj));
        localStorage.setItem('jwtTmp', response.data.token);
        yield put({ type: 'LOGIN_SUCCESS', loginMsg: 'not-verified', data: loginPlainObj || '' });
      } else {
        localStorage.setItem('loggedInUser', encrypt(loginObj));
        localStorage.setItem('jwt', response.data.token);
        localStorage.removeItem('jwtTmp', response.data.token);
        yield put({ type: 'LOGIN_SUCCESS', loginMsg: 'success', data: loginPlainObj || '' });
      }
    } else {
      yield put({ type: 'LOGIN_ERROR', error: 'error' });
    }
  } catch (error) {

    yield put({ type: 'LOGIN_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* otpRequest({ formData, isResend = false }) {
  try {
    let tmpURl = `resend/email/token`;
    const response = yield call(postRequestAPI, { url: tmpURl, data: formData });
    if (response.status === 200) {
      yield put({ type: 'OTP_SUCCESS', data: 'success' });
    } else {
      yield put({ type: 'OTP_ERROR', data: 'invalid' });
    }
  } catch (error) {
    message.error({
      content: 'Something went wrong, please try again',
      className: 'error-class',
      duration: 3,
    });
    yield put({ type: 'OTP_ERROR', data: 'somethingWrong' });
  }
}
function* signupRequest({ formData, signupType = 'customer' }) {
  try {
    let URL = '';
    if (signupType === 'customer') {
      URL = 'register';
    } else if (signupType === 'expert') {
      URL = 'register-expert';
    }

    const response = yield call(postRequestAPI, { url: URL, data: formData });

    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        localStorage.setItem('jwtTmp', response.data.token);
        yield put({ type: 'SIGNUP_SUCCESS', data: response.data, status: 'success' });
      } else {
        yield put({ type: 'SIGNUP_ERROR', data: response.data.data, status: 'error' });
      }
    } else {
      yield put({ type: 'SIGNUP_ERROR', data: 'invalid' });
    }
  } catch (error) {
    message.error({
      content: 'Something went wrong, please try again',
      className: 'error-class',
      duration: 3,
    });
    yield put({ type: 'SIGNUP_ERROR', data: 'somethingWrong' });
  }
}

function* signupRequestFromGoogle({ formData, signupType = 'customer' }) {
  try {

    const response = yield call(postRequestAPI, { url: "register-google-user", data: formData });

    if (response.status === 200 || response.status === 201) {

      if (response.data.success) {

        yield loginRequest({ formData: response.data });

      } else {
        yield put({ type: 'SIGNUP_ERROR', data: response.data.data, status: 'error' });
      }
    } else {
      yield put({ type: 'SIGNUP_ERROR', data: 'invalid', status: 'error' });
    }
  } catch (error) {
    message.error({
      content: 'Something went wrong, please try again',
      className: 'error-class',
      duration: 3,
    });
    yield put({ type: 'SIGNUP_ERROR', data: 'somethingWrong', status: 'error' });
  }
}

function* otpVerificationRequest({ formData }) {
  try {
    const response = yield call(postRequestAPI, { url: `email/verify`, data: formData });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'OTP_VERIFY_SUCCESS', data: 'success' });
    } else {
      yield put({ type: 'OTP_VERIFY_ERROR', data: 'invalid' });
    }
  } catch (error) {
    message.error({
      content: 'Something went wrong, please try again',
      className: 'error-class',
      duration: 3,
    });
    yield put({ type: 'OTP_VERIFY_ERROR', data: 'somethingWrong' });
  }
}

function* forgetPassword({ formData }) {
  try {
    const response = yield call(postRequestAPI, { url: `resend/email/token`, data: formData });
    if (response.status === 200) {
      yield put({ type: 'FORGET_PASSWORD_REQUEST_SUCCESS', data: 'success' });
    } else {
      yield put({ type: 'FORGET_PASSWORD_REQUEST_ERROR', error: 'invalid' });
    }
  } catch (error) {
    message.error({
      content: 'Something went wrong, please try again',
      className: 'error-class',
      duration: 3,
    });
    yield put({ type: 'FORGET_PASSWORD_REQUEST_ERROR', error: 'something went wrong!' });
  }
}

function* updateProfile({ formData }) {
  try {
    const response = yield call(putRequestAPI, { url: `account-update`, data: formData });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'UPDATE_PROFILE_SUCCESS', data: response.data || '' });
    } else {
      yield put({ type: 'UPDATE_PROFILE_ERROR', error: response });
    }
  } catch (error) {
    yield put({ type: 'UPDATE_PROFILE_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* getUserRequest({ formData }) {
  try {
    const response = yield call(getRequestAPI, { url: `user`, params: {} });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'GET_CURRENT_USER_SUCCESS', data: response.data || '' });
    } else {
      yield put({ type: 'GET_CURRENT_USER_ERROR', error: response });
    }
  } catch (error) {
    yield put({ type: 'GET_CURRENT_USER_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* changePasswordRequest({ formData }) {
  try {
    const response = yield call(postRequestAPI, { url: `reset-password`, data: formData });
    if (response.status === 200 || response.status === 201) {
      yield put({ type: 'CHANGE_PASSWORD_SUCCESS', data: response.data || '' });
    } else {
      yield put({ type: 'CHANGE_PASSWORD_ERROR', error: response });
    }
  } catch (error) {
    message.error(error?.message || 'Somethign went wrong');
    yield put({ type: 'CHANGE_PASSWORD_ERROR', error: error?.message || 'Something went wrong' });
  }
}

function* changePasswordRequestUsingToken({ formData }) {
  try {
    const response = yield call(postRequestAPI, {
      url: `forgot-password/reset-password`,
      data: formData,
    });
    if (response?.data?.success === false) {
      yield put({ type: 'CHANGE_PASSWORD_USING_TOKEN_ERROR', data: 'invalid' });
    } else {
      yield put({ type: 'CHANGE_PASSWORD_USING_TOKEN_SUCCESS', data: 'success' });
    }
  } catch (error) {
    message.error(error?.message || 'Something went wrong');
    yield put({
      type: 'CHANGE_PASSWORD_USING_TOKEN_ERROR',
      error: error?.message || 'Something went wrong',
    });
  }
}

function* getGoogleRedireactUrl() {
  try {

    const response = yield call(getRequestAPI, { url: `google/auth`, params: {} });

    if (response?.data?.success === false) {

      yield put({ type: 'GOOGLE_REDIREACT_URL_GET_ERROR', data: '' });


    } else {

      yield put({ type: 'GOOGLE_REDIREACT_URL_GET_SUCCESS', data: response?.data?.url || '' });

    }

  } catch (error) {

    yield put({
      type: 'GOOGLE_REDIREACT_URL_GET_ERROR',
      error: error?.message || 'Something went wrong',
    });

  }
}

function* googleCallBack({ params }) {
  try {

    // const response = yield call(getRequestAPI, { url: `google/auth/callback`, params:{ params} });
    const response = (
      async () => {
        const res = await axios.get(`${API_URL}google/auth/callback${params}`);
        return res;
      }
    )();
    if (response?.success === false) {

      yield put({ type: 'GOOGLE_CALLBACK_ERROR', data: response || '' });

    } else {

      yield put({ type: 'GOOGLE_CALLBACK_SUCCESS', data: response || {} });

    }

  } catch (error) {

    yield put({
      type: 'GOOGLE_CALLBACK_ERROR',
      error: error?.message || 'Something went wrong',
    });

  }
}

function* watchAuthSagaRequests() {
  yield takeLatest('LOGIN_REQUEST', loginRequest);
  yield takeLatest('SIGNUP_REQUEST', signupRequest);
  yield takeLatest('SIGNUP_REQUEST_FROM_GOOGLE', signupRequestFromGoogle);
  yield takeLatest('OTP_REQUEST', otpRequest);
  yield takeLatest('OTP_VERIFY_REQUEST', otpVerificationRequest);

  yield takeLatest('FORGET_PASSWORD_REQUEST', forgetPassword);
  yield takeLatest('UPDATE_PROFILE_REQUEST', updateProfile);
  yield takeLatest('GET_CURRENT_USER_REQUEST', getUserRequest);
  yield takeLatest('CHANGE_PASSWORD_REQUEST', changePasswordRequest);
  yield takeLatest('CHANGE_PASSWORD_USING_TOKEN', changePasswordRequestUsingToken);

  yield takeLatest('GOOGLE_REDIREACT_URL_GET', getGoogleRedireactUrl);
  yield takeLatest('GOOGLE_CALL_BACK', googleCallBack);
}

function* AuthSaga() {
  yield watchAuthSagaRequests();
}

export default AuthSaga;
