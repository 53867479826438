import { useState, useEffect } from 'react';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

const useResponsive = () => {
  const screens = useBreakpoint();
  const [currentBreakpoint, setCurrentBreakpoint] = useState('xs');

  const getActiveBreakpoint = () => {
    const breakpointOrder = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'];
    return breakpointOrder.find(breakpoint => screens[breakpoint]) || 'xs';
  };

  useEffect(() => {
    const updateBreakpoint = () => {
      const newBreakpoint = getActiveBreakpoint();
      setCurrentBreakpoint(newBreakpoint);
    };

    updateBreakpoint();

    window.addEventListener('resize', updateBreakpoint);

    return () => window.removeEventListener('resize', updateBreakpoint);
  }, [screens]);

  return currentBreakpoint;
};

export default useResponsive;
