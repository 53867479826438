import React, { useEffect, useState } from 'react';
import Helmet from '../../common/helmet';
import { Input, Table, Space, Popover, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { formatDateTo } from '../../../helpers/functions';
import DeleteModal from '../../common/modal/deleteModal';
import { AddCreditModal } from '../../common/modal/AddCreditModal';
import { AddCreditToAnyModal } from '../../common/modal/AddCreditModalToAnyExpert';

const { Search } = Input;
const { Column } = Table;

const AdminCreditsManagement = props => {
  const dispatch = useDispatch();
  const { isAdminDeleteCreditsLoading, isAdminUloading } = useSelector(state => state.admin);
  const { isCreditLoading, creditList } = useSelector(state => state.expert);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showAssignCreditToAnyExpertModal, setShowAssignCreditToAnyExpertModal] = useState(false);

  const [currentRecord, setCurrentRecord] = useState({});

  const content = record => {
    return (
      <div>
        <div
          style={{ color: 'black' }}
          className="action-popover"
          onClick={() => {
            setCurrentRecord(record);
            setTimeout(() => {
              setShowAssignModal(true);
            }, [100]);
          }}
        >
          Edit Credit
        </div>
        <div className="action-popover" onClick={() => setShowDeleteModal(true)}>
          Delete
        </div>
      </div>
    );
  };

  useEffect(() => {
    getAllCredit();
  }, [creditList?.data, isAdminUloading, isAdminDeleteCreditsLoading]);

  const getAllCredit = () => {
    dispatch({ type: 'ALL_CREDIT_REQUEST', formData: {} });
  };

  const doRefresh = () => {
    getAllCredit();
  };

  useEffect(() => {
    if (!isCreditLoading && creditList.length > 0) {
      let tmpCredit = [];
      creditList.map(item => {
        let creditObj = {};
        if (item.user !== null) {
          creditObj.expertName = `${item?.expert?.first_name} ${item?.expert?.last_name}`;
          creditObj.expert_id = item?.expert?.id;
          creditObj.date = formatDateTo(item?.created_at,'DD MMM, YYYY hh:mm A');
          creditObj.credit = item.credit_amount;
          creditObj.creditId = item.id;
        }
        tmpCredit.push(creditObj);
      });
      setFilteredTableData(tmpCredit);
    }
  }, [isAdminUloading, isCreditLoading, isAdminDeleteCreditsLoading]);

  return (
    <div className="admin-container">
      <Helmet title="Expert Management" />
      <div className="d-flex-between">
        <p className="title">Credit Management</p>
        <Button
          type="primary"
          htmlType="button"
          size="middle"
          className="mt5"
          onClick={() => {
            setCurrentRecord({});
            setShowAssignCreditToAnyExpertModal(true);
          }}
        >
          Add Credit
        </Button>
      </div>
      <div className="mt15">
        <Table
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
          }}
          loading={isCreditLoading}
          dataSource={filteredTableData}
        >
          <Column
            title="ID"
            dataIndex="creditId"
            key="creditId"
            sorter={(a, b) => a.creditId - b.creditId}
            defaultSortOrder={'ascend'}
            showSorterTooltip={false}
          />
          <Column title="Expert Name" dataIndex="expertName" key="expertName" />
          <Column
            title="Credit Amount"
            dataIndex="credit"
            key="credit"
            render={data => {
              return <div>{data + ' CAD$'}</div>;
            }}
          />
          <Column title="Added on" dataIndex="date" key="date" />
          <Column
            title="Action"
            key="action"
            render={data => (
              <Space size="middle">
                <Popover content={() => content(data)} trigger="hover">
                  <div className="table-action-column-wrapper">
                    <BiDotsVerticalRounded
                      style={{ rotate: '90deg' }}
                      size={18}
                      className="cursor-p"
                    />
                  </div>
                </Popover>
              </Space>
            )}
          />
        </Table>
      </div>
      {showDeleteModal && (
        <DeleteModal
          visible={showDeleteModal}
          setVisible={setShowDeleteModal}
          subTitle="You want to delete this credits"
          deleteModule="credits"
          rowData={currentRecord}
        />
      )}
      {showAssignModal && (
        <AddCreditModal
          visible={showAssignModal}
          setVisible={setShowAssignModal}
          rowData={currentRecord}
          doRefresh={() => doRefresh()}
        />
      )}
      {showAssignCreditToAnyExpertModal && (
        <AddCreditToAnyModal
          visible={showAssignCreditToAnyExpertModal}
          setVisible={setShowAssignCreditToAnyExpertModal}
        />
      )}
    </div>
  );
};

export default AdminCreditsManagement;
