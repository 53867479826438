import React, { useEffect } from 'react';
import { Layout, Popover, Avatar } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
  logout,
  getFullName,
  showAvatarName,
  getUserData,
  handleNotification,
  formatNotification,
  truncateString,
} from '../../helpers/functions';
import MainLogo from '../../assets/images/Acc_logo/logo_blue.svg';
import { BellTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
const { Header } = Layout;

const Headers = props => {
  // hide header in print mode
  const { print } = useParams();
  const navigate = useNavigate();
  const notifications = useSelector(state => state?.common?.notifications);
  const newNotificationCount = useSelector(state => state?.common?.newNotificationCount);
  const onLogoutClick = () => {
    logout();
    navigate('/login');
  };
  // function formatNotification(n) {

  //   if (typeof n?.data === 'string') {
  //     return JSON.parse(n?.data)?.message;
  //   } else if (n?.data) {
  //     return JSON.parse(n?.data[0])?.message;
  //   }
  // }
  function getDate(n) {
    const date = n.created_at || JSON.parse(n?.data[0])?.notification?.created_at;
    return date ? new Date(date).toLocaleString() : new Date().toLocaleString();
  }
  const dispatch = useDispatch();
  useEffect(() => {
    if (notifications?.length === 0) {
      dispatch({ type: 'GET_NOTIFICATION' });
    }
  }, []);
  function handleNotificationClick(n) {
    if (newNotificationCount > 0) {
      dispatch({ type: 'REMOVE_NOTIFICATION_COUNT_SUCCESS' });
    }

    if (typeof n?.data === 'string') {
      navigate(
        handleNotification(JSON.parse(n?.data)?.type, n) + (JSON.parse(n?.data)?.redirect_id || ''),
        { state: { from: 'notification' } },
      );
    } else if (n?.data) {
      navigate(
        handleNotification(JSON.parse(n?.data[0])?.type, n) +
          (JSON.parse(n?.data[0])?.redirect_id || ''),
        { state: { from: 'notification' } },
      );
    }
  }
  const notificationContent = (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center  ', width: '100%' }}
    >
      {notifications?.length ? (
        notifications.slice(0, 3).map((n, i) => {
          return (
            <div
              onClick={() => handleNotificationClick(n)}
              key={i}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
                flexDirection: 'column',
                backgroundColor: `${newNotificationCount > 0 && i < newNotificationCount ? 'rgba(255, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.05)'}`,
                marginBottom: '1rem',
                padding: '.5rem',
                borderRadius: '5px',
                width: '100%',
                cursor: 'pointer',
              }}
            >
              <p style={{ fontWeight: 'bold' }}>{formatNotification(n)[0]}</p>
              <p style={{ fontSize: '12px' }}>{truncateString(formatNotification(n)[1])}</p>
              <p style={{ fontSize: '12px' }}>{getDate(n)}</p>
            </div>
          );
        })
      ) : (
        <p>No new notifications</p>
      )}
      <p style={{ cursor: 'pointer', color: 'blue' }} onClick={() => navigate('/notifications')}>
        View more
      </p>
    </div>
  );
  const content = (
    <div>
      <p>{getFullName()}</p>
      <p>
        <span className="header-menu pointer" onClick={() => onLogoutClick()}>
          Logout
        </span>
      </p>
    </div>
  );

  return (
    <>
      {!!print || (
        <Header className="header">
          <div className="d-flex-between">
            <div className="main-logo">
              <img
                src={MainLogo}
                onClick={() => navigate('/')}
                className="pointer w100"
                alt="Accountizer Logo "
                loading="lazy"
              ></img>
            </div>

            <div className="header-menu d-flex-a-center" style={{ position: 'relative' }}>
              <Popover
                trigger={'click'}
                content={notificationContent}
                title="Notifications"
                onClick={() => dispatch({ type: 'READ_NOTIFICATION' })}
              >
                <span className="notification_bell">
                  <BellTwoTone />
                </span>
                {newNotificationCount > 0 && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '15%',
                      right: '60%',
                      zIndex: '1',
                      backgroundColor: 'red',
                      color: 'white',
                      borderRadius: '50%',
                      width: '20px',
                      height: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ fontSize: '12px' }}>{newNotificationCount}</span>
                  </div>
                )}
              </Popover>
              <Popover placement="bottom" content={content} trigger="hover">
                <Avatar size="large" className="pointer">
                  {showAvatarName()}
                </Avatar>
              </Popover>
            </div>
          </div>
        </Header>
      )}
    </>
  );
};

export default Headers;
