import React from 'react';
import { ROLE_NAME } from '../../helpers/constant';
import { isLogin } from '../../helpers/functions';
import { Navigate } from 'react-router-dom';

// import Dashboard from "./admin";
import Dashboard from '../../components/admin/users';
import HomePage from '../../components/public';
import { role } from '../../helpers/functions';

const Template = props => {
  if (isLogin()) {
    if (role() === ROLE_NAME.ADMIN) {
      return <Navigate to="/admin/requests" replace={true} />;
    } else if (role() === ROLE_NAME.EXPERT) {
      return <HomePage />;
      // return <Navigate to="/expert" replace={true} />;
    } else if (role() === ROLE_NAME.CUSTOMER) {
      return <HomePage />;
    }
  } else {
    return <HomePage />;
  }
};
export default Template;
