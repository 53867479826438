import {
  getRequestWithBlob,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  postRequestForMessaging,
} from './apiCall';

//Perform the Get request
export function getRequestAPI({ url = '', params = {} }) {
  return getRequest(url, params);
}

//Perform the Get request
export function getRequestAPIWithBlob({ url = '', params = {} }) {
  return getRequestWithBlob(url, params);
}

export function postRequestAPI({ url = '', data = {} }) {
  return postRequest(url, data);
}

export function postRequestForMessagingAPI({ url = '', data = {} }) {
  return postRequestForMessaging(url, data);
}

export function putRequestAPI({ url = '', data = {} }) {
  return putRequest(url, data);
}

export function deleteRequestAPI({ url = '', data = {} }) {
  return deleteRequest(url, data);
}
