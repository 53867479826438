import RequestIcon from '../../assets/images/requestIcon.png';
import ExpertIcon from '../../assets/images/expertIcon.png';
import ReviewIcon from '../../assets/images/ratingIcon.png';
import CreditIcon from '../../assets/images/creditIcon.png';
import QuestionIcon from '../../assets/images/questionIcon.png';

import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
const { Sider } = Layout;

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState('admin/campaigns');
  const [collapsed, setCollapsed] = useState(false);

  function getItem(label, key, icon) {
    return {
      key,
      icon,
      label,
    };
  }

  const items = [
    getItem(
      'Requests',
      'admin/requests',
      <img loading='lazy' src={RequestIcon} style={{ minWidth: '20px' }} height={20} alt="" />,
    ),
    getItem(
      'Questions',
      'admin/questions',
      <img loading='lazy' src={QuestionIcon} style={{ minWidth: '20px' }} width={20} height={20} alt="" />,
    ),
    getItem(
      'Expert Management',
      'admin/experts',
      <img loading='lazy' src={ExpertIcon} style={{ minWidth: '20px' }} width={20} height={20} alt="" />,
    ),
    // getItem('User Management', 'admin/users', <img src={UsersIcon} style={{ minWidth: '20px' }} width={20} height={20} alt='' />),
    getItem(
      'Credits',
      'admin/credits',
      <img loading='lazy' src={CreditIcon} style={{ minWidth: '20px' }} width={20} height={20} alt="" />,
    ),
    getItem(
      'Reviews',
      'admin/reviews',
      <img loading='lazy' src={ReviewIcon} style={{ minWidth: '20px' }} width={20} height={20} alt="" />,
    ),
    getItem(
      'Blogs',
      'admin/blogs',
      <img loading='lazy' src={ReviewIcon} style={{ minWidth: '20px' }} width={20} height={20} alt="" />,
    ),
  ];

  useEffect(() => {
    if (location.pathname.includes('admin/requests')) {
      setActive('admin/requests');
    } else if (location.pathname.includes('admin/analytics')) {
      setActive('admin/analytics');
    } else if (location.pathname.includes('admin/reviews')) {
      setActive('admin/reviews');
    } else if (location.pathname.includes('admin/questions')) {
      setActive('admin/questions');
    } else if (location.pathname.includes('admin/experts')) {
      setActive('admin/experts');
    } else if (location.pathname.includes('admin/disputes')) {
      setActive('admin/disputes');
    } else if (location.pathname.includes('admin/credits')) {
      setActive('admin/credits');
    } else if (location.pathname.includes('admin/coupons')) {
      setActive('admin/coupons');
    } else if (location.pathname.includes('admin/users')) {
      setActive('admin/users');
    } else if (location.pathname.includes('admin/blogs')) {
      setActive('admin/blogs');
    } else {
      setActive('');
    }
  }, [location]);

  return (
    <Sider
      width={230}
      breakpoint="sm"
      onBreakpoint={broken => {}}
      collapsible
      collapsed={collapsed}
      onCollapse={value => setCollapsed(value)}
      className="site-layout-background"
    >
      <Menu
        mode="inline"
        selectedKeys={[active]}
        onClick={e => {
          setActive(e.key);
          navigate(`/${e.key}`);
        }}
        inlineCollapsed={collapsed}
        defaultOpenKeys={['sub1']}
        items={items}
        style={{
          height: '100%',
          borderRight: 0,
        }}
      />
    </Sider>
  );
};

export default Sidebar;
