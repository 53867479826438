/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Form, Button, Spin, Radio, Space, Select } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserData } from '../../helpers/functions';
import QuestionSuccessModal from '../common/modal/questionSuccess';
import { IoMdArrowRoundBack } from 'react-icons/io';
import QuestionAutomationSuccessModal from '../common/modal/questionAutomationSuccessModal';
import QuestionAutomationRejectModal from '../common/modal/questionAutomationRejectModal';

const Step7 = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const { qid } = useParams();
  const [form] = Form.useForm();
  const { isSLLoading, isQuestionLoading, questionDetail } = useSelector(state => state.common);
  const { isStepUpdating, stepUpdateMsg, questionAssigned, isQuestionUpdating, questionUpdateMsg } =
    useSelector(state => state.steps);
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [visibleSuccessModel, setVisibleSuccessModel] = useState(false);
  const [visibleRejectModel, setVisibleRejectModel] = useState(false);

  const options = [
    { value: 1, label: 'Fully automated AI-explained algorithmic matching process.' },
    { value: 0, label: 'Human-supervised semi-automated matching process.' },
  ];

  useEffect(() => {
    if (qid) {
      dispatch({ type: 'GET_QUESTION_DETAIL', id: qid });
    }
  }, []);

  const onChange = e => {
    setSelectedType(e.target.value);
  };

  const onFinish = values => {
    if (!qid) return;

    if (!selectedType) {
      setVisibleModal(true);
      return;
    }

    dispatch({
      type: 'QUESTION_STEP_UPDATE_REQUEST',
      formData: {
        ...values,
        user_id: getUserData().id,
        id: parseInt(qid),
      },
      requestType: 'post',
      step: 7,
    });
  };

  useEffect(() => {
    if (!isStepUpdating && stepUpdateMsg === 'success') {
      dispatch({ type: 'CLEAR_STEPS' });
    }
  }, [isStepUpdating, stepUpdateMsg]);

  useEffect(() => {
    if (!isStepUpdating && stepUpdateMsg === 'success') {
      if (questionAssigned) setVisibleSuccessModel(true);
      else setVisibleRejectModel(true);
    }
  }, [isStepUpdating, stepUpdateMsg, questionAssigned]);

  return (
    <div>
      <div className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={false}>
          {isSLLoading && <Spin />}
          {!isSLLoading && (
            <>
              <p className="m-title tl mb-7 d-flex-center">
                <span className="back-icon" onClick={() => handleBack(qid, 'location')}>
                  <IoMdArrowRoundBack />
                </span>
                <span>I prefer to hire professionals through</span>
              </p>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                form={form}
                onFinish={onFinish}
              >
                <Form.Item
                  name="matching_automation "
                  rules={[{ required: true, message: 'Please select at least one option' }]}
                >
                  <Radio.Group className={'mt3'} style={{ width: '100%' }} onChange={onChange}>
                    <Space direction="vertical" size={'middle'} style={{ width: '100%' }}>
                      {options.map(item => {
                        return (
                          <Radio style={{ fontSize: '18px' }} key={item.value} value={item.value}>
                            {item.label}
                          </Radio>
                        );
                      })}
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    className="mt5"
                    disabled={isStepUpdating}
                    loading={isStepUpdating}
                  >
                    Next
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </Card>
      </div>
      <QuestionSuccessModal
        visible={visibleModal}
        setVisible={setVisibleModal}
        navigateTo="/dashboard/customer"
      />
      {questionDetail ? (
        <QuestionAutomationSuccessModal
          visible={visibleSuccessModel}
          setVisible={setVisibleSuccessModel}
          navigateTo={`/my-request/${questionDetail.id}`}
        />
      ) : null}
      <QuestionAutomationRejectModal
        visible={visibleRejectModel}
        setVisible={setVisibleRejectModel}
        navigateTo="/dashboard/customer"
      />
    </div>
  );
};
export default Step7;
