import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import React from 'react';
import { isLogin } from '../../helpers/functions';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import useResponsive from '../../hooks/useBreakpoint';

const MobileHeader = ({ showMenu, setShowMenu, menuItems, onClick, publicheader }) => {
  const navigate = useNavigate();
  const breakpoint = useResponsive();
  return (
    <>
      {showMenu ? (
        <div
          style={{
            zIndex: 9999999,
            height: '100vh',
            width: '100vw',
            position: 'absolute',

            top: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <div
            style={{ height: '100%', width: '50%', backgroundColor: 'transparent' }}
            onClick={() => setShowMenu(!showMenu)}
          ></div>
          <div
            style={{
              height: '100%',
              width: '50%',
              position: 'fixed',
              top: 0,
              right: 0,
              backgroundColor: 'white',
              boxShadow: '-10px 0 15px rgba(0, 0, 0, 0.3)',
            }}
            onClick={() => setShowMenu(!showMenu)}
          >
            {!isLogin() && (breakpoint === 'xs' || breakpoint === 'sm') &&(
              <>
                <div style={{backgroundColor: '#E9F9FE'}} className="mobile-menu-item">
                  <p
                    style={{ cursor: 'pointer' }}
                    className="mb0 mr15"
                    onClick={() => navigate(`/login?type=${publicheader ? 'customer' : 'expert'}`)}
                  >
                    {publicheader ? "Client Login" : "Expert Login"}
                  </p>
                </div>
                <div style={{backgroundColor: '#E9F9FE'}} className="mobile-menu-item">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="float-r"
                    size="middle"
                    onClick={() => navigate(`${publicheader ? '/expert' : '/'}`)}
                  >
                    {publicheader ? "Become an Expert" : "Client Area"}
                  </Button>
                </div>
              </>
            )}
            {menuItems.map((item, idx) => {
              return (
                <div className="mobile-menu-item">
                  <span
                    onClick={e => {
                      setShowMenu(!showMenu);
                      onClick(item);
                    }}
                  >
                    {item.label}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <MenuOutlined onClick={() => setShowMenu(!showMenu)} />
      )}
    </>
  );
};

export default MobileHeader;
