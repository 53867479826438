/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Modal, Form, Input, Spin, Select, DatePicker, Button } from 'antd';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeForm from './stripeForm';
import { STRIPE_PUBLIC_KEY } from '../../../helpers/constant';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const { TextArea } = Input;

const PaymentModal = props => {
  const { open, close, quotationId, quotationDetail } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    let tmpObj = {
      quotation_id: quotationId,
      action: 'review',
    };
    dispatch({ type: 'PAYMENT_TAX_REQUEST', formData: tmpObj });
  }, []);

  const applyCouple = couponId => {
    let tmpObj = {
      quotation_id: quotationId,
      action: 'review',
      coupon_id: couponId,
    };
    dispatch({ type: 'PAYMENT_TAX_REQUEST', formData: tmpObj });
  };

  return (
    <Modal
      title={'Quotation Details'}
      className="payment-modal quotation-modal"
      open={open}
      // onOk={onFinish}
      // header={false}
      footer={false}
      onCancel={() => close(false)}
      okText={'Accept & send quotation'}
      okButtonProps={{
        disabled: false,
        loading: false,
      }}
      width={650}
    >
      <Elements stripe={stripePromise}>
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <StripeForm
              quotationId={quotationId}
              onCancel={() => close(false)}
              stripe={stripe}
              elements={elements}
              quotationDetail={quotationDetail}
              applyCouple={e => applyCouple(e)}
            />
          )}
        </ElementsConsumer>
      </Elements>
    </Modal>
  );
};

export default PaymentModal;
