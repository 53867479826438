import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import searchIcon from '../../assets/images/searchIcon.svg';
import { MessageGroupCard } from './messageGroupCard';
import { MessagingContainerForMessageGroup } from './messagingContanerForMessageGroup';
import { breakName, getUserData } from '../../helpers/functions';
import useResponsive from '../../hooks/useBreakpoint';
import { ArrowLeftOutlined, MenuOutlined } from '@ant-design/icons';
export const MessagesGroupView = () => {
  const [search, setSearch] = useState({ find: '' });
  const [messageThreadLocalData, setMessageThreadLocalData] = useState([]);
  const [selectedThread, setSelectedThread] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  const { messageThreadData, unreadMessages, selectedTabGroup } = useSelector(
    state => state.requests,
  );
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  useEffect(() => {
    dispatch({ type: 'GET_MESSAGE_THREAD' });
    dispatch({ type: 'GET_ALL_UNREAD_MESSAGES' });
  }, []);

  useEffect(() => {
    if (messageThreadData.length) {
      setMessageThreadLocalData(messageThreadData);
      // dispatch({ type: 'GET_MESSAGE_THREAD' });
      // dispatch({ type: 'GET_ALL_UNREAD_MESSAGES' });
      // const timer = setTimeout(() => {
      // }, 3000);
      // return () => clearTimeout(timer);
    }
  }, [dispatch, messageThreadData]);

  useEffect(() => {
    if (search.find.length) {
      messageThreadLocalData?.map((data, idx, self) => {
        data?.participants
          ?.filter((el, id, selfNew) => el?.id !== getUserData().id)
          .filter((ele, id) => {
            if (
              ele &&
              `${ele.first_name} ${ele.last_name}`
                .toLowerCase()
                .includes(search?.find?.toLowerCase())
            ) {
              setFilteredData([data]);
            }
          });
      });
    } else {
      setFilteredData(messageThreadLocalData);
    }
  }, [search, messageThreadLocalData, filteredData, messageThreadData.length]);

  const handleSearchByName = e => {
    setSearch({ ...search, find: e.target.value });
  };
  const breakPoint = useResponsive();
  const [showMessageBox, setShowMessageBox] = useState(
    breakPoint == 'xxl' || breakPoint == 'xl' || breakPoint == 'lg',
  );
 
  const [currUser, setCurrUser] = useState(null);
  return (
    <div style={{ border: '0.2px solid #d1d5db' }} className="message-group-view-body">
      {(breakPoint == 'xxl' || breakPoint == 'xl' || breakPoint == 'lg' || !showMessageBox) && (
        <div className="message-group-left-container">
          <div className="message-group-search-container">
            <Input
              className="message-group-search"
              prefix={
                <img style={{ marginRight: '5px' }} src={searchIcon} alt="Search" loading="lazy" />
              }
              placeholder="Search by name"
              size="large"
              onChange={e => handleSearchByName(e)}
            />
          </div>
          <div
            style={{
              height: (height * 55) / 100,
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
            }}
            className="message-group-left-container-card-group"
          >
            {filteredData?.map((data, idx) => {
              return (
                <div
                  key={idx}
                  onClick={() => {
                    !(breakPoint == 'xxl' || breakPoint == 'xl' || breakPoint == 'lg') &&
                      setShowMessageBox(true);
                      setCurrUser(data?.participants?.find(el => el?.id !== getUserData().id));
                  }}
                >
                  <MessageGroupCard
                    data={data}
                    unreadMessages={unreadMessages?.find(el => {
                      if (el?.thread[0]?.thread_id === data?.messages[0]?.thread_id) {
                        return el;
                      }
                      return null;
                    })}
                    selectedThread={selectedThread}
                    setSelectedThread={setSelectedThread}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}

      {(showMessageBox || breakPoint == 'xxl' || breakPoint == 'xl' || breakPoint == 'lg') && (
        <div
          style={{ backgroundColor: 'white', position: 'relative' }}
          className="message-group-right-container"
        >
          {!(breakPoint == 'xxl' || breakPoint == 'xl' || breakPoint == 'lg') && (
            <div
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                cursor: 'pointer',
                backgroundColor: 'gray',
                color: 'white',
                padding: '15px',
                width: '100%',
                zIndex: '100',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div onClick={() => setShowMessageBox(!showMessageBox)}>
                <MenuOutlined/>
              </div>
              <span>{breakName(currUser?.first_name, currUser?.last_name)}</span>
            </div>
          )}

          <MessagingContainerForMessageGroup
            subjectId={parseInt(selectedThread?.Subject)}
            selectedTabGroup={selectedTabGroup}
            selectedThreadData={messageThreadData?.filter(data => data?.id === selectedTabGroup)}
          />
        </div>
      )}
    </div>
  );
};
