import React, { useEffect, useState } from 'react';
import Helmet from '../../common/helmet';
import { Row, Card, Col, Input, Tabs, Table, Space, Popover, Tooltip, Modal, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import DeleteModal from '../../common/modal/deleteModal';
import expertIcon from '../../../assets/images/profile_placeholder.png';
import { useNavigate } from 'react-router-dom';

const { Search } = Input;
const { Column } = Table;

let filterParams = { search: '' };

const tabItems = [
  {
    key: '1',
    name: 'experts',
    label: `Experts`,
  },
  {
    key: '2',
    name: 'new_applicants',
    label: `New Applicants`,
  },
  {
    key: '3',
    name: 'pending_updates',
    label: `Pending Updates`,
  },
];

const AdminExpertManagement = props => {
  const dispatch = useDispatch();

  const { getUserCountFromApi } = useSelector(state => state.requests);
  const {
    isExpertListLoading,
    expertsList,
    isExpertNewApplicationLoading,
    expertNewApplication,
    isUpdateExpertStatusLoading,
    isUpdateExpertFreezingStatusLoading,
    isExpertPendingUpdatesLoading,
    expertPendingUpdates,
  } = useSelector(state => state.expert);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState({ find: '' });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showFreezeModal, setShowFreezeModal] = useState(false);
  const [showRejectExpertModal, setShowRejectExpertModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [selectedTab, setSelectedTab] = useState('1');
  const { isDeleteUserLoading } = useSelector(state => state.admin);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: 'GET_USER_COUNT' });
  }, []);

  useEffect(() => {
    if (selectedTab === '1') {
      dispatch({ type: 'EXPERT_LIST_REQUEST', formData: {} });
    } else if (selectedTab === '2') {
      dispatch({ type: 'EXPERT_NEW_APPLICATION' });
    } else if (selectedTab === '3') {
      dispatch({ type: 'EXPERT_PENDING_UPDATES' });
    }
  }, [dispatch, isDeleteUserLoading, selectedTab, isUpdateExpertStatusLoading]);

  useEffect(() => {
    const mapExpertData = item => ({
      ...item,
      location: item?.locations ? item?.locations : [],
      expertName: `${item?.first_name} ${item?.last_name}`,
      expertise: item?.category ? item?.category : [],
      resume: item?.upload_resume,
    });
    if (selectedTab === '1' && !isExpertListLoading && expertsList?.length > 0) {
      let tmpArray = expertsList?.map(mapExpertData);
      setTableData(tmpArray);
    } else if (
      selectedTab === '2' &&
      !isExpertNewApplicationLoading &&
      expertNewApplication?.length > 0
    ) {
      let tmpArray = expertNewApplication
        ?.filter(data => data?.is_authorized === 1)
        .map(mapExpertData);
      setTableData(tmpArray);
    } else if (
      selectedTab === '3' &&
      !isExpertPendingUpdatesLoading &&
      expertPendingUpdates?.length > 0
    ) {
      let tmpArray = expertPendingUpdates?.map(mapExpertData);
      setTableData(tmpArray);
    } else {
      setTableData([]);
    }
  }, [
    isExpertListLoading,
    isDeleteUserLoading,
    selectedTab,
    expertsList,
    isExpertNewApplicationLoading,
    expertNewApplication,
    isUpdateExpertStatusLoading,
    isExpertPendingUpdatesLoading,
    expertPendingUpdates,
  ]);

  useEffect(() => {
    const filteredData = tableData?.filter(el =>
      `${el?.first_name} ${el?.last_name}`.toLowerCase().includes(search?.find?.toLowerCase()),
    );
    setFilteredTableData(filteredData);
    if (!search.find.length) {
      setFilteredTableData(tableData);
    }
  }, [search, tableData, filteredTableData]);

  const getAllRequests = params => {
    dispatch({ type: 'EXPERT_LIST_REQUEST', formData: {} });
  };

  const doRefresh = () => {
    getAllRequests();
  };

  const handleTabChange = e => {
    setSelectedTab(e);
  };

  const handleApproveExpert = (expert, approveOn) => {
    let update = {};
    if (approveOn === 'create') {
      update = {
        is_approve: 1,
      };
    } else if (approveOn === 'update') {
      update = {
        has_pending_update: 0,
      };
    }

    dispatch({
      type: 'UPDATE_EXPERT_STATUS',
      formData: {
        expert_id: expert.id,
        ...update,
      },
    });
  };

  const handleSearch = e => {
    setSearch({ ...search, find: e.target.value });
  };

  const handleFreeze = () => {
    const isFrozen = currentRecord.is_frozen;
    dispatch({
      type: 'UPDATE_EXPERT_FREEZING_STATUS',
      expert_id: currentRecord.id,
      handleRefresh: () => {
        setShowFreezeModal(false);
        message.success(`Expert is ${isFrozen ? 'Unfrozen' : 'frozen'} successfully`);
        doRefresh();
      },
      formData: {
        is_frozen: !isFrozen,
      },
    });
  };

  const content = data => {
    setCurrentRecord(data);
    if (selectedTab === '1') {
      return (
        <>
          <div>
            <div className="action-popover" onClick={() => setShowDeleteModal(true)}>
              Delete expert
            </div>
          </div>
          <div>
            <div
              className="action-popover"
              style={{ color: 'black' }}
              onClick={() => setShowFreezeModal(true)}
            >
              {data.is_frozen ? 'Unfreeze' : 'Freeze'} Expert
            </div>
          </div>
        </>
      );
    } else if (selectedTab === '2') {
      return (
        <div>
          <div
            className="action-popover"
            style={{ color: 'black' }}
            onClick={() => handleApproveExpert(data, 'create')}
          >
            Approve
          </div>
          <div className="action-popover" onClick={() => setShowRejectExpertModal(true)}>
            Reject
          </div>
        </div>
      );
    } else if (selectedTab === '3') {
      return (
        <div>
          <div
            className="action-popover"
            style={{ color: 'black' }}
            onClick={() => handleApproveExpert(data, 'update')}
          >
            Approve
          </div>
        </div>
      );
    }
  };

  return (
    <div className="admin-container">
      <Helmet title="Requests" />
      <p className="title">Experts</p>
      <Row gutter={30} className="page-card-header">
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Card className="top-card">
            <div className="parent">
              <div className="mb0">
                <p className="mb-2 card-title">Total Experts</p>
                <p className="card-value">
                  {getUserCountFromApi?.allUserCount?.expert
                    ? getUserCountFromApi?.allUserCount?.expert
                    : 0}
                </p>
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Card className="top-card">
            <div className="parent">
              <div className="mb0">
                <p className="mb-2 card-title">New Experts</p>
                <p className="card-value">
                  {getUserCountFromApi?.allUserCount?.['new-expert']
                    ? getUserCountFromApi?.allUserCount?.['new-expert']
                    : 0}
                </p>
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Card className="top-card">
            <div className="parent">
              <div className="mb0">
                <p className="mb-2 card-title">New Applicants</p>
                <p className="card-value">100</p>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <div>
        <Row className="nav-sub-header mt-10">
          <Col xs={24} sm={24} md={18} lg={14} xl={12}>
            <Search
              defaultValue={filterParams['search']}
              size="large"
              allowClear
              placeholder="Search by expert name."
              style={{ width: '100%' }}
              enterButton
              onChange={handleSearch}
            />
          </Col>
        </Row>
      </div>
      <div className="mt15">
        <Tabs
          defaultActiveKey="1"
          items={tabItems}
          animated={true}
          defaultValue={selectedTab}
          onChange={e => handleTabChange(e)}
        />
        <Table
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
          }}
          size="middle"
          loading={selectedTab === '1' ? isExpertListLoading : isExpertNewApplicationLoading}
          dataSource={filteredTableData}
          rowClassName={data => (data.is_frozen ? 'frozen-expert-row' : '')}
        >
          <Column
            title="ID"
            dataIndex="id"
            key="id"
            sorter={(a, b) => a.id - b.id}
            defaultSortOrder="descend"
            showSorterTooltip={false}
          />

          {selectedTab === '1' ? (
            <Column
              title="Expert Name"
              dataIndex="expertName"
              key="expertName"
              render={(data, record) => {
                return (
                  <div style={{ display: 'inline-flex', alignItems: 'center', gap: '10px' }}>
                    <img
                      style={{ height: '40px', width: '40px', borderRadius: '20px' }}
                      src={record?.avatar ? record?.avatar : expertIcon}
                      alt="Profile Pic."
                    />
                    <span>{data}</span>
                  </div>
                );
              }}
            />
          ) : (
            <Column title="Applicant Name" dataIndex="expertName" key="expertName" />
          )}

          <Column
            title="Email"
            dataIndex="email"
            key="email"
            render={data => {
              return (
                <div>
                  {data.length > 30 ? (
                    <Tooltip title={data}>{data.slice(0, 30) + '...'}</Tooltip>
                  ) : (
                    data
                  )}
                </div>
              );
            }}
          />

          <Column
            title="Location"
            dataIndex="location"
            key="location"
            width={'150px'}
            render={data =>
              data?.length ? (
                data?.map((el, idx) => {
                  return (
                    idx < 4 && (
                      <span id={idx}>
                        {data.length === idx + 1
                          ? el.name
                          : `${el.name}${idx !== 3 ? ',' : '...'} `}
                      </span>
                    )
                  );
                })
              ) : (
                <div>-</div>
              )
            }
          />

          <Column
            title="Expertise"
            dataIndex="expertise"
            key="expertise"
            width={'200px'}
            render={data =>
              data?.length ? (
                data?.map((el, idx) => {
                  return (
                    idx < 3 && (
                      <span id={idx}>
                        {data.length === idx + 1
                          ? el.name
                          : `${el.name}${idx !== 2 ? ',' : '...'} `}
                      </span>
                    )
                  );
                })
              ) : (
                <div>-</div>
              )
            }
          />

          {selectedTab === '2' && (
            <Column
              title="Resume"
              dataIndex="resume"
              key="resume"
              render={value => {
                return value ? <a href={value}>View</a> : <div>NA</div>;
              }}
            />
          )}

          <Column
            title="Profile"
            align="center"
            dataIndex="id"
            key="id"
            render={data => {
              return (
                <div
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={() => navigate(`/application-detail/${data}`)}
                >
                  View
                </div>
              );
            }}
          />

          <Column
            title="Action"
            key="action"
            render={data => (
              <Space size="middle">
                <Popover content={() => content(data)} trigger="hover">
                  <div className="table-action-column-wrapper">
                    <BiDotsVerticalRounded
                      style={{ rotate: '90deg' }}
                      size={18}
                      className="cursor-p"
                    />
                  </div>
                </Popover>
              </Space>
            )}
          />
        </Table>
      </div>

      <DeleteModal
        visible={showDeleteModal}
        setVisible={setShowDeleteModal}
        h-title
        mb10
        subTitle="You want to delete this expert?"
        deleteModule="expert"
        rowData={currentRecord}
        doRefresh={() => doRefresh()}
      />

      <DeleteModal
        visible={showRejectExpertModal}
        setVisible={setShowRejectExpertModal}
        subTitle="You want to reject this application?"
        deleteModule="expert_reject"
        rowData={currentRecord}
        doRefresh={() => doRefresh()}
        okText="Reject"
      />

      {/* Freeze Modal */}
      <Modal
        title={'Are you sure?'}
        open={showFreezeModal}
        onOk={handleFreeze}
        centered
        onCancel={() => setShowFreezeModal(false)}
        okText={currentRecord.is_frozen ? 'Unfreeze' : 'Freeze'}
        okButtonProps={{
          disabled: isUpdateExpertFreezingStatusLoading,
          loading: isUpdateExpertFreezingStatusLoading,
        }}
        cancelText={'Cancel'}
      >
        <p>You want to {currentRecord.is_frozen ? 'unfreeze' : 'freeze'} this expert?</p>
      </Modal>
    </div>
  );
};

export default AdminExpertManagement;
