import { Button, Modal, Rate, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckIcon from '../../../assets/images/CheckCircleIcon.svg';
import expertIcon from '../../../assets/images/profile_placeholder.png';

export const ReviewModal = props => {
  const { visible, setShowModal, expertId, questionId, questionStatus, rawData } = props;
  const { expertDetailById, isExpertDetailLoading } = useSelector(state => state.requests);
  const [reviewText, setReviewText] = useState('');
  const [reviewInStar, setReviewInStar] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (visible && expertId) {
      dispatch({ type: 'GET_EXPERT_DETAIL_BY_ID', id: expertId });
    }
  }, [expertId, visible]);

  const postReview = () => {
    if (reviewText && reviewInStar !== 0 && expertId && questionId && questionStatus) {
      dispatch({
        type: 'ADD_EXPERT_REVIEW',
        formData: {
          expert_id: expertId,
          question_id: questionId,
          review: reviewText,
          rating: reviewInStar,
          status: 'active',
        },
      });
      setShowModal(false);
    }
  };

  return (
    <Modal closable={false} centered footer={null} open={visible} width="600px">
      {isExpertDetailLoading ? (
        <div className="d-flex-a-c">
          <Spin />
        </div>
      ) : (
        <div className="review-modal-container">
          <div className="modal-title-wrapper">
            <img src={CheckIcon} alt="icon" loading='lazy' height={80} width={80} />
            <div className="modal-title-text">Request #{rawData?.id} id marked as completed</div>
          </div>
          <div className="modal-body-wrapper">
            asking for customer's experience and to leave review
          </div>
          <div className="modal-profile-wrapper">
            <div style={{ height: '80px', width: '80px' }} className="assigned-to-profile-icon">
              <img
                alt="expert"
                height={80}
                width={80}
                src={expertDetailById?.avatar ? expertDetailById?.avatar : expertIcon}
                loading='lazy'
              />
            </div>
            <div className="assigned-to-profile-detail">
              <div className="assigned-to-profile-name">
                {expertDetailById?.first_name} {expertDetailById?.last_name}
              </div>
              <div className="assigned-to-profile-role">{rawData?.category?.name}</div>
            </div>
          </div>
          <div className="modal-rating-wrapper">
            <Rate
              value={reviewInStar}
              onChange={star => setReviewInStar(star)}
              className="modal-rating"
            />
          </div>
          <div className="modal-text-area-wrapper">
            <div>
              Describe your experience with {expertDetailById?.first_name}{' '}
              {expertDetailById?.last_name}.
            </div>
            <TextArea
              value={reviewText}
              onChange={e => setReviewText(e.target.value)}
              autoSize={{ minRows: 7, maxRows: 7 }}
            />
          </div>
          <div className="modal-footer-button-area">
            <Button size="large" onClick={() => setShowModal(false)}>
              Later
            </Button>
            <Button size="large" type="primary" onClick={postReview}>
              Submit
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
