import { Button, Col, Divider, Rate, Row, Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import img1 from '../../assets/images/expert/img1.svg';
import img2 from '../../assets/images/expert/img2.svg';
import img3 from '../../assets/images/expert/img3.svg';
import img4 from '../../assets/images/expert/img4.svg';
import starLogo from '../../assets/images/star.svg';
import { breakName, convertDateToTimezone, formatDateTo, isLogin, role, getUserData } from '../../helpers/functions';
import expertIcon from '../../assets/images/profile_placeholder.png';
import { useNavigate } from 'react-router-dom';
import { ROLE_NAME, OTHER_SUB_CATEGORY_IDS, nameRecommendationConstantForToolTip, BASE_URL } from '../../helpers/constant';
import { FaEye } from 'react-icons/fa';
import useResponsive from '../../hooks/useBreakpoint.jsx';
// import "./FindExpert.css";
const desc = ['4.9'];
export const FindAnExpertDetail = () => {
  const dispatch = useDispatch();
  const { qid } = useParams();
  const navigate = useNavigate();
  const [expandJobsList, setExpendJobsList] = useState(false);
  const [value, setValue] = useState(1);
  const {
    expertDetailById,
    reviewsByUserId,
    expertServiceAndRateDetailById,
    isExpertDetailLoading,
    expertLicensesDetailById,
    isExpertLicensesDetailLoading,
    expertExperienceDetailById,
    expertDegreesDetailById,
    isExpertDegreesDetailLoading,
  } = useSelector(state => state.requests);
  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  useEffect(() => {
    if (qid) {
      dispatch({ type: 'GET_EXPERT_DETAIL_BY_ID', id: qid });
      dispatch({ type: 'GET_REVIEWS_BY_ID', id: qid });
      dispatch({ type: 'GET_EXPERT_SERVICE_AND_RATE_DETAIL_BY_ID', formData: { expert_id: qid } });
      dispatch({ type: 'GET_EXPERT_EXPERIENCE_DETAIL_BY_ID', formData: { expert_id: qid } });
      dispatch({ type: 'GET_EXPERT_LICENSES_DETAIL_BY_ID', formData: { expert_id: qid } });
      dispatch({ type: 'GET_EXPERT_DEGREES_DETAIL_BY_ID', formData: { expert_id: qid } });
    }
  }, []);
  const navigationButtons = () => {
    if (role() === ROLE_NAME.EXPERT && getUserData()?.id != qid) return;

    if (role() === ROLE_NAME.EXPERT) {
      return (
        <Button
          className="d-flex-a-c"
          icon={<FaEye />}
          type="primary"
          disabled={getUserData()?.id != qid}
          block
          
          onClick={() => {
            if (isLogin()) {
              navigate(`/dashboard/expert/detail`);
            } else {
              localStorage.setItem(
                'redirectLink',
                `${window.location.origin}/dashboard/expert/detail`,
              );
              navigate('/login');
            }
          }}
        >
          View as Expert
        </Button>
        // you cannot view other expert profile
      );
    }
    return (
      <Button
        className="d-flex-a-c"
        type="primary"
        block
        onClick={() => {
          if (isLogin()) {
            navigate(`/request?expertId=${qid}`);
          } else {
            localStorage.setItem(
              'redirectLink',
              `${window.location.origin}/request?expertId=${qid}`,
            );
            navigate('/login');
          }
        }}
      >
        Contact Expert
      </Button>
    );
  };

  const renderExpertLicenses = () => {
    if (
      isExpertLicensesDetailLoading ||
      isExpertDegreesDetailLoading ||
      (!expertLicensesDetailById?.data?.length && !expertDegreesDetailById?.data?.length)
    ) {
      return <p>-</p>;
    }

    const allNames = expertLicensesDetailById?.data
      ?.map(({ designation }) => designation)
      .concat(expertDegreesDetailById?.data?.map(({ name }) => name));

    return (
      <p>
        {allNames.map((name, i) => {
          return allNames.length - 1 === i ? (
            <span key={i}>{name}</span>
          ) : (
            <span key={i}>{name}, </span>
          );
        })}
      </p>
    );
  };
  const breakpoint = useResponsive();
  return (
    <div className="FindExpert-body ac-bg-light" >
      {isExpertDetailLoading ? (
        <div
          style={{ width: (width * 80) / 100, height: (height * 50) / 100 }}
          className="d-flex-a-c"
        >
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 16]} className="profile" id="profile">

            <Col xs={24}  md={6}  className="profile-img" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
             
              <img src={expertDetailById?.avatar ? expertDetailById?.avatar : expertIcon} alt="expert" loading='lazy' />
              <div style={{width: (breakpoint === 'xs' || breakpoint === 'sm' ) ? '50%' : '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              {navigationButtons()}
              </div>
             
            </Col>
            <Col xs={24} md={16}  className="profile-details">
              <div className="profile-name">
                <div>
                  <div className="section-title">
                    <Tooltip title={nameRecommendationConstantForToolTip?.title || ""} color={nameRecommendationConstantForToolTip?.color}>
                      {breakName(expertDetailById?.first_name, expertDetailById?.last_name)}
                    </Tooltip>
                  </div>
                  {expertServiceAndRateDetailById?.data?.length ? (
                    <p className="profile-main-category">
                      {expertServiceAndRateDetailById?.data
                        ?.sort((a, b) => a.main_category?.name.localeCompare(b.main_category?.name))
                        .filter(
                          (value, index, self) =>
                            index ===
                            self.findIndex(
                              prev =>
                                prev.main_category?.name === value.main_category?.name &&
                                prev.main_category?.name === value.main_category?.name,
                            ),
                        )
                        .map((el, i, self) => {
                          return self?.length - 1 === i ? (
                            <span>{el?.main_category?.name}</span>
                          ) : (
                            <span>{el?.main_category?.name}, </span>
                          );
                        })}
                    </p>
                  ) : null}
                </div>
                {reviewsByUserId?.reviews?.data?.length > 0 && (
                  <div className="profile-expert-rating-container">
                    <img src={starLogo} alt="reviews" loading='lazy' />
                    <div>{reviewsByUserId?.rating ? reviewsByUserId?.rating : 0}</div>
                  </div>
                  
                )}
              </div>
              <Divider />
              <div className="profile-address">
                <div>
                  <img src={img1} alt="expert" loading='lazy' />
                  {renderExpertLicenses()}
                </div>
                <div>
                  <img src={img2} alt="expert" loading='lazy' />
                  {expertExperienceDetailById?.data?.length ? (
                    <p>
                      {expertExperienceDetailById?.data
                        ?.filter(
                          (value, index, self) =>
                            index ===
                            self?.findIndex(
                              prev =>
                                prev.industry?.name === value.industry?.name &&
                                prev.industry?.name === value.industry?.name,
                            ),
                        )
                        .map((el, i, self) => {
                          const subCategories =
                            el.industry_sub_categories?.map(subEl => subEl?.name) || [];
                          const displaySubCategory = subCategories[0];
                          const subCategoryCount = subCategories.length - 1;
                          const subCategoryText =
                            subCategories.length > 0
                              ? ` (${displaySubCategory}${subCategoryCount > 0 ? `, +${subCategoryCount}` : ''})`
                              : '';

                          return (
                            <>
                              {el.industry.name}
                              <Tooltip title={subCategories.join(', ')} key={i}>
                                <span>
                                  {subCategoryText}
                                  {i < self.length - 1 && ', '}
                                </span>
                              </Tooltip>
                            </>
                          );
                        })}
                    </p>
                  ) : (
                    <p>-</p>
                  )}
                </div>
                <div>
                  <img src={img3} alt="expert" loading='lazy' />
                  {expertDetailById?.languages?.length ? (
                    <p>
                      {expertDetailById?.languages?.map((el, i) => {
                        return expertDetailById?.languages?.length - 1 === i ? (
                          <span key={i}>{el.name}</span>
                        ) : (
                          <span key={i}>{el.name}, </span>
                        );
                      })}
                    </p>
                  ) : (
                    <p>-</p>
                  )}
                </div>
                {expertDetailById?.locations?.length > 0 && (
                  <div>
                    <img src={img4} alt="expert" loading='lazy' />

                    <p>
                      {expertDetailById?.locations?.map((el, i) => {
                        return expertDetailById?.locations?.length - 1 === i ? (
                          <span key={i}>{el.name}</span>
                        ) : (
                          <span key={i}>{el.name}, </span>
                        );
                      })}
                    </p>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <section className="biography" id="Biography">
            <h2 className="section-title">Expert’s Biography</h2>
            <p className="biography-p">
              {expertDetailById?.bio ? expertDetailById?.bio : 'No Biography'}
            </p>

          </section>

          <section className="jobs" id="jobs">
            <h2 className="section-title">Jobs and Fees</h2>
            <div className="jobs-details">
              {expertServiceAndRateDetailById && expertServiceAndRateDetailById?.data?.length ? (
                expertServiceAndRateDetailById?.data?.map((data, idx) => {
                  if (expandJobsList) {
                    return (
                      <div className="jobs-details-card" id={idx}>
                        <img className="jobs-details-card-img" src={
                          (data?.main_category?.name) ? BASE_URL + "" + data?.main_category?.img_url : img2
                        } alt="job" loading='lazy' />
                        <h6 className="jobs-details-card-heading">{data?.main_category?.name}</h6>
                        <p className="jobs-details-card-paragraph">
                          {OTHER_SUB_CATEGORY_IDS.includes(data?.sub_category?.id)
                            ? data?.other_sub_category_name
                            : data?.sub_category?.name}
                        </p>
                        <p className="jobs-details-card-paragraph">
                          From CA${data?.rate_from}/{data?.rate_type?.name == "Monthly" ? 'mo' : 'hr'} to
                          CA${data?.rate_to}/{data?.rate_type?.name == "Monthly" ? 'mo' : 'hr'}
                        </p>
                      </div>
                    );
                  } else {
                    if (idx < 3) {
                      return (
                        <div className="jobs-details-card" id={idx}>

                          <img className="jobs-details-card-img" src={
                            (data?.main_category?.name) ? BASE_URL + "" + data?.main_category?.img_url : img2
                          } alt="job" loading='lazy' />

                          <h6 className="jobs-details-card-heading">{data?.main_category?.name}</h6>
                          <p className="jobs-details-card-paragraph">
                            {' '}
                            {OTHER_SUB_CATEGORY_IDS.includes(data?.sub_category?.id)
                              ? data?.other_sub_category_name
                              : data?.sub_category?.name}
                          </p>
                          <p className="jobs-details-card-paragraph">
                            From CA${data?.rate_from}/{data?.rate_type?.name == "Monthly" ? 'mo' : 'hr'} to
                            CA${data?.rate_to}/{data?.rate_type?.name == "Monthly" ? 'mo' : 'hr'}
                          </p>
                        </div>
                      );
                    }
                  }
                })
              ) : (
                <div style={{ display: 'flex' }}> No Job Data. </div>
              )}
            </div>
            {expertServiceAndRateDetailById && expertServiceAndRateDetailById?.data?.length > 3 && (
              <div className="btn-view">
                <Button type="primary" onClick={() => setExpendJobsList(!expandJobsList)}>
                  {expandJobsList ? 'View less' : 'View all'}
                </Button>
              </div>
            )}
          </section>

          {reviewsByUserId?.reviews?.data?.length > 0 && (
            <section className="expert-review" id="expert-review">
              <h2 className="section-title">Expert’s reviews</h2>
              {reviewsByUserId?.reviews?.data?.length ? (
                reviewsByUserId?.reviews?.data
                  ?.filter(el => el?.status === 'active')
                  .map((data, index) => {
                    return (
                      <>
                        <Row className="customer">
                          <Col xs={24} md={20}>
                            <h6 className="customer-name">
                              {data?.customer?.first_name} {data?.customer?.last_name}
                              <span style={{ marginInline: '20px' }}>
                                {/* {formatDateTo(data?.created_at, 'MM/DD/YYYY')} */}
                                {convertDateToTimezone(data?.created_at)}
                              </span>
                            </h6>
                          </Col>
                          <Col>
                            <span>
                              <Rate
                                tooltips={desc}
                                onChange={setValue}
                                value={data?.rating}
                                disabled
                              />
                              {value ? <span className="ant-rate-text"></span> : ''}
                            </span>
                          </Col>
                        </Row>
                        <p className="customer-text">{data?.review}</p>
                        {/* <Divider /> */}
                      </>
                    );
                  })
              ) : (
                <div className="customer">No Reviews.</div>
              )}
            </section>
            
          )}
        </>
      )}
    </div>
  );
};
