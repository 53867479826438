import React, { useEffect, useRef, useState } from 'react';
import { animatedMehtod, cuurentBrowser } from '../../helpers/functions';
import { useInView, animated } from 'react-spring';
import { Card, Col, Row } from 'antd';
import useResponsive from '../../hooks/useBreakpoint';

export default function HowItWork({ item, index }) {
  const getRootMargin = () => (cuurentBrowser() === 'Firefox' ? '-40% 0%' : '-75px 0px');

  const [playVideo, setPlayVideo] = useState(false);

  const ifreameRef = useRef();

  const [workingStepsRef, workingStepsSpring] = useInView(animatedMehtod, {
    rootMargin: getRootMargin(),
    once: true,
  });
  const [workingStepsLineRef, workingStepsLineSpring] = useInView(animatedMehtod, {
    rootMargin: getRootMargin(),
    once: true,
  });
  const breakPoint = useResponsive();
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setPlayVideo(ifreameRef.current.title);
          // observer.disconnect(); // Stop observing after it's visible
        } else {
          setPlayVideo(false);
        }
      },
      {
        threshold: 0.8, // 10% of the element should be visible
      },
    );

    if (ifreameRef.current) {
      observer.observe(ifreameRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return index % 2 === 0 ? (
    <>
      <animated.div style={workingStepsSpring} ref={workingStepsRef}>
        {index !== 0 ? (
          <animated.div
            key={index}
            id={index}
            style={workingStepsLineSpring}
            ref={workingStepsLineRef}
          >
            <div className="w100 line-container">
              <span className="line"></span>
            </div>
          </animated.div>
        ) : (
          ''
        )}  

        <Card className={index === 0 ? 'working-steps-card mt-10' : 'working-steps-card'}>
          <Row
            style={{ display: 'flex', alignItems: 'start', justifyContent: 'center' }}
            className=" benefits-row-container"
            // gutter={[75]}
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              className="d-flex-center benefits-description-wrapper align-start"
              style={{ paddingRight: '15px' }}
            >
              <Row>
                <Col xs={24} lg={4} xl={4}>
                  <Row
                    style={{
                      marginBottom:
                        (breakPoint == 'xs' || breakPoint == 'sm' || breakPoint == 'md') && '10px',
                    }}
                    className="how-it-work"
                  >
                    <Col xs={6} lg={24}>
                      <div className="steps-num-box">
                        <span className="steps-num">{index + 1}</span>
                      </div>
                    </Col>
                    <Col
                      xs={18}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent:
                          breakPoint == 'xs' || breakPoint == 'sm' || breakPoint == 'md'
                            ? 'end'
                            : 'start',
                      }}
                    >
                      {(breakPoint == 'xs' || breakPoint == 'sm' || breakPoint == 'md') && (
                        <h3 className="m-title ac-heading hiw-heading ">{item.title}</h3>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                  <div
                    className={`benefits-sub-text ${breakPoint == 'xs' || breakPoint == 'sm' || breakPoint == 'md' ? 'pl-0' : 'pl-6'}`}
                  >
                    {!(breakPoint == 'xs' || breakPoint == 'sm' || breakPoint == 'md') && (
                      <h3 className="m-title ac-heading hiw-heading" style={{ fontSize: '12px !important' }}>
                        {item.title}
                      </h3>
                    )}

                    <p className="sub-title ac-content hiw-content">{item.desc}</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="benefits-image-wrapper" xs={24} sm={24} md={24} lg={12} xl={12}>
              <div>
                <iframe
                  width="100%"
                  height="100%"
                  src={
                    item.vidURL +
                    '&muted=1&loop=1' +
                    (playVideo == 'Video_' + index && '&autoplay=1')
                  }
                  frameborder="0"
                  allow="autoplay;encrypted-media;fullscreen;picture-in-picture;accelerometer; clipboard-write;"
                  allowfullscreen={true}
                  style={{ minHeight: '40vh' }}
                  title={'Video_' + index}
                  ref={ifreameRef}
                />
              </div>
            </Col>
          </Row>
        </Card>
      </animated.div>
    </>
  ) : (
    <>
      <animated.div style={workingStepsSpring} ref={workingStepsRef}>
        <animated.div style={workingStepsLineSpring} ref={workingStepsLineRef}>
          <div className="w100 line-container">
            <span className="line"></span>
          </div>
        </animated.div>
        <Card className="working-steps-card">
          <Row
            style={{ display: 'flex', alignItems: 'start', justifyContent: 'center' }}
            className="benefits-row-container"
            // gutter={[75]}
          >
            <Col className="benefits-image-wrapper" xs={24} sm={24} md={24} lg={12} xl={12}>
              <div>
                <iframe
                  width="100%"
                  height="100%"
                  src={
                    item.vidURL +
                    '&muted=1&loop=1' +
                    (playVideo == 'Video_' + index && '&autoplay=1')
                  }
                  frameborder="0"
                  allow="autoplay;encrypted-media;fullscreen;picture-in-picture;accelerometer; clipboard-write;"
                  style={{ minHeight: '40vh' }}
                  title={'Video_' + index}
                  ref={ifreameRef}
                  allowfullscreen={true}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              className="d-flex-center benefits-description-wrapper align-start"
              style={{ padding: '0px 15px' }}
            >
              <Row style={{}}>
                <Col
                  order={breakPoint == 'xs' || breakPoint == 'sm' || breakPoint == 'md' ? 2 : 1}
                  style={{}}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={20}
                  xl={20}
                >
                  <div
                    className={`benefits-sub-text ${breakPoint == 'xs' || breakPoint == 'sm' || breakPoint == 'md' ? 'pl-0' : 'pl-6'}`}
                  >
                    {!(breakPoint == 'xs' || breakPoint == 'sm' || breakPoint == 'md') && (
                      <h3 className="m-title ac-heading hiw-heading" style={{ fontSize: '12px !important' }}>
                        {item.title}
                      </h3>
                    )}

                    <p className="sub-title ac-content hiw-content">{item.desc}</p>
                  </div>
                </Col>
                <Col
                  order={breakPoint == 'xs' || breakPoint == 'sm' || breakPoint == 'md' ? 1 : 2}
                  xs={24}
                  lg={4}
                  xl={4}
                >
                  <Row className="how-it-work" style={{ padding: '15px 0px' }}>
                    <Col
                      order={breakPoint == 'xs' || breakPoint == 'sm' || breakPoint == 'md' ? 2 : 1}
                      xs={6}
                      lg={24}
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
                    >
                      <div className="steps-num-box steps-num-box-black">
                        <span className="steps-num">{index + 1}</span>
                      </div>
                    </Col>
                    <Col
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                      }}
                      order={breakPoint == 'xs' || breakPoint == 'sm' || breakPoint == 'md' ? 1 : 2}
                      xs={18}
                    >
                      {(breakPoint == 'xs' || breakPoint == 'sm' || breakPoint == 'md') && (
                        <h3 className="m-title ac-heading hiw-heading ">{item.title}</h3>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </animated.div>
    </>
  );
}
