import React, { createContext, useContext, useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import { getUserData } from '../../helpers/functions';
import Echo from 'laravel-echo';

const PusherContext = createContext(null);

export const PusherProvider = ({ children }) => {
    const [pusher, setPusher] = useState(null);
    const [channel, setChannel] = useState(null);
    const [echo, setEcho] = useState(null);

    useEffect(() => {
        const pusherInstance = new Pusher(process.env.REACT_APP_APP_KEY, {
            cluster: process.env.REACT_APP_APP_CLUSTER,
        });
        const echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.REACT_APP_APP_KEY,
            cluster: process.env.REACT_APP_APP_CLUSTER,
            forceTLS: false,
        });

        const channelInstance = pusherInstance.subscribe(`${getUserData()?.id}`);

        setPusher(pusherInstance);
        setChannel(channelInstance);
        setEcho(echo);

        return () => {
            pusherInstance.unsubscribe(`${getUserData()?.id}`);
            pusherInstance.disconnect();
        };
    }, []);

    return (
        <PusherContext.Provider value={{ pusher, channel, echo }}>
            {children}
        </PusherContext.Provider>
    );
};

export const usePusher = () => useContext(PusherContext);
