import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { postRequest } from '../../../helpers/apiCall';

const ImagePreviewModal = ({ isModal, setIsModal, imgsrc, handleDownload, data, setdata }) => {
  return (
    <Modal
      visible={isModal}
      footer={null}
      onCancel={() => (setIsModal(false), setdata({}))}
      width="50%"

      title={<div>
        <Button
          onClick={() => handleDownload(data?.id)}
          type='primary'
        >
          Download
        </Button>
      </div>}
    >
      <img loading='lazy' width={'100%'} src={imgsrc} alt='Loading...'></img>
    </Modal>
  );
};

export default ImagePreviewModal;
