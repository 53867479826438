/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { Card, Form, Button, Spin, Radio, Space, Select, Col, Row } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserData } from '../../helpers/functions';
import AddAnotherModal from '../common/modal/addAnotherModal';
import { IoMdArrowRoundBack } from 'react-icons/io';
import CustomSelect from '../common/customSelect';
import useResponsive from '../../hooks/useBreakpoint';

const BusinessSector = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const { qid } = useParams();
  const [form] = Form.useForm();
  const { isBTLoading, businessTypeList, isQuestionLoading, questionDetail } = useSelector(
    state => state.common,
  );
  const { isStepUpdating, stepUpdateMsg } = useSelector(state => state.steps);
  const [isOpen, setIsOpen] = useState(false);
  const [subSectorList, setSubSectorList] = useState([]);

  useEffect(() => {
    if (qid) {
      dispatch({ type: 'GET_QUESTION_DETAIL', id: qid });
    }
    callBusinessSector();
  }, []);
  const callBusinessSector = () => {
    dispatch({ type: 'GET_BUSINESS_SECTOR_REQUEST', formData: { status: 'active' } });
  };

  const onFinish = values => {
    if (qid) {
      dispatch({
        type: 'QUESTION_STEP_UPDATE_REQUEST',
        formData: {
          ...values,
          user_id: getUserData().id,
          id: parseInt(qid),
        },
        requestType: 'post',
        step: 'business-sector',
      });
    }
  };

  useEffect(() => {
    if (!isQuestionLoading && Object.keys(questionDetail).length > 0) {
      const questionParentSectorId = questionDetail.sector ? questionDetail.sector.id : '';
      form.setFieldsValue({ sector_id: questionParentSectorId });
      if (businessTypeList.length > 0) {
        filterSubSectors(questionParentSectorId);
      }
    }
  }, [isQuestionLoading, questionDetail, businessTypeList]);

  useEffect(() => {
    if (!isStepUpdating && stepUpdateMsg === 'success') {
      handleNext(qid, 'invoice');
      dispatch({ type: 'CLEAR_STEPS' });
    }
  }, [isStepUpdating, stepUpdateMsg]);

  const optionsList = useCallback(items => {
    const options =
      items?.map(item => ({
        value: item.id,
        label: item.name,
      })) ?? [];
    return options;
  }, []);

  const filterSubSectors = sectorId => {
    const subSectors = businessTypeList.filter(item => item.parent_id === sectorId);
    setSubSectorList(subSectors);
    const subSectorId =
      sectorId === questionDetail.sector?.id
        ? questionDetail.sub_industry_category
          ? questionDetail.sub_industry_category.id
          : ''
        : '';
    form.setFieldsValue({ sub_industry_id: subSectorId });
  };

  const handleSectorChange = value => {
    form.setFieldsValue({ sub_industry_id: null });
    filterSubSectors(value);
  };
  const breakpoint = useResponsive();

  return (
    <div>
      <div className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={false} style={{  }}>
          {isBTLoading && <Spin />}
          {!isBTLoading && (
            <>
              <p className="m-title tl mb-7 d-flex-center">
                <span className="back-icon" onClick={() => handleBack(qid, 'business-type')}>
                  <IoMdArrowRoundBack />
                </span>
                <span>Select your business sector</span>
              </p>

              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                form={form}
                onFinish={onFinish}
                style={{ width: (breakpoint == "md" || breakpoint == "sm" || breakpoint == "xs") && "75vw" }}
              >
                <Row gutter={5} className="page-card-header">
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item
                      label="Sector"
                      name={'sector_id'}
                      rules={[{ required: true, message: 'Please select sector' }]}
                    >
                      <CustomSelect
                        placeholder="Select an option"
                        size="large"
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={optionsList(businessTypeList?.filter(item => !item.parent_id))}
                        onChange={handleSectorChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item label="Sub Sector" name={'sub_industry_id'}>
                      <CustomSelect
                        placeholder="Select an option"
                        size="large"
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={optionsList(subSectorList)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  {/* <Button
                                        type="secondray"
                                        htmlType="button"
                                        size="middle"
                                        className="mt5"
                                        onClick={() => setIsOpen(true)}
                                    >
                                        Add new
                                    </Button> */}
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    className="mt-5 float-r"
                    disabled={isStepUpdating}
                    loading={isStepUpdating}
                  >
                    Next
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
          {isOpen && (
            <AddAnotherModal
              open={isOpen}
              close={() => setIsOpen(false)}
              onReferesh={() => callBusinessSector()}
              modalType="business-sector"
              title="Add new business sector"
            />
          )}
        </Card>
      </div>
    </div>
  );
};
export default BusinessSector;
