import { CloseCircleOutlined, VideoCameraOutlined } from '@ant-design/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { breakName } from '../../helpers/functions';
import useResponsive from '../../hooks/useBreakpoint';

const ZoomMeeting = ({ setShowZoomMeeting, isFullnameDisplay }) => {

  const expertData = useSelector(state => state?.common?.questionDetail);
  const breakPoint = useResponsive();
  return (
    <div className="zoom-meeting-prechatview">
      <div
        style={{
          width: breakPoint === 'xs' || breakPoint === 'sm' || breakPoint === 'md' ? '100%' : '30%',
          height: '80%',
          backgroundColor: 'rgba(28,100,242)',
          borderRadius: '8px',
          padding: '10px 10px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
          <div>
            <h1 style={{ color: 'white' }}>Meeting with</h1>
            <h3 style={{ color: 'rgba(255,255,255,0.5)' }}>

              {
                (!isFullnameDisplay) ?

                  breakName(expertData?.expert?.first_name, expertData?.expert?.last_name)

                  :
                  expertData?.expert?.first_name + ' ' + expertData?.expert?.last_name

              }
            </h3>
          </div>
          <CloseCircleOutlined
            onClick={() => setShowZoomMeeting(false)}
            style={{ color: 'white', fontSize: '1.25rem' }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'start' }}>
          <VideoCameraOutlined style={{ color: 'rgba(255,255,255,0.5)', fontSize: '6rem' }} />
        </div>
      </div>
    </div>
  );
};

export default ZoomMeeting;
