import React, { useEffect } from 'react';
import { Card, Form, Button, Input, Divider, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Parent from './parent';
import Helmet from '../common/helmet';
import { role } from '../../helpers/functions';
import { ROLE_NAME } from '../../helpers/constant';
import { useState } from 'react';
import VerifiedOTPModal from '../common/modal/verifiedOTPModal';
import RadioButtonImage from '../common/radioButtonImage';
import expertSignupChoice from '../../assets/images/Expert-signup-img.jpg';
import clientSignupChoice from '../../assets/images/Client-signup-img.png';
import googleLogo from '../../assets/images/google_logo.svg';
import { use } from 'react';
import useResponsive from '../../hooks/useBreakpoint';

const Login = props => {
  const dispatch = useDispatch();

  const { isLoginLoading, loginMsg, loginData, google_auth_redirect_url } = useSelector(
    state => state.auth,
  );

  let { search } = useLocation();

  const query = new URLSearchParams(search);

  const [showModal, setShowModal] = useState(false);

  const [loginType, setLoginType] = useState();

  const [isSignupToggle, setIsSignupToggle] = useState(false);

  const [loadingGoogleBtn, setLoadingGoogleBtn] = useState(false);

  const onFinish = values => {
    dispatch({ type: 'LOGIN_REQUEST', formData: values });
  };

  const onFinishFailed = errorInfo => {};

  useEffect(() => {
    setLoginType(query.get('type'));
  }, [isSignupToggle,search]);

  useEffect(() => {
    if (!isLoginLoading && loginMsg === 'success') {
      dispatch({ type: 'CLEAR_LOGIN_DATA' });
      if (localStorage.getItem('redirectLink')) {
        let tmoURL = localStorage.getItem('redirectLink');
        localStorage.removeItem('redirectLink');
        window.location.href = tmoURL;
      } else {
        if (role() === ROLE_NAME.ADMIN) {
          window.location.href = '/admin/requests';
        } else if (role() === ROLE_NAME.EXPERT) {
          window.location.href = '/dashboard/expert/request';
        } else {
          window.location.href = '/dashboard/customer';
        }
      }
    } else if (!isLoginLoading && loginMsg === 'not-verified') {
      message.error('Please verified your email address');

      setShowModal(true);
    } else if (google_auth_redirect_url) {
      // Get screen width and height
      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      const width = 800;
      const height = 600;
      // Calculate left and top positions for centering
      const left = (screenWidth - width) / 2;
      const top = (screenHeight - height) / 2;
      const windowFeatures = `width=${width},height=${height},top=${top},left=${left},resizable=no,scrollbars=yes`;

      window.open(google_auth_redirect_url, 'MiniBrowser', windowFeatures);

      window.addEventListener('message', handleMiniWindowMessage);

      dispatch({ type: 'CLEAR_LOGIN_DATA' });
    } else if (!isLoginLoading && loginMsg === 'error') {
      message.error('Invalid email or password');
    }
  }, [isLoginLoading, loginMsg, loginData, google_auth_redirect_url]);

  const SIGNUP_TYPES = [
    {
      value: 'expert',
      img: expertSignupChoice,
      label: 'Expert',
    },
    {
      value: 'customer',
      img: clientSignupChoice,
      label: 'Client',
    },
  ];

  const handleGoogleBtnClick = () => {
    setLoadingGoogleBtn(true);
    dispatch({ type: 'GOOGLE_REDIREACT_URL_GET' });
  };
  const handleMiniWindowMessage = event => {
    // Ensure the message is from the mini window
    if (event.origin !== window.location.origin) return;

    // Handle the message
    const windowRes = JSON.parse(event.data);

    if (windowRes) {
      if (windowRes?.success && windowRes?.data?.email) {
        const data = windowRes?.data;
        if (data?.token) {
          dispatch({ type: 'LOGIN_REQUEST', formData: data });
        } else if (loginType === 'customer') {
          window.location.href =
            '/signup?email=' +
            (data?.email || '') +
            '&first_name=' +
            (data?.first_name || '') +
            '&last_name=' +
            (data?.last_name || '');
        } else {
          window.location.href =
            '/expert/signup?email=' +
            (data?.email || '') +
            '&first_name=' +
            (data?.first_name || '') +
            '&last_name=' +
            (data?.last_name || '');
        }
      } else {
        message.error({
          content: windowRes?.message || 'Please try again.',
          className: 'error-class',
        });
      }
    } else {
      message.error({
        content: 'Something went wrong please try again.',
        className: 'error-class',
      });
    }
    setLoadingGoogleBtn(false);
    cleanup();
  };

  const cleanup = () => {
    // Remove event listener when the mini window closes
    window.removeEventListener('message', handleMiniWindowMessage);
  };

  useEffect(() => {
    // Clean up when the component unmounts
    return () => cleanup();
  }, []);
  const breakpoint = useResponsive();
  return (
    <Parent isSignupToggle={isSignupToggle}>
      <Helmet title="Login" />
      <div className="d-flex-between" style={{ height: '100vh',}}>
        <div
          className="login-card-container"
          style={{
            width: !isSignupToggle
              ? breakpoint === 'lg' || breakpoint === 'xl' || breakpoint === 'xxl'
                ? '40%'
                : '100%'
              : '100%',
            display: 'flex',
            justifyContent:
              breakpoint === 'lg' || breakpoint === 'xl' || breakpoint === 'xxl'
                ? isSignupToggle
                  ? 'center'
                  : 'end'
                : 'center',
            alignItems: 'center',
          }}
        >
          <div className="login-card">
            <div
              className="site-card-border-less-wrapper"
           
            >
              <Card bordered={false}>
                {isSignupToggle ? (
                  // Signup form
                  <article style={{}}>
                    <p className="m-title">Signup as </p>
                    <RadioButtonImage
                      setCurrentValue={setLoginType}
                      buttonList={SIGNUP_TYPES}
                      loginType={query.get('type')}
                    ></RadioButtonImage>
                    <Link to={loginType === 'customer' ? '/signup' : '/expert/signup'}>
                      <Button
                        style={{ marginBottom: '24px' }}
                        disabled={!loginType}
                        type="primary"
                        htmlType="submit"
                        className="w100"
                        size="large"
                      >
                        Signup
                      </Button>
                    </Link>
                    {/* <Button
                      style={{ marginBottom: '24px' }}
                      htmlType="button"
                      className="w100"
                      size="large"
                      disabled={!loginType}
                      onClick={handleGoogleBtnClick}
                    >
                      <img src={googleLogo} style={{ width: '30px', height: '30px', marginRight: '5px' }} alt="" />

                      Continue with Google
                    </Button> */}
                    <div className="mt8 tc">
                      <p>
                        Have an account?{' '}
                        <span
                          onClick={() => {
                            setIsSignupToggle(false);
                            setLoginType(query?.get('type'));
                          }}
                          style={{ color: '#478bff', cursor: 'pointer' }}
                        >
                          Login
                        </span>
                      </p>
                    </div>
                  </article>
                ) : (
                  // Login form
                  <article style={{ height: '100%' }}>
                    <p
                      className="m-title"
                      style={{
                        height: '13%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {loginType === 'expert' ? 'Expert Login' : 'Client Login'}
                    </p>
                    <Form
                      layout="vertical"
                      name="basic"
                      hideRequiredMark={true}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      style={{
                        height: '87%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2vh',
                      }}
                    >
                      <Form.Item
                        label="Email address"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter email address',
                          },
                          {
                            type: 'email',
                            message: 'Please enter valid email address',
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Email address" />
                      </Form.Item>

                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter password',
                          },
                        ]}
                      >
                        <Input.Password size="large" placeholder="••••••••••" />
                      </Form.Item>

                      <Form.Item name="remember" valuePropName="checked" className="tr">
                        <Link to="/forget-password" className="b500">
                          Forgot Password?
                        </Link>
                      </Form.Item>

                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="w100"
                          size="large"
                          loading={isLoginLoading}
                          disabled={isLoginLoading}
                        >
                          Login
                        </Button>
                      </Form.Item>

                      {/* <Button
                        style={{ marginBottom: '24px' }}
                        htmlType="button"
                        className="w100"
                        size="large"
                        loading={loadingGoogleBtn}
                        disabled={loadingGoogleBtn}
                        onClick={handleGoogleBtnClick}
                      >
                        <img src={googleLogo} style={{ width: '30px', height: '30px', marginRight: '5px' }} alt="" />

                        Continue with Google
                      </Button> */}

                      <div className="mt8 tc">
                        <p>
                          Don't have an account yet?{' '}
                          <span
                            onClick={() => {
                              setIsSignupToggle(true);
                              setLoginType();
                            }}
                            style={{ color: '#478bff', cursor: 'pointer' }}
                          >
                            Signup
                          </span>
                        </p>
                      </div>
                    </Form>
                  </article>
                )}
              </Card>
            </div>
          </div>
        </div>
        {(breakpoint === 'lg' || breakpoint === 'xl' || breakpoint === 'xxl') && (
          <div
            className="login-side-img-container"
            style={{
              width: !isSignupToggle ? '60%' : 'auto',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center',
              paddingLeft: '6vw',
            }}
          >
            {!isSignupToggle ? (
              loginType === 'expert' ? (
                <>
                  <img
                    src={expertSignupChoice}
                    alt="login-side-img"
                    loading="lazy"
                    style={{ width: '75%' }}
                  />
                  <h2 className="ac-heading">
                    Welcome to Accountizer! Where AI works for you, introducing you precisely and
                    correctly to clients.
                  </h2>
                  <p className="ac-content">
                    Log in to streamline your workflow, manage your client relationships
                    effectively, and leverage innovative tools that simplify your professional
                    tasks.
                  </p>
                </>
              ) : (
                <>
                  <img
                    src={clientSignupChoice}
                    alt="login-side-img"
                    loading="lazy"
                    style={{ width: '75%' }}
                  />
                  <h2 className="ac-heading">
                    Welcome to Accountizer! Where AI enhances your decision-making in selecting
                    accounting professionals.
                  </h2>
                  <p className="ac-content">
                    Log In and let our technology help you decide the right match based on the
                    expertise and qualifications that are tailored to your needs. Streamline your
                    projects and manage your relationships with professionals efficiently with our
                    innovative task management tools.
                  </p>
                </>
              )
            ) : (
              ''
            )}
          </div>
        )}
      </div>
      {showModal && (
        <VerifiedOTPModal
          visible={showModal}
          setVisible={() => {
            setShowModal(false);
          }}
        />
      )}
    </Parent>
  );
};

export default Login;
