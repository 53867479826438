import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { API_URL } from '../../helpers/constant';

function GoogleCallback() {

    const { auth_google_user_data } = useSelector(state => state.auth);

    const [loading, setLoading] = useState(true);

    const location = useLocation();

    const dispatch = useDispatch();

    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.get('code')) {
                // dispatch({ type: 'GOOGLE_CALL_BACK', params: location.search });
                fetch(`${API_URL}google/auth/callback${location.search}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        window.opener.postMessage(JSON.stringify(data), window.location.origin);

                    }).finally(() => {
                        window.close();
                    });
            }
        }

    }, []);
    useEffect(() => {
        if (auth_google_user_data && auth_google_user_data?.success !== undefined) {

            // window.opener.postMessage(JSON.stringify(auth_google_user_data || {}), window.location.origin);
            // window.close();

        }
    }, [auth_google_user_data]);

    if (loading) {
        return <DisplayLoading />
    }
}

function DisplayLoading() {
    return <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spin size="large" />
    </div>;
}

export default GoogleCallback;