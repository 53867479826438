/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Modal, Form, Input, Spin } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateTo } from '../../../helpers/functions';

const { TextArea } = Input;

const AddAnotherModal = props => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { open, close, onReferesh, modalType, title = 'Add new', questionDetail = '' } = props;
  const { isCommonUpdateLoading, commonMsg } = useSelector(state => state.common);
  const onFinish = values => {
    form
      .validateFields()
      .then(values => {
        let formData = { ...values };
        if (modalType === 'sector') {
        } else if (modalType === 'subCategory') {
          formData.parent_id = questionDetail?.category?.id;
        }
        dispatch({
          type: 'COMMON_PUT_POST_REQUEST',
          reqType: 'post',
          module: modalType,
          formData: formData,
        });
      })
      .catch(errorInfo => {});
  };

  useEffect(() => {
    if (!isCommonUpdateLoading && commonMsg === 'success') {
      dispatch({ type: 'CLEAR_COMMON' });
      onReferesh();
      close(false);
    }
  }, [isCommonUpdateLoading, commonMsg]);

  return (
    <Modal
      title={title}
      className="payment-modal question-modal"
      open={open}
      onOk={onFinish}
      // header={false}
      // footer={false}
      onCancel={() => close(false)}
      okText={'Submit'}
      okButtonProps={{
        disabled: isCommonUpdateLoading,
        loading: isCommonUpdateLoading,
      }}
      cancelText={'Cancel'}
      width={650}
    >
      <Row className="mt15">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt15">
          <Form layout="vertical" name="basic" form={form} onFinish={onFinish} autoComplete="off">
            <Form.Item
              name={modalType === 'sector' ? 'name' : 'name'}
              rules={[
                {
                  required: true,
                  message: 'Please enter value',
                },
                {
                  max: 100,
                  message: 'Maximum 100 characters are allowed',
                },
              ]}
            >
              <Input placeholder="Enter value" size="large" />
            </Form.Item>
            {/* <Form.Item
                                    style={{ display: 'none' }}
                                    name={'question_id'}
                                    initialValue={questionId}
                                >
                                </Form.Item> */}
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddAnotherModal;
