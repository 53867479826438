import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Input, message } from 'antd';
import Parent from './parent';
import Helmet from '../common/helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

const ForgetPassword = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { isForgetPasswordLoading, forgetMsg, resendTokenStatus, isResendTokenLoading } =
    useSelector(state => state.auth);
  const [fieldValue, setFieldValue] = useState({});

  useEffect(() => {
    if (!isResendTokenLoading && resendTokenStatus === 'success') {
      navigate(`/set-password`);
      message.success('OTP has been sent to your email address');
    } else if (fieldValue.email && !isResendTokenLoading && resendTokenStatus !== 'success') {
      message.error('You are not registered with entered email.');
      // dispatch({ type: 'CLEAR_LOGIN_DATA' });
    }
  }, [resendTokenStatus]);

  const onFinish = values => {
    setFieldValue(values);
    dispatch({ type: 'FORGET_PASSWORD_REQUEST', formData: values });
  };

  useEffect(() => {
    if (!isForgetPasswordLoading && forgetMsg === 'success') {
      message.success('Password reset mail sent successfully');
      dispatch({ type: 'CLEAR_INVITE' });
      navigate('/reset-password?email=' + fieldValue.email);
    } else if (!isForgetPasswordLoading && forgetMsg?.errors) {
      let msg = forgetMsg?.message;
      msg = msg.replace('selected', 'given');
      message.error({
        content: msg || 'Email address not exists',
        className: 'error-class',
        duration: 3,
      });
    } else if (!isForgetPasswordLoading && forgetMsg?.message) {
      message.error({ content: forgetMsg?.message, className: 'error-class', duration: 3 });
    }
    dispatch({ type: 'CLEAR_INVITE' });
  }, [isForgetPasswordLoading, forgetMsg]);

  const onFinishFailed = errorInfo => {};

  return (
    <Parent>
      <Helmet title="Forget Password" />
      <div className="login-card">
        <div className="site-card-border-less-wrapper">
          <Card bordered={false}>
            <p className="m-title">Forgot Password</p>
            <Form
              layout="vertical"
              name="basic"
              hideRequiredMark={true}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Email address"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter email address',
                  },
                  {
                    type: 'email',
                    message: 'Please enter valid email address',
                  },
                ]}
              >
                <Input size="large" placeholder="Email address" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="w100 mt25" size="large">
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </Parent>
  );
};

export default ForgetPassword;
