/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import ReactLinkify from 'react-linkify';
import { Col, Modal, Row, Spin } from 'antd';
import {
  getClientRejectionObject,
  getExpertRejectionObject,
  getSubCategoryName,
} from '../../../helpers/functions';
import { Link } from 'react-router-dom';

const QuestionDetailModal = props => {
  const { visible, setVisible, questionId, title } = props;
  const dispatch = useDispatch();
  const { questionDetail, isQuestionLoading } = useSelector(state => state.common);

  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const expertRejectionStatus = getExpertRejectionObject(
    questionDetail?.question_rejection,
  )?.filter(e => e);
  const clientRejectionStatus = getClientRejectionObject(
    questionDetail?.question_rejection,
  )?.filter(e => e);
  useEffect(() => {
    if (questionId) {
      dispatch({ type: 'GET_QUESTION_DETAIL', id: questionId });
    }
  }, [questionId]);

  const isStatusAssigned = id => {
    if (id >= 3) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      footer={false}
      width={850}
      centered
      title={title}
    >
      <div
        style={{
          border: 'none',
          maxHeight: (height * 70) / 100,
          overflowY: 'scroll',
        }}
        className="request-details-for-question-modal"
      >
        {isQuestionLoading ? (
          <div style={{ minHeight: (height * 40) / 100 }} className="d-flex-a-c">
            <Spin />
          </div>
        ) : (
          <div className="request-details-wrapper-for-question-detail">
            <div style={{ marginBottom: '20px' }}>
              <h6>ID : #{questionDetail?.id}</h6>
              <h6>
                Client Name :{' '}
                {`${questionDetail?.user?.first_name} ${questionDetail?.user?.last_name}`}
              </h6>
            </div>
            <div className="general">
              <ReactLinkify>{questionDetail?.question_disc}</ReactLinkify>
            </div>

            {questionDetail?.question_rejection?.length ? (
              <>
                <h6 className="mt-4">Past assignments</h6>
                <Row gutter={20}>
                  {questionDetail.question_rejection.map(rejection => (
                    <>
                      <Col span="6">
                        {rejection.expert ? (
                          <>
                            <Link to={`/application-detail/${rejection.expert.id}`}>
                              {rejection.expert.first_name + ' ' + rejection.expert.last_name}
                            </Link>
                          </>
                        ) : null}
                      </Col>
                      <Col span="18">
                        <h6 style={{ color: '#ff8383' }}>
                          Rejected by {rejection.reject_by_client ? 'client' : 'expert'} due to{' '}
                        </h6>
                        <p className="general">{rejection.reasons_for_rejection}</p>
                      </Col>
                    </>
                  ))}
                </Row>
              </>
            ) : null}

            <div className="request-group">
              <div>
                <h6>I need to contact an expert for a</h6>
                <p>
                  {questionDetail?.question_type?.name ? questionDetail?.question_type?.name : '-'}
                </p>
              </div>
              <div>
                <h6>I am (a/an) or representing (a/an)</h6>
                <p>
                  {questionDetail?.customer_type?.name ? questionDetail?.customer_type?.name : '-'}
                </p>
              </div>
              <div>
                <h6>Service category</h6>
                <p>{questionDetail?.category?.name ? questionDetail?.category?.name : '-'}</p>
              </div>
              {!isStatusAssigned(questionDetail?.status?.question_status_id) && (
                <div>
                  <h6>Type of service</h6>
                  <p>
                    {questionDetail?.sub_category?.name ? questionDetail?.sub_category?.name : '-'}
                  </p>
                </div>
              )}

              <>
                <div>
                  <h6>
                    {questionDetail?.category?.name
                      ? questionDetail?.category?.name + ' Subcategory'
                      : '-'}
                  </h6>
                  <p>{getSubCategoryName(questionDetail)}</p>
                </div>
                {questionDetail?.sector?.name && (
                  <div>
                    <h6>Business Sector</h6>
                    <p>{questionDetail?.sector?.name ? questionDetail?.sector?.name : '-'}</p>
                  </div>
                )}
              </>

              {!isStatusAssigned(questionDetail?.status?.question_status_id) && (
                <div>
                  <h6>Estimated level of seniority required</h6>
                  <p>{questionDetail?.seniority?.name ? questionDetail?.seniority?.name : '-'}</p>
                </div>
              )}

              {questionDetail?.ownership && (
                <div>
                  <h6>Ownership type</h6>
                  <p>{questionDetail?.ownership?.name ? questionDetail?.ownership?.name : '-'}</p>
                </div>
              )}
              <div>
                <h6>Location</h6>
                <p>{questionDetail?.location?.name ? questionDetail?.location?.name : '-'}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default QuestionDetailModal;
