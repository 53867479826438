/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Form, Button, Input, Spin, Radio, Space } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getUserData } from '../../helpers/functions';
import AddAnotherModal from '../common/modal/addAnotherModal';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { OTHER_SUB_CATEGORY_IDS } from '../../helpers/constant';

const SubCategory = ({ handleNext, dependent = '', handleBack }) => {
  const dispatch = useDispatch();
  const { qid } = useParams();
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const { isQuestionLoading, questionDetail, isSubCateLoading, subCategoryList } = useSelector(
    state => state.common,
  );
  const { isStepUpdating, stepUpdateMsg } = useSelector(state => state.steps);
  const [categoryName, setCategoryName] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isOtherSubCategorySelected, setIsOtherSubCategorySelected] = useState(false);
  const [shouldAutoFocusOther, setShouldAutoFocusOther] = useState(false);

  useEffect(() => {
    if (qid) {
      dispatch({ type: 'GET_QUESTION_DETAIL', id: qid });
    }
  }, []);

  const getSubCategoryList = parentId => {
    dispatch({
      type: 'GET_SUB_CATEGORY_REQUEST',
      formData: { parent_id: parentId, per_page: 1000 },
    });
  };

  useEffect(() => {
    if (!isQuestionLoading && Object.keys(questionDetail).length > 0) {
      getSubCategoryList(questionDetail?.category?.id);
      setCategoryName(questionDetail?.category?.name || '');

      if (questionDetail['sub-category'] == null) {
        return;
      }

      form.setFieldsValue({
        sub_category_id: questionDetail['sub-category']?.id,
        other_sub_category_name: questionDetail['sub-category']?.other_sub_category_name,
      });
      if (OTHER_SUB_CATEGORY_IDS.includes(questionDetail['sub-category']?.id)) {
        setIsOtherSubCategorySelected(true);
        setShouldAutoFocusOther(false);
      }
    }
  }, [isQuestionLoading, questionDetail]);

  const onChange = e => {
    if (OTHER_SUB_CATEGORY_IDS.includes(e.target.value)) {
      setIsOtherSubCategorySelected(prev => {
        setShouldAutoFocusOther(!prev);
        return true;
      });
    } else {
      setIsOtherSubCategorySelected(false);
      setShouldAutoFocusOther(false);
    }
  };

  const onFinish = values => {
    if (qid) {
      dispatch({
        type: 'QUESTION_STEP_UPDATE_REQUEST',
        formData: {
          ...values,
          user_id: getUserData().id,
          id: qid,
        },
        requestType: 'post',
        step: 'sub-category',
      });
    }
  };

  useEffect(() => {
    if (!isStepUpdating && stepUpdateMsg === 'success') {
      handleNext(qid, 'location');
      dispatch({ type: 'CLEAR_STEPS' });
    }
  }, [isStepUpdating, stepUpdateMsg]);

  return (
    <div>
      <div className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={false}>
          {isQuestionLoading || isSubCateLoading ? (
            <Spin />
          ) : (
            <>
              <p className="m-title tl mb-7 d-flex-center">
                <span
                  className="back-icon"
                  onClick={() => {
                    if (
                      searchParams.get('prev') !== null &&
                      searchParams.get('prev') === 'service-type'
                    ) {
                      handleBack(qid, 'prev-service-type');
                    } else {
                      handleBack(qid, 'sub-category');
                    }
                  }}
                >
                  <IoMdArrowRoundBack />
                </span>
                <span>{categoryName}</span>
              </p>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                form={form}
                onFinish={onFinish}
              >
                <Form.Item
                  name="sub_category_id"
                  rules={[{ required: true, message: 'Please select at least one option' }]}
                >
                  <Radio.Group className={'mt3'} style={{ width: '100%' }} onChange={onChange}>
                    <Space direction="vertical" size={'middle'} style={{ width: '100%' }}>
                      {isSubCateLoading ? (
                        <Spin />
                      ) : (
                        subCategoryList !== undefined &&
                        subCategoryList.length > 0 &&
                        subCategoryList?.map((item, idx) => {
                          return (
                            <>
                              <Radio style={{ fontSize: '18px' }} key={idx} value={item.id}>
                                {item.name}
                              </Radio>
                            </>
                          );
                        })
                      )}
                      {!isSubCateLoading && subCategoryList.length === 0 && (
                        <p>No Sub Categories found, choose another category</p>
                      )}
                    </Space>
                  </Radio.Group>
                </Form.Item>
                {isOtherSubCategorySelected && (
                  <Form.Item
                    name="other_sub_category_name"
                    rules={[{ required: true, message: 'Please enter the name' }]}
                  >
                    <Input
                      size="large"
                      placeholder="Type here..."
                      autoFocus={shouldAutoFocusOther}
                    />
                  </Form.Item>
                )}
                <Form.Item>
                  {/* <Button
                                        type="secondray"
                                        htmlType="button"
                                        size="middle"
                                        className="mt5"
                                        onClick={() => setIsOpen(true)}
                                    >
                                        Add new
                                    </Button> */}
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    className="mt5 float-r"
                    disabled={isStepUpdating}
                    loading={isStepUpdating}
                  >
                    Next
                  </Button>
                </Form.Item>
              </Form>

              {/* <Form
                            layout="vertical"
                            name="basic"
                            hideRequiredMark={true}
                            autoComplete="off"
                        >
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="float-r"
                                    size="middle"
                                >
                                    Next
                                </Button>
                            </Form.Item>
                        </Form> */}
            </>
          )}
        </Card>
        {isOpen && (
          <AddAnotherModal
            open={isOpen}
            close={() => setIsOpen(false)}
            onReferesh={() => getSubCategoryList(questionDetail?.category?.id)}
            modalType="subCategory"
            title="Add new sub category"
            questionDetail={questionDetail}
          />
        )}
      </div>
    </div>
  );
};
export default SubCategory;
