import React from 'react';
import { Row, Col } from 'antd';
import { getUserData } from '../../helpers/functions';
import useResponsive from '../../hooks/useBreakpoint';
const NotificationCard = ({ notification, time }) => {
  const breakpoint = useResponsive();
  return (
    <>
      <Row
        style={{
          backgroundColor: '',
          border: '2px solid black',
          borderRadius: '1rem',
          padding: '1rem',
          marginTop: '1rem',
        }}
      >
        <Col xs={24} lg={20}>{notification}</Col>
        <Col xs={24} lg={4} style={{ textAlign: 'right',marginTop: (breakpoint === 'xs' || breakpoint === 'sm') && '1rem' }}>
          {time}</Col>
      </Row>
    </>
  );
};

export default NotificationCard;
