import React from 'react'

import loader from '../../assets/images/loader.gif'

export default function Loader() {
    return (
        <div >
            <img style={{ width: "75px" }} src={loader} alt="loader" loading='lazy' />
        </div>
    )
}
