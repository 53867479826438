const initialState = {
  isLoginLoading: false,
  loginMsg: '',

  loginData: {},
  isSignupLoading: false,

  signupStatus: '',
  signupData: {},

  isOtpVerifyLoading: false,
  otpVerifyStatus: '',

  isResendTokenLoading: false,
  resendTokenStatus: '',

  isChangePasswordUsingTokenLoading: false,
  changePasswordUsingTokenStatus: '',

  isUserLoading: false,
  userData: {},

  isChaPassLoading: false,
  changePassMsg: '',

  google_auth_redirect_url: '',
  auth_google_user_data: [],
};

const AuthReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /*------------ */
    case 'LOGIN_REQUEST':
      state = {
        ...state,
        isLoginLoading: true,
        loginMsg: '',
      };
      break;

    case 'LOGIN_SUCCESS':
      state = {
        ...state,
        isLoginLoading: false,
        loginMsg: action.loginMsg,
        loginData: action.data,
      };
      break;

    case 'LOGIN_ERROR':
      state = {
        ...state,
        isLoginLoading: false,
        loginMsg: action.error,
      };
      break;

    /*------------ */
    case 'SIGNUP_REQUEST':
      state = {
        ...state,
        isSignupLoading: true,
        signupData: {},
        signupStatus: '',
      };
      break;

    case 'SIGNUP_SUCCESS':
      state = {
        ...state,
        isSignupLoading: false,
        signupData: action.data,
        signupStatus: action.status,
      };
      break;

    case 'SIGNUP_ERROR':
      state = {
        ...state,
        isSignupLoading: false,
        signupStatus: action.status,
        signupData: action.data,
      };
      break;

    /*------------ */
    case 'GET_LOGGED_IN_USER_REQUEST':
      state = {
        ...state,
        isMyDataLoading: true,
        myData: {},
      };
      break;

    case 'GET_LOGGED_IN_USER_SUCCESS':
      state = {
        ...state,
        isMyDataLoading: false,
        myData: action.data,
      };
      break;

    case 'GET_LOGGED_IN_USER_ERROR':
      state = {
        ...state,
        isMyDataLoading: false,
        myData: {},
      };
      break;

    /*------------ */
    case 'OTP_VERIFY_REQUEST':
      state = {
        ...state,
        isOtpVerifyLoading: true,
        otpVerifyStatus: '',
      };
      break;

    case 'OTP_VERIFY_SUCCESS':
      state = {
        ...state,
        isOtpVerifyLoading: false,
        otpVerifyStatus: action.data,
      };
      break;

    case 'OTP_VERIFY_ERROR':
      state = {
        ...state,
        isOtpVerifyLoading: false,
        otpVerifyStatus: action.data,
      };
      break;

    /*------------*/
    case 'UPDATE_PROFILE_REQUEST':
      state = {
        ...state,
        isUpdating: true,
        updatingMsg: '',
      };
      break;

    case 'UPDATE_PROFILE_SUCCESS':
      state = {
        ...state,
        isUpdating: false,
        updatingMsg: 'success',
      };
      break;

    case 'UPDATE_PROFILE_ERROR':
      state = {
        ...state,
        isUpdating: false,
        updatingMsg: '',
      };
      break;

    /*------------ */
    case 'GET_CURRENT_USER_REQUEST':
      state = {
        ...state,
        isUserLoading: true,
        userData: {},
      };
      break;

    case 'GET_CURRENT_USER_SUCCESS':
      state = {
        ...state,
        isUserLoading: false,
        userData: action.data,
      };
      break;

    case 'GET_CURRENT_USER_ERROR':
      state = {
        ...state,
        isUserLoading: false,
        userData: {},
      };
      break;

    /*------------ */
    case 'CHANGE_PASSWORD_REQUEST':
      state = {
        ...state,
        isChaPassLoading: true,
        changePassMsg: '',
      };
      break;

    case 'CHANGE_PASSWORD_SUCCESS':
      state = {
        ...state,
        isChaPassLoading: false,
        changePassMsg: 'success',
      };
      break;

    case 'CHANGE_PASSWORD_ERROR':
      state = {
        ...state,
        isChaPassLoading: false,
        changePassMsg: '',
      };
      break;
    /*------------ */
    case 'OTP_REQUEST':
      state = {
        ...state,
        isOtpLoading: true,
        optStatus: '',
      };
      break;

    case 'OTP_SUCCESS':
      state = {
        ...state,
        isOtpLoading: false,
        optStatus: action.data,
      };
      break;

    case 'OTP_ERROR':
      state = {
        ...state,
        isOtpLoading: false,
        optStatus: action.data,
      };
      break;

    /*------------ */
    case 'FORGET_PASSWORD_REQUEST':
      state = {
        ...state,
        isResendTokenLoading: true,
        resendTokenStatus: '',
      };
      break;

    case 'FORGET_PASSWORD_REQUEST_SUCCESS':
      state = {
        ...state,
        isResendTokenLoading: false,
        resendTokenStatus: action.data,
      };
      break;

    case 'FORGET_PASSWORD_REQUEST_ERROR':
      state = {
        ...state,
        isResendTokenLoading: false,
        resendTokenStatus: action.data,
      };
      break;

    /*------------ */
    case 'CHANGE_PASSWORD_USING_TOKEN':
      state = {
        ...state,
        isChangePasswordUsingTokenLoading: true,
        changePasswordUsingTokenStatus: '',
      };
      break;

    case 'CHANGE_PASSWORD_USING_TOKEN_SUCCESS':
      state = {
        ...state,
        isChangePasswordUsingTokenLoading: false,
        changePasswordUsingTokenStatus: action.data,
      };
      break;

    case 'CHANGE_PASSWORD_USING_TOKEN_ERROR':
      state = {
        ...state,
        isChangePasswordUsingTokenLoading: false,
        changePasswordUsingTokenStatus: action.data,
      };
      break;

    case 'GOOGLE_REDIREACT_URL_GET_ERROR':
      state = {
        ...state,
        google_auth_redirect_url: '',
      };
      break;

    case 'GOOGLE_REDIREACT_URL_GET_SUCCESS':
      state = {
        ...state,
        google_auth_redirect_url: action.data,
      };
      break;

    case 'GOOGLE_CALLBACK_ERROR':
      state = {
        ...state,
        auth_google_user_data: action.data,
      };
      break;

    case 'GOOGLE_CALLBACK_SUCCESS':
      state = {
        ...state,
        auth_google_user_data: action.data,
      };
      break;

    case 'CLEAR_LOGIN_DATA':
      state = {
        ...state,
        loginData: {},
        loginMsg: '',
        isOtpVerifyLoading: false,
        otpVerifyStatus: '',
        isSignupLoading: false,
        signupStatus: '',
        signupData: {},
        isUpdating: false,
        updatingMsg: '',
        changePassMsg: '',
        changePasswordUsingTokenStatus: '',
        resendTokenStatus: '',
        google_auth_redirect_url: '',
        auth_google_user_data: [],
      };
      break;

    default:
  }
  return state;
};

export default AuthReducer;
