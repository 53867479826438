import { Modal, Form, Select, Row, Col, InputNumber, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const AddCreditModal = props => {
  const { visible, setVisible, rowData, doRefresh } = props;
  const dispatch = useDispatch();
  const { isAdminUloading, adminUpdateMsg, isCreditLoading } = useSelector(state => state.admin);
  const [form] = Form.useForm();
  const [credits, setCredits] = useState(100);
  const [selectedRow, setSelectedRow] = useState();
  useEffect(() => {
    setSelectedRow(rowData);
  }, []);

  const onFinish = values => {
    form
      .validateFields()
      .then(values => {
        if (credits && rowData?.expert_id) {
          dispatch({
            type: 'ADMIN_ADD_UPDATE_REQUEST',
            reqType: 'post',
            module: 'credit',
            formData: {
              user_id: selectedRow?.expert_id,
              credit_amount: credits,
            },
          });
          doRefresh();
        }
      })

      .catch(errorInfo => {});
  };

  useEffect(() => {
    if (!isAdminUloading && adminUpdateMsg === 'success') {
      dispatch({ type: 'CLEAR_ADMIN_DATA' });
      setVisible(false);
    }
  }, [isAdminUloading, adminUpdateMsg, isCreditLoading, credits]);

  return (
    <Modal
      closable={false}
      centered
      open={visible}
      onOk={onFinish}
      onCancel={() => setVisible(false)}
      width="600px"
      destroyOnClose={true}
      okText={'Add Credit'}
      okButtonProps={{
        disabled: false,
        loading: false,
      }}
      cancelText={'Cancel'}
    >
      <div className="assign-to-expert-modal-container">
        <div className="assign-to-expert-modal-heading">
          {/* Assign Question Id {rowData?.id} to : {selectedExpertDetail?.first_name} {selectedExpertDetail?.last_name} */}
          Add Credit
        </div>
        <Form layout="vertical" name="basic" form={form} autoComplete="off">
          <Row gutter={30}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="Expert Name" name="expert_id">
                <Input value={rowData?.expertName} defaultValue={rowData?.expertName} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label={'Credit amount'}
                name="credit_amount"
                rules={[
                  { required: true, message: 'Please enter amount' },
                  {
                    pattern: new RegExp(/^[0-9.]{1,10}$/),
                    message: 'Please enter valid amount',
                  },
                ]}
              >
                <InputNumber
                  defaultValue={parseInt(rowData?.credit)}
                  style={{ width: '100%' }}
                  prefix="CAD$"
                  onChange={e => setCredits(e)}
                  maxLength={9}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
