import React, { useState, useEffect } from 'react';
import { Progress } from 'antd';
import Helmet from '../common/helmet';
import QuestionHeader from './questionsHeader';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import Step6 from './step6';
import Step7 from './step7';
import BusinessSector from './businessSector';
import OwnershipType from './ownershipType';
import Invoice from './invoice';
import SubCategory from './subCategory';
import { isLogin, role } from '../../helpers/functions';
import { ROLE_NAME } from '../../helpers/constant';
import { useSelector } from 'react-redux';

const CustomerRequest = props => {
  const navigate = useNavigate();
  const [type, setType] = useState('question-type');
  const [searchParams] = useSearchParams();
  const [percentage, setPercentage] = useState(0);
  const { questionDetail } = useSelector(state => state.common);

  const { qid } = useParams();

  const [stepsCount, setStepsCount] = useState(7);

  useEffect(() => {
    if (questionDetail && questionDetail.expert_id) {
      setStepsCount(6);
    } else {
      setStepsCount(7);
    }
  }, [questionDetail]);

  useEffect(() => {
    if (isLogin() && role() === ROLE_NAME.EXPERT) {
      navigate('/dashboard/expert/request');
    }
  }, []);
  useEffect(() => {
    let tmpType = searchParams.get('type');
    if (tmpType !== null) {
      let tmpType = searchParams.get('type');
      setType(tmpType);
      if (tmpType === 'question-type') {
        calculatePercentage(1, stepsCount);
      } else if (tmpType === 'customer-type') {
        calculatePercentage(2, 8);
      } else if (tmpType === 'business-type') {
        calculatePercentage(4, 8);
      } else if (tmpType === 'ownership') {
      } else if (tmpType === 'category') {
        calculatePercentage(3, stepsCount);
      } else if (tmpType === 'service-type') {
        calculatePercentage(4, stepsCount);
      } else if (tmpType === 'estimated-level') {
        calculatePercentage(5, stepsCount);
      } else if (tmpType === 'location') {
        calculatePercentage(6, stepsCount);
      } else if (tmpType === 'matching-automation') {
        calculatePercentage(7, stepsCount);
      } else if (tmpType === 'invoice') {
        calculatePercentage(5, 8);
      } else if (tmpType === 'main-category') {
        calculatePercentage(6, 8);
      } else if (tmpType === 'sub-category') {
        calculatePercentage(7, 8);
      }
    } else {
      calculatePercentage(1, stepsCount);
    }
  }, [searchParams]);

  const handleNext = (questionId = '', stepType = '', currentStep = '') => {
    if (questionId !== '') {
      if (stepType === 'question-type') {
        navigate(`/request/${questionId}?type=customer-type`);
      } else if (stepType === 'business-type') {
        navigate(`/request/${questionId}?type=business-type`);
      } else if (stepType === 'ownership') {
        navigate(`/request/${questionId}?type=ownership`);
      } else if (stepType === 'category') {
        navigate(`/request/${questionId}?type=category`);
      } else if (stepType === 'service-type') {
        navigate(`/request/${questionId}?type=service-type`);
      } else if (stepType === 'estimated-level') {
        navigate(`/request/${questionId}?type=estimated-level`);
      } else if (stepType === 'location') {
        navigate(`/request/${questionId}?type=location`);
      } else if (stepType === 'matching-automation') {
        navigate(`/request/${questionId}?type=matching-automation`);
      } else if (stepType === 'invoice') {
        navigate(`/request/${questionId}?type=invoice`);
      } else if (stepType === 'main-category') {
        if (currentStep !== '') {
          navigate(`/request/${questionId}?type=main-category&prev=${currentStep}`);
        } else {
          navigate(`/request/${questionId}?type=main-category`);
        }
      } else if (stepType === 'sub-category') {
        if (currentStep !== '') {
          navigate(`/request/${questionId}?type=sub-category&prev=${currentStep}`);
        } else {
          navigate(`/request/${questionId}?type=sub-category`);
        }
      }
    }
  };

  const handleBack = (questionId = '', stepType = '') => {
    if (questionId !== '') {
      if (stepType === 'customer-type') {
        navigate(`/request/${questionId}?type=question-type`);
      } else if (stepType === 'category') {
        navigate(`/request/${questionId}?type=customer-type`);
      } else if (stepType === 'business-type') {
        navigate(`/request/${questionId}?type=customer-type`);
      } else if (stepType === 'ownership') {
        navigate(`/request/${questionId}?type=customer-type`);
      } else if (stepType === 'invoice-ownership') {
        navigate(`/request/${questionId}?type=ownership`);
      } else if (stepType === 'invoice-business') {
        navigate(`/request/${questionId}?type=business-type`);
      } else if (stepType === 'location') {
        navigate(`/request/${questionId}?type=location`);
      } else if (stepType === 'matching-automation') {
        navigate(`/request/${questionId}?type=matching-automation`);
      } else if (stepType === 'invoice') {
        navigate(`/request/${questionId}?type=invoice`);
      } else if (stepType === 'sub-category') {
        navigate(`/request/${questionId}?type=main-category`);
      } else if (stepType === 'service-type') {
        navigate(`/request/${questionId}?type=category`);
      } else if (stepType === 'estimated-level') {
        navigate(`/request/${questionId}?type=service-type`);
      } else if (stepType === 'prev-service-type') {
        navigate(`/request/${questionId}?type=service-type`);
      } else if (stepType === 'prev-invoice') {
        navigate(`/request/${questionId}?type=invoice`);
      }
    }
  };

  const calculatePercentage = (step, totalStep = 8) => {
    let tmpPercentage = (100 * step) / totalStep;
    setPercentage(tmpPercentage);
  };

  const displayStep = () => {
    if (type !== '') {
      if (type === 'question-type') {
        return (
          <Step1
            handleNext={handleNext}
            handleBack={handleBack}
            step={type}
            expertId={searchParams.get('expertId')}
          />
        );
      } else if (type === 'customer-type') {
        return <Step2 handleNext={handleNext} handleBack={handleBack} step={type} />;
      }
      if (type === 'business-type') {
        return <BusinessSector handleNext={handleNext} handleBack={handleBack} step={type} />;
      } else if (type === 'ownership') {
        return <OwnershipType handleNext={handleNext} handleBack={handleBack} step={type} />;
      } else if (type === 'category') {
        return <Step3 handleNext={handleNext} handleBack={handleBack} step={type} />;
      } else if (type === 'service-type') {
        return <Step4 handleNext={handleNext} handleBack={handleBack} step={type} />;
      } else if (type === 'estimated-level') {
        return <Step5 handleNext={handleNext} handleBack={handleBack} step={type} />;
      } else if (type === 'location') {
        return <Step6 handleNext={handleNext} handleBack={handleBack} step={type} />;
      } else if (type === 'matching-automation') {
        return <Step7 handleNext={handleNext} handleBack={handleBack} step={type} />;
      } else if (type === 'invoice') {
        return <Invoice handleNext={handleNext} handleBack={handleBack} step={type} />;
      } else if (type === 'main-category') {
        return (
          <Step3 handleNext={handleNext} handleBack={handleBack} step={type} dependent="sub" />
        );
      } else if (type === 'sub-category') {
        return <SubCategory handleNext={handleNext} handleBack={handleBack} step={type} />;
      }
    } else {
      if (type === 'question-type') {
        return (
          <Step1
            handleNext={handleNext}
            handleBack={handleBack}
            step={type}
            expertId={searchParams.get('expertId')}
          />
        );
      }
    }
  };

  return (
    <div className="question-create">
      <Helmet title="Ask a question" />
      <QuestionHeader />
      <Progress percent={percentage} strokeLinecap="butt" showInfo={false} />
      <div className="profile d-flex-a-c mt30">{displayStep()}</div>
    </div>
  );
};

export default CustomerRequest;
