/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Form, Button, Spin, Radio, Space, Divider } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserData } from '../../helpers/functions';
import { IoMdArrowRoundBack } from 'react-icons/io';

const Step2 = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const { qid } = useParams();
  const [form] = Form.useForm();
  const { isCTLoading, customerTypes, isQuestionLoading, questionDetail } = useSelector(
    state => state.common,
  );
  const { isStep2Loading, step2Success } = useSelector(state => state.steps);
  const [selectedType, setSelectedType] = useState('');

  useEffect(() => {
    dispatch({ type: 'GET_CUSTOMER_TYPE_REQUEST', formData: { status: 'active' } });
    if (qid) {
      dispatch({ type: 'GET_QUESTION_DETAIL', id: qid });
    }
  }, []);

  const onChange = e => {
    setSelectedType(e.target.value);
  };

  useEffect(() => {
    if (!isQuestionLoading && Object.keys(questionDetail).length > 0) {
      setSelectedType(questionDetail.customer_type !== null ? questionDetail.customer_type.id : '');
      form.setFieldsValue({
        customer_type_id:
          questionDetail.customer_type !== null ? questionDetail.customer_type.id : '',
      });
    }
  }, [isQuestionLoading, questionDetail]);

  const onFinish = values => {
    if (qid) {
      dispatch({
        type: 'QUESTION_STEP_2_REQUEST',
        formData: {
          ...values,
          user_id: getUserData().id,
          id: qid,
        },
        requestType: 'post',
      });
    }
  };

  useEffect(() => {
    if (!isStep2Loading && step2Success === 'success') {
      if ([2, 3, 4].includes(selectedType)) {
        handleNext(qid, 'business-type');
      } else if (selectedType === 5) {
        handleNext(qid, 'ownership');
      } else {
        handleNext(qid, 'category');
      }
      dispatch({ type: 'CLEAR_STEPS' });
    }
  }, [isStep2Loading, step2Success, selectedType]);

  return (
    <div>
      <div className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={false}>
          {isCTLoading && <Spin />}
          {!isCTLoading && (
            <>
              <p className="m-title tl mb-7 d-flex-center">
                <span className="back-icon" onClick={() => handleBack(qid, 'customer-type')}>
                  <IoMdArrowRoundBack />
                </span>
                <span>I am (a/an) or representing (a/an)</span>
              </p>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                form={form}
                onFinish={onFinish}
              >
                <Form.Item
                  name="customer_type_id"
                  rules={[{ required: true, message: 'Please select at least one option' }]}
                >
                  <Radio.Group className={'mt3'} style={{ width: '100%' }} onChange={onChange}>
                    <Space direction="vertical" size={'middle'} style={{ width: '100%' }}>
                      {customerTypes.map(item => {
                        return (
                          <>
                            <Radio style={{ fontSize: '18px' }} key={item.id} value={item.id}>
                              {' '}
                              {item.name}
                            </Radio>
                          </>
                        );
                      })}
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Divider className="mt40 mb40" />
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    className="mt5"
                    loading={isStep2Loading}
                    disabled={isStep2Loading}
                  >
                    Next
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </Card>
      </div>
    </div>
  );
};
export default Step2;
