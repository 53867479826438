/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Form, Button, Spin, Radio, Space } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getUserData } from '../../helpers/functions';
import { IoMdArrowRoundBack } from 'react-icons/io';

const Step3 = ({ handleNext, dependent = '', handleBack }) => {
  const dispatch = useDispatch();
  const { qid } = useParams();
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const { isSCLoading, serviceCategoryList, isQuestionLoading, questionDetail } = useSelector(
    state => state.common,
  );
  const { isStepUpdating, stepUpdateMsg } = useSelector(state => state.steps);
  const [selectedType, setSelectedType] = useState('general-question');

  useEffect(() => {
    dispatch({ type: 'GET_SERVICE_CATEGORY_REQUEST', formData: { status: 'active' } });
    if (qid) {
      dispatch({ type: 'GET_QUESTION_DETAIL', id: qid });
    }
  }, []);

  const onChange = e => {
    setSelectedType(e.target.value);
  };

  useEffect(() => {
    if (!isQuestionLoading && Object.keys(questionDetail).length > 0) {
      const categoryId = questionDetail.category !== null ? questionDetail.category?.id : '';
      form.setFieldsValue({ category_id: categoryId });
    }
  }, [isQuestionLoading, questionDetail]);

  const onFinish = values => {
    if (qid) {
      dispatch({
        type: 'QUESTION_STEP_UPDATE_REQUEST',
        formData: {
          ...values,
          user_id: getUserData().id,
          id: qid,
        },
        requestType: 'post',
        step: 3,
      });
    }
  };

  useEffect(() => {
    if (!isStepUpdating && stepUpdateMsg === 'success') {
      if (dependent === 'sub') {
        handleNext(qid, questionDetail.customer_type.name === 'Individuals'?'location':'sub-category');
        dispatch({ type: 'CLEAR_STEPS' });
      } else {
        handleNext(qid, questionDetail.customer_type.name === 'Individuals'?'location':'service-type');
        dispatch({ type: 'CLEAR_STEPS' });
      }
    }
  }, [isStepUpdating, stepUpdateMsg]);

  return (
    <div>
      <div className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={false}>
          {isSCLoading && <Spin />}
          {!isSCLoading && (
            <>
              <p className="m-title tl mb-7 d-flex-center">
                <span
                  className="back-icon"
                  onClick={() => {
                    if (
                      searchParams.get('prev') !== null &&
                      searchParams.get('prev') === 'invoice'
                    ) {
                      handleBack(qid, 'prev-invoice');
                    } else {
                      handleBack(qid, 'category');
                    }
                  }}
                >
                  <IoMdArrowRoundBack />
                </span>
                <span>Service category</span>
              </p>

              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                form={form}
                onFinish={onFinish}
              >
                <Form.Item
                  name="category_id"
                  rules={[{ required: true, message: 'Please select at least one option' }]}
                >
                  <Radio.Group className={'mt3'} style={{ width: '100%' }} onChange={onChange}>
                    <Space direction="vertical" size={'middle'} style={{ width: '100%' }}>
                      {serviceCategoryList.map(item => {
                        return (
                          <>
                            <Radio style={{ fontSize: '18px' }} key={item.id} value={item.id}>
                              {' '}
                              {item.name}
                            </Radio>
                          </>
                        );
                      })}
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    className="mt5"
                    disabled={isStepUpdating}
                    loading={isStepUpdating}
                  >
                    Next
                  </Button>
                </Form.Item>
              </Form>

              {/* <Form
                                layout="vertical"
                                name="basic"
                                hideRequiredMark={true}
                                autoComplete="off"
                            >
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="float-r"
                                        size="middle"
                                    >
                                        Next
                                    </Button>
                                </Form.Item>
                            </Form> */}
            </>
          )}
        </Card>
      </div>
    </div>
  );
};
export default Step3;
