import { Avatar, Col, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { formatDateBlog, shareOnSocial } from '../../helpers/functions';
import { AiFillLinkedin, AiOutlineTwitter } from 'react-icons/ai';
import { RiFacebookFill } from 'react-icons/ri';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { API_URL, FACEBOOK_SHARE_URL } from '../../helpers/constant';
import useResponsive from '../../hooks/useBreakpoint';
import Helmet from './helmet';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
const BlogBox = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const blogFromRedux = useSelector(state => state?.common?.singleBlog);
  const isBlogLoading = useSelector(state => state?.common?.isBlogLoading);
  const [blog, setBlog] = useState({});
  useEffect(() => {
    const handlePopState = () => {
      console.log('User navigated using browser buttons.');
    };
    dispatch({ type: 'GET_SINGLE_BLOG', id: null });
  }, [navigate])
  useEffect(() => {
    dispatch({ type: 'GET_SINGLE_BLOG', id: location?.state || location?.pathname?.split('/')[2] });
  }, [location.state]);
  useEffect(() => {
    setBlog(blogFromRedux);
  }, [blogFromRedux]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'GET_BLOG_ERROR' });
    };
  }, []);

  const breakpoint = useResponsive();

  const encodedTitle = encodeURIComponent('Accountizer - ' + blog?.title);
  const encodedBody = encodeURIComponent(blog?.body?.replace(/<[^>]*>/g, '').length > 150 ? blog?.body?.replace(/<[^>]*>/g, '').substring(0, 150) + '...' : blog?.body?.replace(/<[^>]*>/g, ''));
  
  function extractYouTubeVideoId(url) {
    if (!url || typeof url !== 'string') {
      return null; // Handle invalid input
    }
  
    // Regular expression to match various YouTube URL formats
    const regExp = /^.*(youtu\.be\/|v\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
  
    if (match && match[2].length === 11) {
      return match[2]; // Return the video ID
    }
  
    return null; // Return null if no valid ID is found
  }
  return (
    <>
      <Helmet
        title={blog?.title}
        desc={blog?.body ? `${blog?.body?.replace(/<[^>]*>/g, '').length > 150 ? blog?.body?.replace(/<[^>]*>/g, '').substring(0, 150) + '...' : blog?.body?.replace(/<[^>]*>/g, '')}` : ''}
        type={"article"}
        image={blog?.featured_image}
      />
      <Row
        style={{
          minHeight: '100vh',
          backgroundColor: 'rgba(255,255,255)',
          padding:
            breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' ? '1.5rem' : '5rem',
        }}
      >
        {Object.keys(blog).length > 0 ? (
          <>
            <Col xs={24} lg={12}
              style={{

                height: breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' ? "unset" : "100vh",
                position: breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' ? "unset" : "sticky",
                top: '0',
                marginBottom: breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' ? "unset" : "25%"
              }}>
              <ArrowLeftOutlined
                style={{ fontSize: '1.5rem', color: 'rgb(74,127,234)' }}
                onClick={() => navigate('/blogs')}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  height: breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' ? "unset" : "100%",


                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: "80%"
                  }}
                >
                  {blog?.youtube_video_url !== null ? (
                    <>
                    <div className="youtube_video_box"
                      style={{
                        width: '90%',
                      }}
                    >
                      <iframe height="500" style={{
                        width: '100%',
                        border:'none'
                      }}
                        src={`https://www.youtube.com/embed/${extractYouTubeVideoId(blog?.youtube_video_url)}`}>
                      </iframe>
                    </div>
                    </>
                  ):(
                    <>
                      <img
                        style={{
                          width: '90%',
                          objectFit: 'cover',
                          // height: '80%',
                          boxShadow:
                            'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 10px',
                        }}
                        src={blog?.featured_image}
                        // src={'https://picsum.photos/200/300'}
                        alt="Blog"
                        loading="lazy"
                      />
                    </>
                  )}
                  <Row style={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>
                    <Col
                      xs={24}
                      lg={12}
                      style={{ display: 'flex', paddingRight: '1rem', alignItems: 'center' }}
                      className=""
                    >
                      <p style={{ marginTop: '1rem', color: 'rgb(74,127,234)' }}>Share This: </p>
                      <span className="mr10 ml20">
                        <TwitterShareButton url={`${API_URL}blogshare?title=${encodedTitle}&desc=${encodedBody}&image=${blog?.featured_image}&type=article&location=${window.location.href}`} >
                          {/* <AiOutlineTwitter
                            // onClick={() =>
                            //   shareOnSocial('twitter', blog?.title, window.location.href)
                            // }
                            className="cursor-p"
                            size={22}
                            color="#6B7280"
                          /> */}
                          <img
                            style={{ width: '22px' }}
                            className="cursor-p"
                            src={require('../../assets/images/Dark_Gray_twitter_logo.png')}
                            alt="twitter"
                            loading='lazy'
                          />

                        </TwitterShareButton>
                      </span>
                      <span className="mr10">
                        <FacebookShareButton
                          url={`${API_URL}blogshare?title=${encodedTitle}&desc=${encodedBody}&image=${blog?.featured_image}&type=article&location=${window.location.href}`}

                        >
                          <RiFacebookFill
                            className="cursor-p"
                            size={22}
                            color="#2D72F5"
                          // onClick={() =>
                          //   shareOnSocial('facebook', blog?.title, window.location.href)
                          // }
                          />

                        </FacebookShareButton>
                      </span>
                      <span>
                        <LinkedinShareButton
                          url={`${API_URL}blogshare?title=${encodedTitle}&desc=${encodedBody}&image=${blog?.featured_image}&type=article&location=${window.location.href}`}
                        >
                          <AiFillLinkedin
                            className="cursor-p"
                            size={22}
                            color="#2D72F5"
                          // onClick={() =>
                          //   shareOnSocial('linkedin', blog?.title, window.location.href)
                          // }
                          />
                        </LinkedinShareButton>
                      </span>
                    </Col>
                    <Col
                      xs={24}
                      lg={12}
                      className=" mt10"
                      style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '1rem' }}
                    >
                      {!!blog?.tags?.length &&
                        blog?.tags?.map((tag, index) => (
                          <>
                            <span className="tag mr10" key={index}>
                              #{tag?.name}
                            </span>
                          </>
                        ))}
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>{' '}
            <Col
              xs={24}
              lg={12}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
            >
              <h1 style={{ color: 'black' }}>{blog?.title}</h1>
              <div
                style={{ display: 'flex', gap: '1rem', alignItems: 'center', marginBottom: '3rem' }}
              >
                {blog?.author_image && (
                  <span>
                    <Avatar size="large" style={{ backgroundColor: 'transparent' }}>
                      <img src={blog?.author_image} alt="avatar" loading="lazy" />
                    </Avatar>
                  </span>
                )}
                {blog?.author_name && <strong>{blog?.author_name}</strong>}
                <span style={{ color: '#6b7280' }}>{formatDateBlog(blog?.created_at)}</span>
              </div>
              <p
                style={{
                  textAlign: 'start',
                  lineHeight: '2rem',
                  fontSize: 'clamp(.8rem, 2vw, 1.2rem)',
                }}
                dangerouslySetInnerHTML={{ __html: blog?.body.replace(/style="[^"]*"/g, '') }}
              ></p>
            </Col>
          </>
        ) : (
          isBlogLoading && <Spin />
        )}
      </Row>
    </>
  );
};

export default BlogBox;
