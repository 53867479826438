import React, { useState, useEffect } from 'react';
import {
  Progress,
  Form,
  Card,
  Divider,
  Button,
  Input,
  Row,
  Col,
  message,
  Radio,
  Upload,
  Checkbox,
  Tooltip,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import Helmet from '../common/helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import QuestionHeader from '../../components/customer/questionsHeader';
import Password from '../common/password';
import VerifiedOTPModal from '../common/modal/verifiedOTPModal';
import FileUpload from '../common/upload';
import TextArea from 'antd/es/input/TextArea';
import { SignupCaution } from '../common/signupCaution';
import { beforeUpload } from '../../helpers/beforeUpload';
import { IframPdfModal } from '../common/modal/iframPdfModal';
import Privacy_policy from '../../assets/documents/Privacy_policy.pdf';
import Service_provider_agreement from '../../assets/documents/Service_provider_agreement.pdf';
import CustomSelect from '../common/customSelect';
import { role } from '../../helpers/functions';
import { ROLE_NAME } from '../../helpers/constant';
const ExpertSignup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { locationList, docURL, isLocationLoading } = useSelector(state => state.common);
  const { isSignupLoading, signupStatus, signupData, isLoginLoading, loginMsg } = useSelector(state => state.auth);

  const { imageUrlFromDB, isImageUploadingToDBLoading, isImageUploadFailed } = useSelector(
    state => state.requests,
  );

  useEffect(() => {
    if (imageUrlFromDB) {
      form.setFieldsValue({ avatar: imageUrlFromDB });
    }
  }, [imageUrlFromDB]);

  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const param_email = query.get('email');
  const param_first_name = query.get('first_name');
  const param_last_name = query.get('last_name');

  const [showModal, setShowModal] = useState(false);
  const [signupEmail, setSignupEmail] = useState('');
  const [fileName, setFileName] = useState('');
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [showServiceProviderModal, setShowServiceProviderModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [fileList, setFileList] = useState([]);

  const isParamExists = () => {
    return (param_email && param_first_name) ? true : false
  }

  const optionsList = tmpLocation => {
    let locationOptions = [];
    if (tmpLocation.length > 0) {
      tmpLocation.map(item => {
        return locationOptions.push({
          value: item.id,
          label: item.name,
        });
      });
    }
    return locationOptions;
  };
  const handleChange = info => {
    // Has to be set even when it's still uploading otherwise it doesn't trigger the 'done' event
    setFileList(info.fileList);
    if (info.file.status === 'done') {
      const formData = new FormData();
      formData.append('file', info.file.originFileObj);
      dispatch({ type: 'UPLOAD_IMAGE_TO_DB', formData: formData });
    } else if (info.file.status === 'removed') {
      form.setFieldsValue({ avatar: '' });
    }
  };

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  useEffect(() => {
    if (locationList.length === 0) {
      dispatch({ type: 'GET_LOCATION_REQUEST', formData: { status: 'active' } });
    }
  }, []);

  // Handle image upload being set automatically by ant upload even if it is not done
  useEffect(() => {
    if (isImageUploadingToDBLoading) {
      const FILE_LIST_UPLOADING = fileList.map(file => {
        file.status = 'uploading';
        return file;
      });
      setFileList(FILE_LIST_UPLOADING);
    } else {
      if (fileList.length > 0) {
        const FILE_LIST_DONE = fileList.map(file => {
          file.status = 'done';
          return file;
        });
        setFileList(FILE_LIST_DONE);
      }
    }
    if (isImageUploadFailed) {
      setFileList([]);
      message.error('Something went wrong during upload. Please try again.');
    }
  }, [isImageUploadFailed, isImageUploadingToDBLoading]);

  const onFinish = values => {
    let tmpObj = { ...values };
    tmpObj.location_ids = [values.location_ids];

    if (docURL !== '') {
      tmpObj.resume = docURL;
    }

    if (values.linkedin !== undefined && values.linkedin !== '') {
      let linkedInValue = values.linkedin;
      if (linkedInValue.startsWith('linkedin')) {
        linkedInValue = `https://www.${linkedInValue}`;
      }
      tmpObj.linkedin = linkedInValue;
    } else {
      delete tmpObj.linkedin;
    }
    tmpObj.role = 'expert';
    if (tmpObj.avatar !== undefined && tmpObj.avatar !== '') {
      tmpObj.avatar = imageUrlFromDB;
    } else {
      delete tmpObj.avatar;
    }
    if (isParamExists()) {
      dispatch({ type: 'SIGNUP_REQUEST_FROM_GOOGLE', formData: tmpObj, signupType: 'expert' });

    } else {
      dispatch({ type: 'SIGNUP_REQUEST', formData: tmpObj, signupType: 'expert' });

    }
  };

  useEffect(() => {
    if (!isLoginLoading && loginMsg === 'success') {
      dispatch({ type: 'CLEAR_LOGIN_DATA' });
      if (localStorage.getItem('redirectLink')) {
        let tmoURL = localStorage.getItem('redirectLink');
        localStorage.removeItem('redirectLink');
        window.location.href = tmoURL;
      } else {
        if (role() === ROLE_NAME.ADMIN) {
          window.location.href = '/admin/requests';
        } else if (role() === ROLE_NAME.EXPERT) {
          window.location.href = '/dashboard/expert/request';
        } else {
          window.location.href = '/dashboard/customer';
        }
      }
    } else if (!isLoginLoading && loginMsg === 'not-verified') {
      message.error('Please verified your email address');
    }
  }, [isLoginLoading, loginMsg]);

  useEffect(() => {
    if (!isSignupLoading && signupStatus === 'error' && Object.keys(signupData).length > 0) {
      if (typeof signupData === 'string') {
        message.error({ content: 'Something went wrong please try again.', className: 'error-class', duration: 4 });
      } else {
        for (const property in signupData) {
          message.error({
            content: signupData[property][0] || 'Something went wrong',
            className: 'error-class',
            duration: 4,
          });
        }
      }
      dispatch({ type: 'CLEAR_LOGIN_DATA' });
    } else if (
      !isSignupLoading &&
      signupStatus === 'success' &&
      Object.keys(signupData).length > 0
    ) {
      setSignupEmail(signupData.email);
      setShowModal(true);
      form.resetFields([
        'avatar',
        'first_name',
        'last_name',
        'organization',
        'email',
        'bio',
        'authorized',
        'linkedin',
        'file_upload',
        'c_password',
      ]);

      // navigate('/login?type=expert')
      dispatch({ type: 'CLEAR_LOGIN_DATA' });
    }
  }, [isSignupLoading, signupStatus, signupData, isImageUploadingToDBLoading]);

  return (
    <div className="question-create">
      <Helmet title="Expert Sign Up" />
      <QuestionHeader type="Expert Signup" />
      <Progress
        strokeColor={{
          '0%': '#1C64F2',
          '100%': '#1C64F2',
        }}
        trailColor={{
          '0%': '#1C64F2',
          '100%': '#1C64F2',
        }}
        percent={100}
        strokeLinecap="butt"
        showInfo={false}
      />
      <div className="profile d-flex-a-c mt30 mb30">
        <div>
          <div  className="site-card-border-less-wrapper card-wrapper">
            <Card bordered={false}>
              {/* <Spin /> */}

              <>
                <p className="m-title tl mb-7">Tell us about your self</p>

                <Form
                  layout="vertical"
                  name="basic"
                  form={form}
                  onFinish={onFinish}
                  autoComplete="off"
                  initialValues={{
                    first_name: param_first_name || '',
                    last_name: param_last_name || '',
                    email: param_email || '',
                  }}
                >
                  <Row gutter={30}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        label="Upload Photo"
                        name="avatar"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          overflow: 'hidden',
                        }}
                        rules={[{ required: true, message: 'Please upload your photo' }]}
                      >
                        <ImgCrop rotationSlider>
                          <Upload
                            progress
                            accept="image/svg,image/gif,image/png,image/bmp,image/jpeg"
                            beforeUpload={beforeUpload}
                            fileList={fileList}
                            name="attachment"
                            listType="picture-card"
                            onChange={handleChange}
                            onPreview={onPreview}
                            customRequest={({ file, onSuccess }) =>
                              setTimeout(() => onSuccess('ok'), 0)
                            }

                          >
                            {fileList.length < 1 && '+ Upload'}
                          </Upload>
                        </ImgCrop>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item
                        label="First Name"
                        name="first_name"
                        className="form-field"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter first name',
                          },
                          {
                            pattern: new RegExp(/^[a-zA-Z0-9_ ]*$/),
                            message: 'Please enter valid characters',
                          },
                          {
                            max: 100,
                            message: 'Maximum 100 characters are allowed',
                          },
                        ]}
                      >
                        <Input size="large" placeholder="First Name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item
                        label="Last Name"
                        name="last_name"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter last name',
                          },
                          {
                            pattern: new RegExp(/^[a-zA-Z0-9_ ]*$/),
                            message: 'Please enter valid characters',
                          },
                          {
                            max: 100,
                            message: 'Maximum 100 characters are allowed',
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Last Name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        label="Business Name"
                        name="organization"
                        rules={[
                          {
                            max: 100,
                            message: 'Maximum 100 characters are allowed',
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Business Name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        label="Email address"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter email address',
                          },
                          {
                            type: 'email',
                            message: 'Please enter valid email address',
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Email" disabled={isParamExists()} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        label="Biography"
                        name="bio"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter your biography',
                          },
                          {
                            max: 2000,
                            message: 'Maximum 2000 characters are allowed',
                          },
                        ]}
                      >
                        <TextArea
                          rows={6}
                          size="large"
                          placeholder="Already have a biography? Paste it here!"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        label="Are you authorized to work in Canada?"
                        name="authorized"
                        rules={[
                          {
                            required: true,
                            message: 'Please select the option',
                          },
                        ]}
                      >
                        <Radio.Group buttonStyle="solid">
                          <Radio.Button value={1}>Yes</Radio.Button>
                          <Tooltip color='#1c64f2' placement="right" title="We apologize for any inconvenience, but for the time being, we are only accepting applications from candidates who are authorized to work in Canada.">
                            <Radio.Button disabled value={0}>No</Radio.Button>
                          </Tooltip>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        label="Location"
                        name="location_ids"
                        rules={[
                          {
                            required: true,
                            message: 'Please select a location',
                          },
                        ]}
                      >
                        <CustomSelect
                          placeholder="Select an option"
                          style={{
                            width: '100%',
                          }}
                          defaultValue={locationList?.length ? locationList[0]?.id : null}
                          size="large"
                          showSearch
                          allowClear
                          loading={isLocationLoading}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={optionsList(locationList)}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        label="LinkedIn Profile"
                        name="linkedin"
                        rules={[
                          {
                            required: false,
                            message: 'Please enter URL',
                          },
                          {
                            pattern: /^(?:(https|http):\/\/(www\.){0,1})?linkedin\.com\/.*$/,
                            message: 'This field must be a valid linkedin url.',
                          },
                        ]}
                      >
                        <Input size="large" placeholder="https://www.linkedin.com/in/" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        name="file_upload"
                        label="Upload Resume"
                        rules={[
                          {
                            required: false,
                            message: 'Please upload your resume',
                          },
                          {
                            type: 'doc',
                            message: 'This field must be a valid url.',
                          },
                        ]}
                      >
                        <FileUpload
                          uploadImageURL={docURL}
                          setfileName={setFileName}
                          type="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        />
                      </Form.Item>
                    </Col>
                    {
                      !isParamExists() &&
                      <>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Password />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Form.Item
                            label="Confirm Password"
                            name="c_password"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: 'Please confirm your password',
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error('The two passwords that you entered do not match!'),
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input.Password size="large" placeholder="••••••••••" />
                          </Form.Item>
                        </Col>
                      </>
                    }
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item>
                        <div className="d-flex mt10" style={{ gap: '10px', alignItems: 'start' }}>
                          <Checkbox onChange={e => setIsTermsChecked(e.target.checked)} />
                          <p style={{ display: 'flex' }}>
                            I have read and agree to &nbsp;
                            <span
                              style={{
                                textDecoration: 'underline',
                                fontWeight: '700',
                                cursor: 'pointer',
                              }}
                              onClick={() => setShowServiceProviderModal(!showServiceProviderModal)}
                            >
                              Service Provider Agreement.
                            </span>
                            <span>&nbsp;&nbsp;</span>
                            <span
                              style={{
                                textDecoration: 'underline',
                                fontWeight: '700',
                                cursor: 'pointer',
                              }}
                              onClick={() => setShowPrivacyModal(!showPrivacyModal)}
                            >
                              Privacy Policy.
                            </span>
                          </p>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <SignupCaution /> */}
                  <Divider className="mt40 mb40" />
                  <Form.Item>
                    <div style={{ textAlign: 'center', width: '100%' }}>
                      <Button
                        type="secondary"
                        htmlType="button"
                        size="large"
                        className="mt5"
                        onClick={() => navigate('/')}
                      >
                        Cancel
                      </Button>
                      <Button
                        htmlType="submit"
                        size="large"
                        className="mt5"
                        loading={isSignupLoading}
                        type={isTermsChecked ? 'primary' : 'default'}
                        disabled={isSignupLoading || !isTermsChecked}
                      >
                        Sign Up
                      </Button>
                    </div>
                  </Form.Item>
                </Form>

                {/* <Form
                                layout="vertical"
                                name="basic"
                                hideRequiredMark={true}
                                autoComplete="off"
                            >
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="float-r"
                                        size="middle"
                                    >
                                        Next
                                    </Button>
                                </Form.Item>
                            </Form> */}
              </>
            </Card>
          </div>
        </div>
      </div>
      {showModal && (
        <VerifiedOTPModal
          pageType="expert"
          registerEmail={signupEmail}
          visible={showModal}
          setVisible={() => {
            setShowModal(false);
          }}
        />
      )}
      <IframPdfModal
        visible={showPrivacyModal}
        setShow={setShowPrivacyModal}
        title="Accountizer's Privacy Policy"
        file={Privacy_policy}
      />
      <IframPdfModal
        visible={showServiceProviderModal}
        setShow={setShowServiceProviderModal}
        title="Accountizer's Service Provider Agreement"
        file={Service_provider_agreement}
      />
    </div>
  );
};

export default ExpertSignup;
