/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Form, Button, Spin, Radio, Space, Checkbox } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserData } from '../../../helpers/functions';
import { IoMdArrowRoundBack } from 'react-icons/io';

const Languages = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const { eid } = useParams();
  const [form] = Form.useForm();
  const { isLangLoading, languageList } = useSelector(state => state.common);
  const { isExpertUpdating, stepExpertMsg } = useSelector(state => state.steps);
  const { isExpertInfoLoading, expertDetails } = useSelector(state => state.expert);

  useEffect(() => {
    if (eid || getUserData().id) {
      dispatch({ type: 'EXPERT_DETAIL_REQUEST', id: eid || getUserData().id });
    }
    dispatch({ type: 'GET_LANGUAGE_REQUEST', formData: { status: 'active' } });
  }, []);

  const onChange = e => { };

  useEffect(() => {
    if (
      !isExpertInfoLoading &&
      Object.keys(expertDetails).length > 0 &&
      !isLangLoading &&
      languageList.length > 0
    ) {
      let tmpIds =
        expertDetails.languages !== null && expertDetails.languages.length
          ? expertDetails.languages.map(i => i.id)
          : [];
      form.setFieldsValue({ language_ids: tmpIds });
    }
  }, [isExpertInfoLoading, expertDetails, isLangLoading, languageList]);

  const onFinish = values => {
    dispatch({
      type: 'EXPERT_STEP_UPDATE_REQUEST',
      formData: {
        ...values,
        expert_id: eid || getUserData().id,
      },
      requestType: 'put',
      step: 'language',
    });
  };

  useEffect(() => {
    if (!isExpertUpdating && stepExpertMsg === 'success') {
      handleNext('experience');
      dispatch({ type: 'CLEAR_STEPS' });
    }
  }, [isExpertUpdating, stepExpertMsg]);


  return (
    <div>
      <div className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={false}>
          {isLangLoading && <Spin />}
          {!isLangLoading && (
            <>
              <p className="m-title tl mb-7 d-flex-center">
                <span className="back-icon" onClick={() => handleBack('language')}>
                  <IoMdArrowRoundBack />
                </span>
                <span>Language I speak</span>
              </p>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                form={form}
                onFinish={onFinish}
              >
                <Form.Item
                  name="language_ids"
                  rules={[{ required: true, message: 'Please select location' }]}
                >
                  <Checkbox.Group className={'mt3'} style={{ width: '100%' }} onChange={onChange}>
                    <Space direction="vertical" size={'middle'} style={{ width: '100%' }}>
                      {!isLangLoading &&
                        languageList.map(item => {
                          return (
                            <>
                              <Checkbox key={item.id} value={item.id}>
                                {' '}
                                {item.name}
                              </Checkbox>
                            </>
                          );
                        })}
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    className="mt-5"
                    disabled={isExpertUpdating}
                    loading={isExpertUpdating}
                  >
                    Next
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </Card>
      </div>
    </div>
  );
};
export default Languages;
