const initialState = {
  isRequestLoading: false,
  requestsList: [],

  isReviewLoading: false,
  reviewsList: [],

  isDeleteLoading: false,
  deleteMsg: '',

  isDeleteReviewLoading: false,
  deleteReviewMsg: '',

  isDeleteUserLoading: false,
  deleteUserMsg: '',

  isAdminUloading: false,
  adminUpdateMsg: '',

  isAdminDeleteCreditsLoading: false,
  adminCreditsDeleteMsg: '',

  isAdminDeleteLicensesAndCertificateLoading: false,
  adminLicensesAndCertificateDeleteMsg: '',

  isAdminDeleteExperienceLoading: false,
  adminExperienceDeleteMsg: '',

  isAdminDeleteServicesAndRateLoading: false,
  adminServicesAndRateDeleteMsg: '',

  isAdminDeleteDegreeLoading: false,
  adminDegreeDeleteMsg: '',
};

const AuthReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /*------------ */
    case 'ADMIN_REQUESTS_REQUEST':
      state = {
        ...state,
        isRequestLoading: true,
        requestsList: [],
      };
      break;

    case 'ADMIN_REQUESTS_SUCCESS':
      state = {
        ...state,
        isRequestLoading: false,
        requestsList: action.data,
      };
      break;

    case 'ADMIN_REQUESTS_ERROR':
      state = {
        ...state,
        isRequestLoading: false,
        requestsList: [],
      };
      break;

    /*------------ */
    case 'ADMIN_REVIEWS_REQUEST':
      state = {
        ...state,
        isReviewLoading: true,
        reviewsList: [],
      };
      break;

    case 'ADMIN_REVIEWS_SUCCESS':
      state = {
        ...state,
        isReviewLoading: false,
        reviewsList: action.data,
      };
      break;

    case 'ADMIN_REVIEWS_ERROR':
      state = {
        ...state,
        isReviewLoading: false,
        reviewsList: [],
      };
      break;

    /*------------ */
    case 'ADMIN_DELETE_REQUEST':
      state = {
        ...state,
        isDeleteLoading: true,
        deleteMsg: '',
      };
      break;

    case 'ADMIN_DELETE_SUCCESS':
      state = {
        ...state,
        isDeleteLoading: false,
        deleteMsg: 'success',
      };
      break;

    case 'ADMIN_DELETE_ERROR':
      state = {
        ...state,
        isDeleteLoading: false,
        deleteMsg: '',
      };
      break;

    /*------------ */
    case 'ADMIN_DELETE_REVIEW':
      state = {
        ...state,
        isDeleteReviewLoading: true,
        deleteReviewMsg: '',
      };
      break;

    case 'ADMIN_DELETE_REVIEW_SUCCESS':
      state = {
        ...state,
        isDeleteReviewLoading: false,
        deleteReviewMsg: 'success',
      };
      break;

    case 'ADMIN_DELETE_REVIEW_ERROR':
      state = {
        ...state,
        isDeleteReviewLoading: false,
        deleteReviewMsg: '',
      };
      break;

    /*------------ */
    case 'ADMIN_DELETE_USER':
      state = {
        ...state,
        isDeleteUserLoading: true,
        deleteUserMsg: '',
      };
      break;

    case 'ADMIN_DELETE_USER_SUCCESS':
      state = {
        ...state,
        isDeleteUserLoading: false,
        deleteUserMsg: 'success',
      };
      break;

    case 'ADMIN_DELETE_USER_ERROR':
      state = {
        ...state,
        isDeleteUserLoading: false,
        deleteUserMsg: '',
      };
      break;

    /*------------ */
    case 'ADMIN_ADD_UPDATE_REQUEST':
      state = {
        ...state,
        isAdminUloading: true,
        adminUpdateMsg: '',
      };
      break;

    case 'ADMIN_ADD_UPDATE_SUCCESS':
      state = {
        ...state,
        isAdminUloading: false,
        adminUpdateMsg: 'success',
      };
      break;

    case 'ADMIN_ADD_UPDATE_ERROR':
      state = {
        ...state,
        isAdminUloading: false,
        adminUpdateMsg: '',
      };
      break;

    /*------------ */
    case 'ADMIN_DELETE_CREDITS':
      state = {
        ...state,
        isAdminDeleteCreditsLoading: true,
        adminCreditsDeleteMsg: '',
      };
      break;

    case 'ADMIN_DELETE_CREDITS_SUCCESS':
      state = {
        ...state,
        isAdminDeleteCreditsLoading: false,
        adminCreditsDeleteMsg: 'Credits deleted successfully',
      };
      break;

    case 'ADMIN_DELETE_CREDITS_ERROR':
      state = {
        ...state,
        isAdminDeleteCreditsLoading: false,
        adminCreditsDeleteMsg: 'Error deleting credits',
      };
      break;

    /*------------ */
    case 'ADMIN_DELETE_LICENSE_AND_CERTIFICATE':
      state = {
        ...state,
        isAdminDeleteLicensesAndCertificateLoading: true,
        adminLicensesAndCertificateDeleteMsg: '',
      };
      break;

    case 'ADMIN_DELETE_LICENSE_AND_CERTIFICATE_SUCCESS':
      state = {
        ...state,
        isAdminDeleteLicensesAndCertificateLoading: false,
        adminLicensesAndCertificateDeleteMsg: 'License Detail deleted successfully',
      };
      break;

    case 'ADMIN_DELETE_LICENSE_AND_CERTIFICATE_ERROR':
      state = {
        ...state,
        isAdminDeleteLicensesAndCertificateLoading: false,
        adminLicensesAndCertificateDeleteMsg: 'Error Deleting License Detail',
      };
      break;

    /*------------ */
    case 'ADMIN_DELETE_EXPERIENCE':
      state = {
        ...state,
        isAdminDeleteExperienceLoading: true,
        adminExperienceDeleteMsg: '',
      };
      break;

    case 'ADMIN_DELETE_EXPERIENCE_SUCCESS':
      state = {
        ...state,
        isAdminDeleteExperienceLoading: false,
        adminExperienceDeleteMsg: 'Experience Details Deleted Successfully',
      };
      break;

    case 'ADMIN_DELETE_EXPERIENCE_ERROR':
      state = {
        ...state,
        isAdminDeleteExperienceLoading: false,
        adminExperienceDeleteMsg: 'Error Deleting Experience Details',
      };
      break;

    /*------------ */
    case 'ADMIN_DELETE_SERVICES_AND_RATE':
      state = {
        ...state,
        isAdminDeleteServicesAndRateLoading: true,
        adminServicesAndRateDeleteMsg: '',
      };
      break;

    case 'ADMIN_DELETE_SERVICES_AND_RATE_SUCCESS':
      state = {
        ...state,
        isAdminDeleteServicesAndRateLoading: false,
        adminServicesAndRateDeleteMsg: 'Services And Rate Deleted Successfully',
      };
      break;

    case 'ADMIN_DELETE_SERVICES_AND_RATE_ERROR':
      state = {
        ...state,
        isAdminDeleteServicesAndRateLoading: false,
        adminServicesAndRateDeleteMsg: 'Error Deleting Services And Rate',
      };
      break;

    case 'ADMIN_DELETE_DEGREE':
      state = {
        ...state,
        isAdminDeleteDegreeLoading: true,
        adminDegreeDeleteMsg: '',
      };
      break;

    case 'ADMIN_DELETE_DEGREE_SUCCESS':
      state = {
        ...state,
        isAdminDeleteDegreeLoading: false,
        adminDegreeDeleteMsg: 'Degree Deleted Successfully',
      };
      break;

    case 'ADMIN_DELETE_DEGREE_ERROR':
      state = {
        ...state,
        isAdminDeleteDegreeLoading: false,
        adminDegreeDeleteMsg: 'Error Deleting Degree',
      };
      break;

    case 'CLEAR_ADMIN_DATA':
      state = {
        ...state,
        deleteMsg: '',
        adminUpdateMsg: '',
      };
      break;
    default:
  }
  return state;
};

export default AuthReducer;
