import React, { useEffect, useState } from 'react';
import RequestCard from '../common/requestCard';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Spin } from 'antd';

export const RequestList = () => {
  const dispatch = useDispatch();
  const { isRequestLoading, requests, isUpdateQuestionStatusLoading } = useSelector(
    state => state.requests,
  );
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // dispatch({ type: 'GET_REQUESTS', formData: { type: "request" } })
    dispatch({ type: 'GET_REQUESTS', formData: {} });
  }, [dispatch, isUpdateQuestionStatusLoading]);

  useEffect(() => {
    if (requests?.data?.length) {
      const filterData = requests?.data?.filter(
        el => el.status?.question_status_id !== 10 && el.status?.question_status_id !== 11,
      );
      setFilteredData(filterData);
    }
  }, [dispatch, isUpdateQuestionStatusLoading, filteredData.length, requests?.data]);

  const navigate = useNavigate();

  const handleOnRequestClick = data => {
    navigate(`/my-request/${data.id}`, {
      state: data,
    });
  };
  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;


  return (
    <div>
      <div className="d-flex" style={{ textAlign: 'center', justifyContent: 'space-between' }}>
        <p className="requests-title">My Requests</p>
        <div >
          <Button size="large" onClick={() => navigate('/')}>
            {' '}
            + Submit a new request
          </Button>
          {!filteredData?.length && !isRequestLoading &&
            <div class="cursorBlink">👆
              <br />
              <span style={{ fontSize: '14px' }}>
                Please make your first request
              </span>
            </div>}
        </div>
      </div>
      {isRequestLoading ? (
        <div
          style={{ width: (width * 80) / 100, height: (height * 50) / 100 }}
          className="d-flex-a-c"
        >
          <Spin />
        </div>
      ) : !filteredData?.length ? (
        <div
          style={{ width: (width * 80) / 100, height: (height * 50) / 100 }}
          className="d-flex-a-c"
        >
          <p style={{ textAlign: 'center', fontSize: '18px' }}>
            No Request Found ! <br></br>
            {/* <div className="mt5">
              <Link to="/request">
                <b>Ask a question</b>
              </Link>
            </div> */}
          </p>
        </div>
      ) : (
        filteredData?.map((data, index) => {
          return (
            <div style={{ backgroundColor: 'white' }} key={index}>
              <RequestCard
                {...data}
                isClickable={true}
                list={true}
                onRequestClick={() => handleOnRequestClick(data)}
              />
            </div>
          );
        })
      )}
    </div>
  );
};
