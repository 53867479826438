/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Form, Button, Spin, Checkbox, Space, Divider, Modal } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserData } from '../../../helpers/functions';
import { IoMdArrowRoundBack } from 'react-icons/io';

const CustomerType = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const { eid } = useParams();
  const [form] = Form.useForm();
  const { isCTLoading, customerTypes } = useSelector(state => state.common);
  const { isExpertInfoLoading, expertDetails } = useSelector(state => state.expert);
  const { isExpertUpdating, stepExpertMsg } = useSelector(state => state.steps);
  const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setChckedAll] = useState(false);
  const [welcomneModalVisible, setWelcomeModalVisible] = useState(false);

  useEffect(() => {
    if (eid || getUserData().id) {
      dispatch({ type: 'EXPERT_DETAIL_REQUEST', id: eid || getUserData().id });
    }
    if (localStorage.getItem('expertFirstTimeLogin') == 'true') {
      setWelcomeModalVisible(true);
    }
  }, []);

  useEffect(() => {
    dispatch({ type: 'GET_CUSTOMER_TYPE_REQUEST', formData: { status: 'active' } });
  }, []);

  const onChange = e => {
    setCheckedList(e);
    // setSelectedType(e.target.value)
  };

  useEffect(() => {
    if (
      !isExpertInfoLoading &&
      Object.keys(expertDetails).length > 0 &&
      customerTypes.length > 0 &&
      !isCTLoading
    ) {
      let tmpIds =
        expertDetails['prefer-customer'] !== null && expertDetails['prefer-customer'].length
          ? expertDetails['prefer-customer'].map(i => i.id)
          : [];
      form.setFieldsValue({ customer_type_ids: tmpIds });
    }
  }, [isExpertInfoLoading, expertDetails, customerTypes, isCTLoading]);

  const onFinish = values => {
    dispatch({
      type: 'EXPERT_STEP_UPDATE_REQUEST',
      formData: {
        ...values,
        expert_id: eid || getUserData().id,
      },
      requestType: 'put',
      step: 'customer-type',
    });
  };

  useEffect(() => {
    if (!isExpertUpdating && stepExpertMsg === 'success') {
      handleNext('main-category');
      dispatch({ type: 'CLEAR_STEPS' });
    }
  }, [isExpertUpdating, stepExpertMsg]);

  return (
    <div>
      <div className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={false}>
          {isCTLoading && <Spin />}
          {!isCTLoading && (
            <>
              <p className="m-title tl mb-7 d-flex-center">
                <span>I prefer to serve</span>
              </p>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                form={form}
                onFinish={onFinish}
              >
                <Form.Item
                  name="customer_type_ids"
                  rules={[{ required: true, message: 'Please select at least one option' }]}
                >
                  <Checkbox.Group
                    value={checkedList}
                    className={'mt3'}
                    style={{ width: '100%' }}
                    onChange={onChange}
                  >
                    <Space direction="vertical" size={'middle'} style={{ width: '100%' }}>
                      {customerTypes.map(item => {
                        return (
                          <>
                            <Checkbox key={item.id} value={item.id}>
                              {' '}
                              {item.name}
                            </Checkbox>
                          </>
                        );
                      })}
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
                {/* <Form.Item>
                                    <Checkbox
                                        onChange={onCheck}
                                        checked={checkAll}
                                    >
                                        Check all
                                    </Checkbox>
                                </Form.Item> */}
                <Divider className="mt40 mb40" />
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    className="mt5"
                    loading={isExpertUpdating}
                    disabled={isExpertUpdating}
                  >
                    Next
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </Card>
      </div>
      <Modal
        open={welcomneModalVisible}
        okText="Got it"
        closable={false}
        centered
        footer={
          <div>
            <Button
              type="primary"
              size="middle"
              className="mt5 mr5"
              onClick={() => (setWelcomeModalVisible(false), localStorage.removeItem('expertFirstTimeLogin'))}
            >
              Okay
            </Button>
          </div>
        }
      >
        <div >
          <p style={{ textAlign: 'center' }}>
            <h2>
              Welcome to Accountizer !
            </h2>
          </p>
          <p><strong>Thank you for joining us !</strong> To ensure you can start offering your services effectively and help the perfect matching clients find you, please complete the Experience Details section. This will showcase your work experience, qualifications, and the services you provide.</p>
        </div>
      </Modal>
    </div>
  );
};
export default CustomerType;
